import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { global } from '../../shared/colors';
import { StyledUploadOutlined, StyledDiv } from './styled-components';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '10px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: global.Gray6,
  borderStyle: 'dashed',
  backgroundColor: global.Gray6,
  color: global.Gray4,
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  backgroundColor: '#C5E8FB'
};

const acceptStyle = {
  backgroundColor: '#C5E8FB'
};

const rejectStyle = {
  borderColor: global.ErrorRed
};

const UploadTarget = ({ onFileDrop, disabled = false }) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    disabled,
    onDrop: onFileDrop
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const renderInstructions = () => (disabled ? (
    <p id="instruction">Clear results to add new files</p>
  ) : (
    <>
      <div>
        <StyledUploadOutlined aria-hidden="true" />
      </div>
      <p id="UploadFiles">Drag and drop or click to browse files</p>
    </>
  ));

  const disabledClass = disabled ? 'disabled' : '';

  return (
    <section>
      <StyledDiv className={` ${disabledClass}`}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} aria-labelledby="instruction" />
          {renderInstructions()}
        </div>
      </StyledDiv>
    </section>
  );
};

export default UploadTarget;
