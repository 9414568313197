import React from 'react';
import styled from 'styled-components';
import { RxDownload } from 'react-icons/rx';

const StyledIoDownloadOutline = styled(({ className }) => (
  <RxDownload className={className} />
))`
    font-size: 16px;
`;

export default StyledIoDownloadOutline;
