import styled from 'styled-components';

const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 52px;
    margin-left: 61px;
    margin-right: 29px;
    margin-bottom: 99px;
    height: 80vh;
`;

export default StyledDiv;
