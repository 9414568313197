import { useContext } from 'react';
import { InspectionContext } from '../../../../context/InspectionContext';
import LeakSummaryCard from './LeakSummaryCard';
// eslint-disable-next-line import/no-named-as-default
import Leak from '../../../../classes/leak';

const InspectionSummary = () => {
  const { inspection } = useContext(InspectionContext);
  const totalLeakCount = inspection?.leaks?.length ?? 0;
  const leaksWithRepairs = inspection?.leaks?.filter((leak) => (
    (leak.leakRepairs?.length ?? 0) > 0
    && (leak.leakRepairs?.some((repair) => repair.repaired))
  )) ?? [];
  const leaksWithoutRepairs = inspection?.leaks?.filter((leak) => (
    (leak.leakRepairs?.length ?? 0) === 0
    || !(leak.leakRepairs?.some((repair) => repair.repaired))
  )) ?? [];
  return (
    <div>
      <h1 className="page-details__header">Leak details</h1>
      <h4 className="part">Part 1 of 2</h4>
      {
        totalLeakCount === 0 && (
          <LeakSummaryCard
            leaks={[] as Leak[]}
            leakStatus="NO LEAKS DETECTED"
          />
        )
      }
      {leaksWithRepairs.length > 0 && (
        <LeakSummaryCard
          leaks={leaksWithRepairs}
          leakStatus="REPAIRED"
        />
      )}
      {leaksWithoutRepairs.length > 0 && (
        <LeakSummaryCard
          leaks={leaksWithoutRepairs}
          leakStatus="NOT REPAIRED"
        />
      )}
    </div>
  );
};

export default InspectionSummary;
