import styled from 'styled-components';
import { Input } from 'antd';

import { global } from '../../../../shared/colors';

const StyledInput = styled(Input)`
    height: 38px;
    border-radius: 10px;
    text-overflow: ellipsis;

    &:hover,
    &:focus {
        border: solid 1px ${global.Gray1};
    }
`;

export default StyledInput;
