import {
  createRef, KeyboardEvent, useContext, useEffect, useState
} from 'react';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { Button } from '@flogistix/flo-ui';

import './MultiDropdown.scss';
import { InspectionContext } from '../../context/InspectionContext';
import { fetchFileUrl } from '../../services/airmethaneApi';

interface MultiDropdownOption {
  label: string;
  value: string | number;
  content?: React.ReactNode;
}

interface MultiDropdownProps
  extends Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'size' | 'onSelect'
  > {
  options: MultiDropdownOption[];
  placeholder: string;
  value: string[];
  fixedWidth?: string;
  className?: string;
  dropdownHeight?: string;
  size?: 'small' | 'medium' | 'large' | 'auto';
  onSelect: (option: MultiDropdownOption) => void;
  onDeselect?: (option: MultiDropdownOption) => void;
  onConfirm?: (selectedOptions: string[]) => void;
  initialSelectedOptions?: string[];
}

const MultiDropdown = ({
  options = [],
  placeholder,
  size = 'medium',
  fixedWidth,
  className,
  dropdownHeight,
  onSelect,
  onDeselect,
  onConfirm,
  value,
  initialSelectedOptions = [],
  ...restOfProps
}: MultiDropdownProps) => {
  const { token } = useContext(InspectionContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialSelectedOptions);
  const dropdownRef = createRef<HTMLDivElement>();

  const handleSelect = (option: MultiDropdownOption) => {
    const isSelected = selectedOptions.includes(option.value as string);
    let newSelectedOptions;

    if (isSelected) {
      newSelectedOptions = selectedOptions.filter(
        (val) => val !== option.value
      );
      if (onDeselect) {
        onDeselect(option);
      }
    } else {
      newSelectedOptions = [...selectedOptions, option.value as string];
      onSelect(option);
    }

    setSelectedOptions(newSelectedOptions);
  };

  const handleConfirmSelection = () => {
    setIsOpen(false);
    if (onConfirm) {
      onConfirm(selectedOptions);
    }
  };

  const handleCancel = () => {
    setSelectedOptions(initialSelectedOptions);
    setIsOpen(false);
  };

  const handleKeyPress = (
    e: KeyboardEvent<HTMLDivElement>,
    option: MultiDropdownOption
  ): void => {
    switch (e.key) {
      case 'Enter': {
        handleSelect(option);
        break;
      }
      case 'ArrowUp': {
        e.preventDefault();
        const prevElement = e.currentTarget.previousElementSibling as HTMLDivElement;
        prevElement?.focus();
        break;
      }
      case 'ArrowDown': {
        e.preventDefault();
        const nextElement = e.currentTarget.nextElementSibling as HTMLDivElement;
        nextElement?.focus();
        break;
      }
      case 'Escape': {
        setIsOpen(false);
        break;
      }
      default:
        break;
    }
  };

  const fetchFileData = async (fileIds: string[], tkn: string): Promise<string[]> => {
    const fileDataArray = await Promise.all(
      fileIds.map(async (fileId) => {
        const fileData = await fetchFileUrl(fileId, tkn);
        return fileData.id;
      })
    );
    return fileDataArray;
  };

  const displayValue = selectedOptions.length === 1
    ? options.find((option) => option.value === selectedOptions[0])?.label || selectedOptions[0]
    : `${selectedOptions.length} files selected`;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        dropdownRef.current
        && !dropdownRef.current.contains(e.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const channel = new BroadcastChannel('transaction-channel');

    channel.onmessage = async (event) => {
      if (event.data.type === 'TRANSACTION_COMPLETED' || event.data.type === 'TRANSACTION_UPDATED') {
        const fetchedIds = await fetchFileData(value, token);
        setSelectedOptions(fetchedIds);
      }
    };

    return () => {
      channel.close();
    };
  }, [value]);

  return (
    <div
      className={`flo-multidropdown ${size} ${className ?? ''}`}
      style={fixedWidth ? { width: fixedWidth } : {}}
      ref={dropdownRef}
      {...restOfProps}
    >
      <div
        className={`flo-multidropdown--trigger ${isOpen ? 'open' : ''}`}
        id="dropdown-trigger"
        role="button"
        aria-expanded={isOpen}
        aria-controls="dropdown-options"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => e.key === 'Enter' && setIsOpen(!isOpen)}
        tabIndex={0}
        aria-label="Toggle dropdown"
      >
        <div className="flo-multidropdown--value">
          <span>{selectedOptions.length > 0 ? displayValue : placeholder}</span>
        </div>
        <div className="flo-multidropdown--icon">
          {isOpen ? <IoChevronUp /> : <IoChevronDown />}
        </div>
      </div>
      {isOpen && (
        <div
          className="flo-multidropdown--options-container"
          style={dropdownHeight ? { maxHeight: dropdownHeight } : {}}
        >
          <div className="flo-multidropdown--options-container--options-list">
            {options.map((option: MultiDropdownOption) => (
              <div
                key={option.value}
                role="option"
                aria-selected={selectedOptions.includes(option.value as string)}
                onClick={() => handleSelect(option)}
                onKeyDown={(e) => handleKeyPress(e, option)}
                className={`
                flo-multidropdown--options-container--options-list--option ${
              selectedOptions.includes(option.value as string) ? 'selected' : ''
              }`}
                tabIndex={0}
                aria-label={`Select ${option.value as string}`}
              >
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={selectedOptions.includes(option.value as string)}
                    className="custom-checkbox"
                    readOnly
                  />
                </div>
                <span>{option.content || option.label}</span>
              </div>
            ))}
          </div>
          <div className="flo-multidropdown--options-container--footer">
            <Button
              variation="black-outline"
              onClick={handleCancel}
              className="flo-multidropdown--cancel-btn"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmSelection}
              variation="blue"
              className="flo-multidropdown--select-btn"
            >
              Select
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiDropdown;

MultiDropdown.defaultProps = {
  fixedWidth: '',
  className: '',
  dropdownHeight: '',
  size: 'medium',
  onDeselect: undefined,
  onConfirm: undefined,
  initialSelectedOptions: []
};
