import {
  useContext, useEffect, useRef, useState
} from 'react';
import { Button, Dropdown } from '@flogistix/flo-ui';
import { Input } from 'antd';
import { IoChevronDownOutline, IoChevronUpOutline, IoRemoveCircle } from 'react-icons/io5';
import MonitoredComponent from '../../../../classes/monitored-component';
import { InspectionContext } from '../../../../context/InspectionContext';
import { ComponentType } from '../../../../classes/enums';

import './MonitoredComponentCard.scss';

const MonitoredComponentCard = ({
  component,
  removeMonitoredComponent,
  componentIndex
}: {
  component: MonitoredComponent;
  removeMonitoredComponent: () => void;
  componentIndex: number;
}) => {
  const { TextArea } = Input;
  const { inspection, token } = useContext(InspectionContext);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [componentTypeSearch, setComponentTypeSearch] = useState('');
  const [componentType, setComponentType] = useState('' as ComponentType);
  const [difficultComponent, setDifficultComponent] = useState(component?.difficultToMonitor || false);
  const [unsafeComponent, setUnsafeComponent] = useState(component?.unsafeToMonitor || false);
  const [difficultDescription, setDifficultDescription] = useState(component?.difficultToMonitorDescription || '');
  const [unsafeDescription, setUnsafeDescription] = useState(component?.unsafeToMonitorDescription || '');

  const handleComponentTypeChange = (selectedOption: {
    value: string | number;
    label: string;
  }) => {
    const { value } = selectedOption;
    component.patchMonitoredComponent(
      token,
      inspection!.flogistixId!,
      inspection!,
      {
        ...component,
        compoenetType: value as ComponentType
      } as MonitoredComponent
    ).then(() => {
      setComponentType(value as ComponentType);
    });
  };

  const handleDifficultComponentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isDifficult = e.target.checked;
    component.patchMonitoredComponent(
      token,
      inspection!.flogistixId!,
      inspection!,
      {
        ...component,
        difficultToMonitor: isDifficult,
        difficultToMonitorDescription: !isDifficult ? null : component.difficultToMonitorDescription
      } as MonitoredComponent
    ).then(() => {
      setDifficultComponent(isDifficult);
      if (!isDifficult) setDifficultDescription('');
    });
  };

  const handleUnsafeComponentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isUnsafe = e.target.checked;
    component.patchMonitoredComponent(
      token,
      inspection!.flogistixId!,
      inspection!,
      {
        ...component,
        unsafeToMonitor: isUnsafe,
        unsafeToMonitorDescription: !isUnsafe ? null : component.unsafeToMonitorDescription
      } as MonitoredComponent
    ).then(() => {
      setUnsafeComponent(isUnsafe);
      if (!isUnsafe) setUnsafeDescription('');
    });
  };

  const handleDifficultDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
    component.patchMonitoredComponent(
      token,
      inspection!.flogistixId!,
      inspection!,
      {
        ...component,
        difficultToMonitorDescription: description
      } as MonitoredComponent
    ).then(() => {
      setDifficultDescription(description);
    });
  };

  const handleUnsafeDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
    component.patchMonitoredComponent(
      token,
      inspection!.flogistixId!,
      inspection!,
      {
        ...component,
        unsafeToMonitorDescription: description
      } as MonitoredComponent
    ).then(() => {
      setUnsafeDescription(description);
    });
  };

  const componentTypeOptions = Object.values(ComponentType).map((type) => ({
    value: type,
    label: type
  }));

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteButtonRef.current
        && !deleteButtonRef.current.contains(event.target as Node)
      ) {
        setShowDelete(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="monitored-card-container">
      <div ref={deleteButtonRef}>
        {
          showDelete ? (
            <Button
              variation="red-outline"
              className="delete-button-container"
              size="medium"
              type="button"
              onClick={removeMonitoredComponent}
            >
              Delete
            </Button>
          ) : (
            <button
              className="icon-button"
              type="button"
              onClick={() => setShowDelete(true)}
              aria-label="Delete"
            >
              <IoRemoveCircle className="remove-icon" size={24} />
            </button>
          )
        }
      </div>
      <div className="monitored-card">
        <button
          className="monitored-card__header"
          onClick={() => setCollapsed(!collapsed)}
          type="button"
          style={collapsed ? { marginBottom: 0 } : {}}
        >
          <h4>
            Component
            {' '}
            {componentIndex + 1}
          </h4>
          { collapsed ? <IoChevronDownOutline /> : <IoChevronUpOutline />}
        </button>
        {
          !collapsed && (

            <>
              <label className="label">Component type</label>
              <Dropdown
                id="component-type"
                options={componentTypeOptions.filter(
                  (option) => option.label.toLowerCase().includes(componentTypeSearch.toLowerCase())
                )}
                value={componentType}
                onSelect={handleComponentTypeChange}
                onClear={() => handleComponentTypeChange({ value: '', label: '' })}
                searchValue={componentTypeSearch}
                onSearchChange={(value) => setComponentTypeSearch(value)}
                placeholder="Select component type"
                className="input__select"
              />
              <div className="component-checkbox">
                <label className="checkbox-group">
                  <input
                    type="checkbox"
                    checked={unsafeComponent}
                    onChange={handleUnsafeComponentChange}
                    id="checkbox-input"
                  />
                  <span className="custom-checkbox" />
                </label>
                <span className="label">Unsafe to monitor</span>
              </div>
              {
                unsafeComponent && (
                  <>
                    <label className="label">Description</label>
                    <TextArea
                      defaultValue={unsafeDescription}
                      onBlur={handleUnsafeDescriptionChange}
                      placeholder="Why was this unsafe to monitor?"
                      className="description-input"
                    />
                  </>
                )
              }
              <div className="component-checkbox">
                <label className="checkbox-group">
                  <input
                    type="checkbox"
                    checked={difficultComponent}
                    onChange={handleDifficultComponentChange}
                  />
                  <span className="custom-checkbox" />
                </label>
                <span className="label">Difficult to monitor</span>
              </div>
              {
                difficultComponent && (
                  <>
                    <label className="label">Description</label>
                    <TextArea
                      defaultValue={difficultDescription}
                      onBlur={handleDifficultDescriptionChange}
                      placeholder="Why was this difficult to monitor?"
                      className="description-input"
                    />
                  </>
                )
              }
            </>
          )
        }
      </div>
    </div>
  );
};

export default MonitoredComponentCard;
