import React, { useContext } from 'react';
import { Button } from '@flogistix/flo-ui';
import { InspectionContext } from '../../context/InspectionContext';
import './InspectionLayout.scss';

interface InspectionFooterProps {
  hasBackButton: boolean;
  hasNextButton: boolean;
  handleBack: () => void;
  handleNavigation: () => void;
}

const InspectionFooter: React.FC<InspectionFooterProps> = ({
  hasBackButton,
  hasNextButton,
  handleBack,
  handleNavigation
}) => {
  const { hasAllRequiredFields } = useContext(InspectionContext);

  return (
    <footer className={`${hasBackButton ? 'has-back' : ''} ${hasNextButton ? 'has-next' : ''}`}>
      {hasBackButton && (
        <Button id="back-button" variation="black-outline" onClick={handleBack} fixedWidth="112px">
          Back
        </Button>
      )}
      {hasNextButton && (
        <Button
          id="next-button"
          variation="black-outline"
          onClick={handleNavigation}
          fixedWidth="112px"
          disabled={!hasAllRequiredFields}
        >
          Next
        </Button>
      )}
    </footer>
  );
};

export default InspectionFooter;
