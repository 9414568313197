import React from 'react';
import { IoCheckmark, IoClose } from 'react-icons/io5';
import { global } from '../../shared/colors';
import './UploadManagement.scss';

interface ToastProps {
  message: string;
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ message, onClose }) => (
  <div className="copy-toast">
    <IoCheckmark size="20px" color={global.PrimaryBlue} />
    <span>{message}</span>
    <IoClose size="20px" className="toast-close-icon" onClick={onClose} />
  </div>
);

export default Toast;
