import React from 'react';
import { Table } from 'antd';

import { sensorTypes } from '../../shared/constants';
import { createOptionFromTypeSet } from '../../shared/utils';
import MultiUploadTableColumns from './MultiUploadTableColumns';
import { UploadFileTable } from './styled-components';

const UploadedFileTable = ({
  uploadedFiles,
  onSensorTypeChange,
  onFileTypeChange,
  onDeleteFile,
  orgs,
  onInspectionChange,
  onSiteChange,
  onOrgChange,
  onDisplayNameChange,
  selectedInspection = null,
  selectedSite = null,
  selectedOrg = null,
  getUploadStatusByFileKey
}) => {
  const sensorOptions = createOptionFromTypeSet(sensorTypes);

  const orgOptions = orgs.map((org) => ({ label: org.orgName, value: org }));

  const isDisabled = (data) => getUploadStatusByFileKey(data)?.status;

  const columns = MultiUploadTableColumns({
    orgOptions,
    onOrgChange,
    onSiteChange,
    onInspectionChange,
    onDisplayNameChange,
    sensorOptions,
    onSensorTypeChange,
    onFileTypeChange,
    onDeleteFile,
    flogistixId: selectedInspection?.flogistixId,
    siteName: selectedSite?.siteName,
    orgName: selectedOrg?.orgName,
    isDisabled,
    getUploadStatus: getUploadStatusByFileKey
  });

  return (
    <UploadFileTable>
      <Table
        columns={columns}
        dataSource={uploadedFiles}
        pagination={false}
        flogistixId={selectedInspection?.flogistixId}
      />
    </UploadFileTable>
  );
};

export default UploadedFileTable;
