import styled from 'styled-components';

import { global } from '../../../../shared/colors';

const StyledFile = styled.div`
    button {
        color: ${global.Gray1};
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        padding: 10px 0 10px 0;

        &:hover {
            color: ${global.Black};
        }
    }
`;

export default StyledFile;
