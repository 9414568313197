import styled from 'styled-components';

import { global } from '../../../../shared/colors';

const InspectionID = styled.div`
    margin-left: 7px;
    color: ${global.Black};
`;

export default InspectionID;
