import styled from 'styled-components';

const HeaderWrapper = styled.div`
    display: flex;
    font-size: 16px;
    flex-direction: column;
    margin-top: 53px;
    margin-left: 61px;
`;

export default HeaderWrapper;
