import React from 'react';
import { Button } from '@flogistix/flo-ui';
import { IoClose, IoAlertCircleOutline } from 'react-icons/io5';

import './DeleteConfirmationModal.scss';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onRemoveFromSection: () => void;
  disabled: boolean;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen, onClose, onConfirm, onRemoveFromSection, disabled
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div
        className="modal-content"
        style={{ height: disabled ? '293px' : '259px' }}
      >
        <IoClose className="close-icon" onClick={onClose} />
        <h2 className="modal-header">Remove media</h2>
        <p className="modal-subheader">
          Would you like to remove this file completely from the form or remove it from this section?
        </p>
        <div className={`modal-actions ${disabled ? 'swapped' : ''}`}>
          <div className="modal-actions">
            {disabled ? (
              <>
                <Button
                  className="width-no-padding"
                  disabled
                  onClick={onConfirm}
                  style={{ marginRight: '16px' }}
                >
                  Delete from form
                </Button>
                <Button
                  variation="red"
                  className="width-no-padding"
                  onClick={onRemoveFromSection}
                >
                  Remove from section
                </Button>
              </>
            ) : (
              <>
                <Button
                  variation="light-red"
                  className='"width-no-padding"'
                  onClick={onRemoveFromSection}
                  style={{ marginRight: '16px' }}
                >
                  Remove from section
                </Button>
                <Button
                  variation="red"
                  className="width-no-padding"
                  onClick={onConfirm}
                >
                  Delete from form
                </Button>
              </>
            )}
          </div>

        </div>
        {disabled && (
          <div className="modal-alert">
            <IoAlertCircleOutline className="modal-alert-icon" size={20} />
            <p className="alert-text">
              Unable to delete this file because it is currently being used in another section of the form.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
