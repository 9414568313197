import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-cycle
import { Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';
import { API_INSPECTION } from '../shared/url';
import { ComponentType } from './enums';
import { Inspection } from './inspection';

export interface IMonitoredComponent {
  monitoredComponentId?: string;
  compoenetType?: ComponentType;
  difficultToMonitor?: boolean;
  difficultToMonitorDescription?: string;
  unsafeToMonitor?: boolean;
  unsafeToMonitorDescription?: string;
}

export default class MonitoredComponent {
  monitoredComponentId?: string;

  compoenetType?: ComponentType;

  difficultToMonitor?: boolean;

  difficultToMonitorDescription?: string;

  unsafeToMonitor?: boolean;

  unsafeToMonitorDescription?: string;

  constructor({
    monitoredComponentId,
    compoenetType,
    difficultToMonitor,
    difficultToMonitorDescription,
    unsafeToMonitor,
    unsafeToMonitorDescription
  }: IMonitoredComponent) {
    this.monitoredComponentId = monitoredComponentId ?? uuidv4();
    this.compoenetType = compoenetType;
    this.difficultToMonitor = difficultToMonitor;
    this.difficultToMonitorDescription = difficultToMonitorDescription;
    this.unsafeToMonitor = unsafeToMonitor;
    this.unsafeToMonitorDescription = unsafeToMonitorDescription;
  }

  isEqual(other: MonitoredComponent | undefined) {
    return this.compoenetType === other?.compoenetType
      && this.difficultToMonitor === other?.difficultToMonitor
      && this.difficultToMonitorDescription === other?.difficultToMonitorDescription
      && this.unsafeToMonitor === other?.unsafeToMonitor
      && this.unsafeToMonitorDescription === other?.unsafeToMonitorDescription;
  }

  async patchMonitoredComponent(
    token: string,
    flogitsixId: string,
    inspection: Inspection,
    monitoredComponent: IMonitoredComponent
  ) {
    this.compoenetType = monitoredComponent.compoenetType;
    this.difficultToMonitor = monitoredComponent.difficultToMonitor;
    this.difficultToMonitorDescription = monitoredComponent.difficultToMonitorDescription;
    this.unsafeToMonitor = monitoredComponent.unsafeToMonitor;
    this.unsafeToMonitorDescription = monitoredComponent.unsafeToMonitorDescription;
    const transaction = {
      queueId: `${flogitsixId}->monitored-component`,
      request: {
        payload: monitoredComponent as object,
        inspectionId: inspection.id,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `${API_INSPECTION}/${inspection.id}/patchMonitoredComponent`
      }
    } as Transaction;
    const newInspection = new Inspection(inspection);
    const newComponents = newInspection.monitoredComponents?.filter(
      (c) => c.monitoredComponentId === this.monitoredComponentId
    );
    newInspection.monitoredComponents = newComponents ? [...newComponents, this] : [this];
    await createTransaction(transaction, newInspection);
  }
}
