/* eslint-disable import/no-cycle */
import { v4 as uuidv4 } from 'uuid';
import { ComponentType } from './enums';
import { Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';
import AirMethaneFile, { UploadFile } from './airmethane-file';
import { API_INSPECTION } from '../shared/url';
import LeakRepair from './leak-repair';
import { Inspection } from './inspection';

export interface ILeak {
  tagNumber?: string;
  componentType?: ComponentType;
  componentTypeOther?: string;
  componentSubtype?: string;
  priority?: string;
  notes?: string;
  location?: string;
  timestamp?: string;
  rate?: number;
  rateUom?: string;
  leakRepairs?: LeakRepair[];
  files?: AirMethaneFile[];
}

export class Leak {
  tagNumber?: string;

  componentType?: ComponentType;

  componentTypeOther?: string;

  componentSubtype?: string;

  priority?: string;

  notes?: string;

  location?: string;

  timestamp?: string;

  rate?: number;

  rateUom?: string;

  leakRepairs?: LeakRepair[];

  files?: AirMethaneFile[];

  pendingFiles?: UploadFile[];

  constructor({
    tagNumber,
    componentType,
    componentTypeOther,
    componentSubtype,
    priority,
    notes,
    location,
    timestamp,
    rate,
    rateUom,
    leakRepairs,
    files
  }: ILeak = {}) {
    this.tagNumber = tagNumber;
    this.componentType = componentType;
    this.componentTypeOther = componentTypeOther;
    this.componentSubtype = componentSubtype;
    this.priority = priority;
    this.notes = notes;
    this.location = location;
    this.timestamp = timestamp;
    this.rate = rate;
    this.rateUom = rateUom ?? 'ppm';
    this.leakRepairs = leakRepairs;
    this.files = files;
  }

  async patchLeak(token: string, flogistixId: string, inspection: Inspection, leak: Leak) {
    this.tagNumber = leak.tagNumber;
    this.componentType = leak.componentType;
    this.componentSubtype = leak.componentSubtype;
    this.priority = leak.priority;
    this.notes = leak.notes;
    this.location = leak.location;
    this.timestamp = leak.timestamp;
    this.rate = leak.rate;
    this.rateUom = leak.rateUom;
    this.leakRepairs = leak.leakRepairs;
    this.files = leak.files ?? [];
    this.pendingFiles = leak.pendingFiles ?? [];

    const { pendingFiles, ...leakObject } = leak;
    if (flogistixId) {
      const transaction = {
        queueId: `${flogistixId}->leaks->${this.tagNumber}`,
        request: {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: {
            body: {
              ...leakObject
            },
            pendingFiles,
            uploadedFiles: this.files,
            inspectionId: inspection.id
          },
          fileUpload: true,
          endpoint: `${API_INSPECTION}/${inspection.id}/patchLeak`
        }
      } as Transaction;
      const queuedFiles = this.pendingFiles.map((file) => new AirMethaneFile(file.id));
      this.files = [...this.files, ...queuedFiles];
      this.pendingFiles = [];
      const newInspection = new Inspection(inspection);
      const newLeaks = newInspection.leaks?.filter((l) => l.tagNumber !== this.tagNumber);
      newInspection.leaks = newLeaks ? [...newLeaks, this] : [this];
      await createTransaction(transaction, newInspection);
    }
  }

  async addFile(token: string, flogistixId: string, inspectionId: string, file: AirMethaneFile) {
    this.files?.push(file);
    const transaction = {
      queueId: `${flogistixId}->leaks->${this.tagNumber}`,
      request: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: file as object,
        endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${this.tagNumber}/addFile`
      }
    } as Transaction;
    await createTransaction(transaction);
  }

  async removeFile(token: string, flogistixId: string, inspectionId: string, fileIdToRemove: string) {
    this.files = this.files?.filter((f) => f.id !== fileIdToRemove);
    const transaction = {
      queueId: `${flogistixId}->leaks->${this.tagNumber}`,
      request: {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: {
          id: fileIdToRemove
        },
        endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${this.tagNumber}/removeFile`
      }
    } as Transaction;
    await createTransaction(transaction);
  }

  async addRepair(
    token: string,
    inspection: Inspection,
    flogistixId: string,
    repair: LeakRepair
  ) {
    /* eslint no-param-reassign: "off" */
    if (repair.leakRepairId == null) { repair.leakRepairId = uuidv4(); }
    if (this.leakRepairs === undefined) { this.leakRepairs = []; }
    this.leakRepairs?.push(repair);
    if (!flogistixId) return;
    const transaction = {
      queueId: `${flogistixId}->leaks->${this.tagNumber}->repair`,
      request: {
        inspectionId: inspection.id,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: repair as object,
        endpoint: `${API_INSPECTION}/${inspection.id}/leaks/${this.tagNumber}/addLeakRepair`
      }
    } as Transaction;
    const newInspection = new Inspection(inspection);
    const newLeaks = newInspection.leaks?.filter((leak) => leak.tagNumber !== this.tagNumber) ?? [];
    newInspection.leaks = newLeaks ? [...newLeaks, this] : [this];
    await createTransaction(transaction, newInspection);
  }

  async removeRepair(token: string, flogistixId: string, inspection: Inspection, repair: LeakRepair) {
    this.leakRepairs = this.leakRepairs?.filter((r) => r.leakRepairId !== repair.leakRepairId);
    if (!flogistixId) return;
    const transaction = {
      queueId: `${flogistixId}->leaks->${this.tagNumber}->repair`,
      request: {
        inspectionId: inspection.id,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: repair as object,
        endpoint: `${API_INSPECTION}/${inspection.id}/leaks/${this.tagNumber}/removeLeakRepair`
      }
    } as Transaction;
    const newInspection = new Inspection(inspection);
    const newLeaks = newInspection.leaks?.filter((leak) => leak.tagNumber !== this.tagNumber);
    newInspection.leaks = newLeaks ? [...newLeaks, this] : [this];
    await createTransaction(transaction, newInspection);
  }

  getSubmissionReadiness() {
    return (
      this.tagNumber !== undefined
      && this.componentType !== undefined
      && this.componentSubtype !== undefined
      && this.location !== undefined
      && this.rateUom !== undefined
      && this.rate !== undefined
      && this.files ? this.files.length === 2 : false
    );
  }

  getLeakCompletionPercentage() {
    const amountOfRequiredFields = 6;
    const fieldsOnLeak = [
      this.tagNumber,
      this.componentType,
      this.componentSubtype,
      this.location,
      this.rateUom,
      this.rate
    ].filter((field) => !!field);
    const topLevelLeakCompletion = (fieldsOnLeak.length / amountOfRequiredFields) * 100;

    let repairCompletion = 100;
    if (this.leakRepairs?.length) {
      repairCompletion = (this.leakRepairs.reduce((acc, repair) => (
        acc + repair.getRepairCompletionPercentage()
      ), 0) / this.leakRepairs.length);
    }
    const totalCompletionPercentage = (topLevelLeakCompletion + repairCompletion) / 2;
    return totalCompletionPercentage;
  }
}

export default Leak;
