import styled from 'styled-components';
import { global } from '../../../shared/colors';

const StyledInspection = styled.p`
    color: ${global.textGrey};
    margin-bottom: 0;
    margin-top: 30px;
    background: ${global.Gray6};
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default StyledInspection;
