import React from 'react';

const clipboard = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 24 24" fill="white" {...props}>
    <path
      d="M16 4C16.93 4 17.395 4 17.7765 4.10222
        C18.8117 4.37962 19.6204 5.18827 19.8978 6.22354
        C20 6.60504 20 7.07003 20 8
        V17.2C20 18.8802 20 19.7202 19.673 20.362
        C19.3854 20.9265 18.9265 21.3854 18.362 21.673
        C17.7202 22 16.8802 22 15.2 22
        H8.8C7.11984 22 6.27976 22 5.63803 21.673
        C5.07354 21.3854 4.6146 20.9265 4.32698 20.362
        C4 19.7202 4 18.8802 4 17.2
        V8C4 7.07003 4 6.60504 4.10222 6.22354
        C4.37962 5.18827 5.18827 4.37962 6.22354 4.10222
        C6.60504 4 7.07003 4 8 4
        M9.6 6H14.4C14.9601 6 15.2401 6 15.454 5.89101
        C15.6422 5.79513 15.7951 5.64215 15.891 5.45399
        C16 5.24008 16 4.96005 16 4.4
        V3.6C16 3.03995 16 2.75992 15.891 2.54601
        C15.7951 2.35785 15.6422 2.20487 15.454 2.10899
        C15.2401 2 14.9601 2 14.4 2
        H9.6C9.03995 2 8.75992 2 8.54601 2.10899
        C8.35785 2.20487 8.20487 2.35785 8.10899 2.54601
        C8 2.75992 8 3.03995 8 3.6
        V4.4C8 4.96005 8 5.24008 8.10899 5.45399
        C8.20487 5.64215 8.35785 5.79513 8.54601 5.89101
        C8.75992 6 9.03995 6 9.6 6Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const clipboardOutline = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M16 4C16.93 4 17.395 4 17.7765 4.10222
       C18.8117 4.37962 19.6204 5.18827 19.8978 6.22354
       C20 6.60504 20 7.07003 20 8
       V17.2C20 18.8802 20 19.7202 19.673 20.362
       C19.3854 20.9265 18.9265 21.3854 18.362 21.673
       C17.7202 22 16.8802 22 15.2 22
       H8.8C7.11984 22 6.27976 22 5.63803 21.673
       C5.07354 21.3854 4.6146 20.9265 4.32698 20.362
       C4 19.7202 4 18.8802 4 17.2
       V8C4 7.07003 4 6.60504 4.10222 6.22354
       C4.37962 5.18827 5.18827 4.37962 6.22354 4.10222
       C6.60504 4 7.07003 4 8 4
       M9.6 6H14.4C14.9601 6 15.2401 6 15.454 5.89101
       C15.6422 5.79513 15.7951 5.64215 15.891 5.45399
       C16 5.24008 16 4.96005 16 4.4
       V3.6C16 3.03995 16 2.75992 15.891 2.54601
       C15.7951 2.35785 15.6422 2.20487 15.454 2.10899
       C15.2401 2 14.9601 2 14.4 2
       H9.6C9.03995 2 8.75992 2 8.54601 2.10899
       C8.35785 2.20487 8.20487 2.35785 8.10899 2.54601
       C8 2.75992 8 3.03995 8 3.6
       V4.4C8 4.96005 8 5.24008 8.10899 5.45399
       C8.20487 5.64215 8.35785 5.79513 8.54601 5.89101
       C8.75992 6 9.03995 6 9.6 6Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const barChart = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M1.28125 9L1.28125 19M12.9062 9L12.9063 19M7.09375 1L7.09375 19M18.7188 1V19"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const barChartOutline = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 20 20" fill="white" {...props}>
    <path
      d="M1.28125 9L1.28125 19M12.9062 9L12.9063 19M7.09375 1L7.09375 19M18.7188 1V19"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const upload = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362
       C20.3854 19.9265 19.9265 20.3854 19.362 20.673
       C18.7202 21 17.8802 21 16.2 21H7.8
       C6.11984 21 5.27976 21 4.63803 20.673
       C4.07354 20.3854 3.6146 19.9265 3.32698 19.362
       C3 18.7202 3 17.8802 3 16.2
       V15M17 8L12 3M12 3L7 8M12 3V15"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const uploadOutline = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362
       C20.3854 19.9265 19.9265 20.3854 19.362 20.673
       C18.7202 21 17.8802 21 16.2 21H7.8
       C6.11984 21 5.27976 21 4.63803 20.673
       C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export {
  clipboard,
  clipboardOutline,
  barChart,
  barChartOutline,
  upload,
  uploadOutline
};
