const localSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';

export const AUTH_0 = 'http://axil.com/user';

export const IAM_URL = `${process.env.REACT_APP_IAM_URL}${localSuffix}`;

export const API_URL = process.env.REACT_APP_API_URL
    && `${process.env.REACT_APP_API_URL}${localSuffix}/airmethane`;

export const DATABOOKS_URL = process.env.REACT_APP_API_URL
    && `${process.env.REACT_APP_API_URL}${localSuffix}/databook/air-methane`;

export const audience = 'https://api.axil.ai';

export const API_INSPECTIONS_ENDPOINT = `${API_URL}/inspections`;
export const urlPrefix = process.env.REACT_APP_ENV === 'development' ? 'dev-' : '';
export const urlSuffix = process.env.REACT_APP_IS_LOCAL === 'true' ? '/local' : '';
const apiUrl = (base, path, includeAirMethane = true) => {
  const airMethanePath = includeAirMethane ? 'AirMethane/' : '';
  return `https://${process.env.REACT_APP_ENV === 'development'
    ? 'dev-' : ''}${base}.api.axil.ai/${airMethanePath}${path}`;
};
export const FLO_IAM = `https://${urlPrefix}api.axil.ai/iam`;
export const API_INSPECTORS = apiUrl('inspectors', 'inspectors');
export const API_INSTRUMENTS = apiUrl('survey-instruments', 'survey-instruments');
export const API_INSPECTIONS = apiUrl('inspections', 'inspections');
export const API_INSPECTIONS_BY_ORGS = `https://${urlPrefix}inspections.api.axil.ai/inspections/byUserOrgs`;
export const API_INSPECTION = apiUrl('inspections', 'inspections', false);
export const API_REPORTING = `https://${urlPrefix}api.axil.ai${urlSuffix}/reporting/airmethane`;
