import React, {
  useState, useCallback, useContext, useEffect
} from 'react';
import { TimePicker, TimePickerProps } from 'antd';
import { FiClock, FiArrowRight } from 'react-icons/fi';
import { IoCloseOutline } from 'react-icons/io5';
import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './rangePicker.scss';
import { InspectionContext } from '../../context/InspectionContext';

dayjs.extend(customParseFormat);

const RangerPicker: React.FC = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext) || {};

  const [localStartTime, setLocalStartTime] = useState<Dayjs | null>(
    inspection?.startTime ? dayjs(inspection.startTime, 'HH:mm:ss') : null
  );
  const [localEndTime, setLocalEndTime] = useState<Dayjs | null>(
    inspection?.endTime ? dayjs(inspection.endTime, 'HH:mm:ss') : null
  );

  const onFocusClear = (setter: React.Dispatch<React.SetStateAction<Dayjs | null>>) => {
    setter(null);
  };

  const handleInspectionTimeStartChange: TimePickerProps['onChange'] = useCallback(async (time: Dayjs | null) => {
    const newInspectionTimeStart = time ? dayjs(time).format('HH:mm:ss') : '';
    if (inspection && token) {
      if (!newInspectionTimeStart) {
        await inspection.removeStartTime(token);
      } else if (inspection.startTime !== newInspectionTimeStart) {
        await inspection.updateStartTime(token, newInspectionTimeStart);
      }
    }
    setLocalStartTime(time);
    setCompletedSectionsOnChange();
  }, [inspection, token]);

  const handleInspectionTimeEndChange: TimePickerProps['onChange'] = useCallback(async (time: Dayjs | null) => {
    const newInspectionTimeEnd = time ? dayjs(time).format('HH:mm:ss') : '';
    if (inspection && token) {
      if (!newInspectionTimeEnd) {
        await inspection.removeEndTime(token);
      } else if (inspection.endTime !== newInspectionTimeEnd) {
        await inspection.updateEndTime(token, newInspectionTimeEnd);
      }
    }
    setLocalEndTime(time);
    setCompletedSectionsOnChange();
  }, [inspection, token]);

  useEffect(() => {
    setLocalStartTime(inspection?.startTime ? dayjs(inspection.startTime, 'HH:mm:ss') : null);
    setLocalEndTime(inspection?.endTime ? dayjs(inspection.endTime, 'HH:mm:ss') : null);
  }, [inspection?.startTime, inspection?.endTime]);

  return (
    <div className="time-picker-container">
      <TimePicker
        className="time-picker"
        use12Hours
        format="h:mm a"
        value={localStartTime}
        onChange={handleInspectionTimeStartChange}
        onFocus={() => onFocusClear(setLocalStartTime)}
        clearIcon={<IoCloseOutline size={24} />}
        suffixIcon={<FiArrowRight size={24} />}
      />

      <TimePicker
        className="time-picker"
        use12Hours
        format="h:mm a"
        value={localEndTime}
        onChange={handleInspectionTimeEndChange}
        onFocus={() => onFocusClear(setLocalEndTime)}
        clearIcon={<IoCloseOutline size={24} />}
        suffixIcon={<FiClock size={24} />}
      />
    </div>
  );
};

export default RangerPicker;
