import styled from 'styled-components';
import { global } from '../../../../shared/colors';

const StyledTrashCan = styled.a`
    width: 32px;
    height: 32px;
    color: ${global.Gray3};
    display: grid;
    place-items: center;
    margin-top: 36px;
    z-index: 10;

    &:hover {
        background: ${global.Gray4};
        color: ${global.Black};
        border-radius: 5px;
    }
`;

export default StyledTrashCan;
