import React, { useContext } from 'react';
import styled from 'styled-components';
import { IoAlertCircleOutline } from 'react-icons/io5';

import { GlobalContext } from '../../context/GlobalContext';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 296px;
    height: 23px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.1);
    margin-top: 12px;

    p {
        color: #848383;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-left: 4px;
    }
`;

const Status = () => {
  const { inspectionsReadyCount } = useContext(GlobalContext);

  if (inspectionsReadyCount <= 0) return null;

  const countDisplay = inspectionsReadyCount === 1 ? 'one' : inspectionsReadyCount;

  return (
    <StyledDiv>
      <IoAlertCircleOutline color="#0A84FF" />
      <p>
        You have
        {' '}
        {countDisplay}
        {' '}
        inspection
        {inspectionsReadyCount > 1 ? 's' : ''}
        {' '}
        ready for submission.
      </p>
    </StyledDiv>
  );
};

export default Status;
