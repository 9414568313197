import React from 'react';
import { Spin } from 'antd';

import { StyledUploadSpin } from '../../shared/styled-components';

const antIcon = <StyledUploadSpin spin />;

const UploadSpin = () => <Spin indicator={antIcon} />;

export default UploadSpin;
