import React from 'react';
import styled from 'styled-components';
import { FiEdit2 } from 'react-icons/fi';

const StyledIoPencilOutline = styled(
  React.forwardRef((props, ref) => <FiEdit2 ref={ref} {...props} />)
)`
    font-size: 16px;
`;

export default StyledIoPencilOutline;
