import styled from 'styled-components';

const StyledDiv = styled.div`
    display: flex;
    font-family: sans-serif;
    width: 100%;
    height: 12rem;
    justify-content: center;
    margin-bottom: 24px;
`;

export default StyledDiv;
