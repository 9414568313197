import { SensorType } from './enums';

export interface UploadFile {
  status?: 'processing' | 'failed' | 'done';
  id: string;
  file: File;
  displayName: string;
}

export default class AirMethaneFile {
  id?: string;

  sensorType?: SensorType;

  preview?: string;

  constructor(id?: string, preview?: string) {
    this.id = id;
    this.preview = preview;
  }

  isEqual(other: AirMethaneFile | undefined) { return this.id === other?.id; }
}

export interface PreviewFile extends AirMethaneFile {
  previewUrl?: string,
  fileName?: string,
  fileType?: string
}
