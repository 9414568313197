import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { global } from '../../shared/colors';
import DropdownIndicator from './DropdownIndicator';

const customStyles = {
  dropdownIndicator: () => ({
    cursor: 'pointer'
  }),
  control: (provided) => ({
    ...provided,
    '&:hover': {
      borderColor: global.Gray2,
      cursor: 'pointer'
    },
    width: '100%',
    borderRadius: '10px',
    height: '40px'
  }),
  container: (provided) => ({
    ...provided,
    justifyContent: 'flex-start'
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    color: global.Gray4,
    fontFamily: 'Inter',
    width: '100%'
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    width: '90%',
    margin: 'auto',
    color: isSelected ? global.White : global.Gray1,
    fontFamily: 'Inter',
    backgroundColor: isSelected ? global.PrimaryBlue : global.White,
    borderRadius: '10px',
    cursor: 'pointer',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: global.Gray4
    }
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2
  })
};

const StyledSelect = ({
  options, onChange, disabled, placeholder
}) => {
  const defaultSelectedValue = null;
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const handleOptionChange = (option) => {
    setSelectedValue(option);
    onChange(option);
  };

  const clearOnBackspace = (e) => e.code === 'Backspace' && setSelectedValue(defaultSelectedValue);

  return (
    <Select
      styles={customStyles}
      options={options}
      autosize
      onKeyDown={clearOnBackspace}
      onChange={handleOptionChange}
      isDisabled={disabled}
      value={selectedValue}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator
      }}
      aria-label="Choose an option"
    />
  );
};

StyledSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

StyledSelect.defaultProps = {
  disabled: false,
  placeholder: 'Select...'
};

export default StyledSelect;
