import styled from 'styled-components';

import { global } from '../../../../shared/colors';

const StyledPaperClip = styled.a`
    color: ${global.Black};
    margin: auto 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    &:hover {
        background: ${global.Gray4};
        color: ${global.Gray3};

        border-radius: 5px;
    }
`;

export default StyledPaperClip;
