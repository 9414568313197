import React from 'react';
import {
  WarningOutlined,
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import styled from 'styled-components';

import { global } from '../../shared/colors';
import logo from '../../assets/HeaderLogo.svg';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23%;

    padding: 24px 24px 0 24px;
    width: 100%;
    color: ${global.Gray3};

    .anticon.anticon-warning {
        svg {
            width: 16em;
            height: 16em;
            color: ${global.PrimaryBlue};
            margin-left: 1em;
            position: relative;
            top: 0.08em;
            opacity: 0.75;
        }
    }
`;

const StyledText = styled.div`
    font-size: 1.4rem;
    margin-bottom: 0.4em;
`;

const StyledHeader = styled.div`
    font-size: 1rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    color: white;
    flex: 0.5;
`;

const StyledWrapper = styled.div`
    width: 50%;
    background-color: ${global.Gray1};
    margin: 4em;
    border-radius: theme.$buttonRadius;
    padding-left: 4em;
    padding-right: 4rem;
    padding-bottom: 2em;
    padding-top: 2em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    a {
        color: white;
        font-size: 1rem;
    }
`;

const AccessDenied = () => (
  <StyledDiv>
    <StyledWrapper>
      <StyledHeader>
        <div>
          <img src={logo} alt="header-logo-svg" />
        </div>
        <StyledText>Access Denied </StyledText>
        <p>
          You currently do NOT have access to this site. Please
          contact our Customer Support for further assistance.
        </p>
        <div>
          <a href="mailto:support@flogistix.com">
            <MailOutlined />
            {' '}
            support@flogistix.com
          </a>
        </div>
        <div>
          <a href="4055360015">
            <PhoneOutlined />
            {' '}
            405-536-0015
          </a>
        </div>
      </StyledHeader>
      <div>
        <WarningOutlined />
      </div>
    </StyledWrapper>
  </StyledDiv>
);

export default AccessDenied;
