import React from 'react';
import { Tooltip } from 'antd';

import { IconToggle } from '../../components';
import UploadSpin from './UploadSpin';
import { global } from '../../shared/colors';
import {
  SUCCESS_FILE_UPLOAD_STATUS,
  FAILED_FILE_UPLOAD_STATUS,
  PENDING_FILE_UPLOAD_STATUS
} from '../../shared/constants';
import {
  IconWrapper,
  StyledCheckOutlined,
  StyledCloseOutlined,
  StyledIoTrashOutline
} from './styled-components';

const UploadFileStatusAndDelete = ({ data, onDeleteFile, getUploadStatus }) => {
  const uploadStatus = getUploadStatus(data);

  const handleClick = () => (!uploadStatus ? onDeleteFile(data) : undefined);

  const showDeleteIcon = uploadStatus?.status !== PENDING_FILE_UPLOAD_STATUS
        && uploadStatus?.status !== SUCCESS_FILE_UPLOAD_STATUS
        && uploadStatus?.status !== FAILED_FILE_UPLOAD_STATUS;

  return (
    <div>
      {showDeleteIcon && (
        <Tooltip title="Delete" color={global.White}>
          <IconWrapper>
            <IconToggle onClick={handleClick}>
              <StyledIoTrashOutline />
            </IconToggle>
          </IconWrapper>
        </Tooltip>
      )}
      {uploadStatus?.status === SUCCESS_FILE_UPLOAD_STATUS && (
        <IconWrapper>
          <IconToggle noHover>
            <StyledCheckOutlined />
          </IconToggle>
        </IconWrapper>
      )}
      {uploadStatus?.status === FAILED_FILE_UPLOAD_STATUS && (
        <IconWrapper>
          <IconToggle
            noHover
            data-tooltip={`Failed to Upload ${uploadStatus?.failedUploadResult?.error?.statusText}`}
          >
            <StyledCloseOutlined />
          </IconToggle>
        </IconWrapper>
      )}
      {uploadStatus?.status === PENDING_FILE_UPLOAD_STATUS && (
        <IconWrapper>
          <IconToggle noHover>
            <UploadSpin />
          </IconToggle>
        </IconWrapper>
      )}
    </div>
  );
};

export default UploadFileStatusAndDelete;
