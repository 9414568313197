import React from 'react';
import styled from 'styled-components';
import { IoVideocamOutline } from 'react-icons/io5';

const StyledIoVideocamOutline = styled(({ className }) => (
  <IoVideocamOutline className={className} />
))`
    font-size: 16px;
    margin: auto 8px auto auto;
`;

export default StyledIoVideocamOutline;
