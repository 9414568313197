import React, { useContext, useEffect, useState } from 'react';
import {
  Header,
  Checkbox,
  Card,
  Toggle,
  Dropdown,
  DateRangePicker,
  DatePicker,
  Input,
  Button
} from '@flogistix/flo-ui';
import { GlobalContext } from '../../../context/GlobalContext';
import { getAllInspectors } from '../../../dexie/operations';
import { DropdownOptionType } from '../../InspectionLayout/shared/typings';
import { formatDate } from '../../../shared/utils';
import {
  immediateReportingPost,
  scheduledReportingPost,
  generateCronExpression
} from '../services/AirMethaneReportingAPI';
import './CreateNewReport.scss';

const generateTimeSlots = (): { label: string; value: string }[] => {
  const times = [];
  const padZero = (num: number) => num.toString().padStart(2, '0');

  for (let hour = 0; hour < 24; hour += 1) {
    for (let minute = 0; minute < 60; minute += 30) {
      const ampm = hour < 12 ? 'AM' : 'PM';
      const labelHour = hour % 12 === 0 ? 12 : hour % 12;
      const labelMinute = padZero(minute);
      const label = `${labelHour}:${labelMinute} ${ampm}`;
      const value = `${padZero(hour)}:${labelMinute}`;

      times.push({ label, value });
    }
  }
  return times;
};

const daySearchItems = [
  { label: 'Sunday', value: '0' },
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },
  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' }
];

const frequencySearchItems = [
  { label: 'Every Day', value: 'day' },
  { label: 'Every Week', value: 'week' },
  { label: 'Every Month', value: 'month' }
];

const reportsEnum = Object.freeze({
  leakReport: 'airmethane-leak-report',
  monthlyReport: 'airmethane-monthly-report'
});

const CreateNewReport = () => {
  const [selectedReport, setSelectedReport] = useState<string>('');
  const [toggleOption, setToggleOption] = useState<string>('One-Time Report');
  const { orgs, token, triggerGlobalNotification } = useContext(GlobalContext);
  const [orgSearchTerm, setOrgSearchTerm] = useState<string>('');
  const [orgValue, setOrgValue] = useState<DropdownOptionType | undefined>(undefined);
  const [siteOptions, setSiteOptions] = useState<DropdownOptionType[]>([]);
  const [siteSearchTerm, setSiteSearchTerm] = useState<string>('');
  const [siteValue, setSiteValue] = useState<DropdownOptionType | undefined>(undefined);
  const [inspectorSearchTerm, setInspectorSearchTerm] = useState<string>('');
  const [inspectorValue, setInspectorValue] = useState<DropdownOptionType | undefined>(undefined);
  const [inspectorOptions, setInspectorOptions] = useState<DropdownOptionType[]>([]);
  const [title, setTitle] = useState<string>('');
  const [frequencyValue, setFrequencyValue] = useState<DropdownOptionType | undefined>(undefined);
  const [dayValue, setDayValue] = useState<DropdownOptionType | undefined>(undefined);
  const [scheduledDate, setScheduledDate] = useState<Date | undefined>(undefined);
  const now = new Date();
  const firstDayThisMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const timeSearchItems = generateTimeSlots();
  const [time, setTime] = useState<DropdownOptionType | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const orgOptions: DropdownOptionType[] = orgs.map((org: { orgName: string, orgId: string }) => ({
    value: `${org.orgId}`,
    label: `${org.orgName}`
  }));

  const fetchInspectorOptions = async () => {
    const inspectors = await getAllInspectors();
    const formattedInspectors = inspectors.map((inspector) => ({
      value: `${inspector.inspectorId}`,
      label: `${inspector.name}`
    }));
    return formattedInspectors;
  };

  const fetchInspectorData = async () => {
    const options = await fetchInspectorOptions();
    setInspectorOptions(options);
  };
  useEffect(() => {
    fetchInspectorData();
  }, []);

  const handleLeakReportSelected = (type: 'monthly' | 'leak') => {
    switch (type) {
      case 'monthly':
        setSelectedReport(reportsEnum.monthlyReport);
        setToggleOption('One-Time Report');
        break;
      case 'leak':
        if (selectedReport !== reportsEnum.leakReport) {
          setSelectedReport(reportsEnum.leakReport);
        } else {
          setSelectedReport('');
        }
        break;
      default:
        break;
    }
  };

  const isDisabled = () => {
    const isOneTimeLeakReportValid = (selectedReport === reportsEnum.leakReport && toggleOption === 'One-Time Report'
      && startDate && endDate && orgValue);

    const isScheduledLeakReportValid = (
      selectedReport === reportsEnum.leakReport && toggleOption === 'Scheduled Report' && orgValue
    ) && (
      (frequencyValue?.value === 'day' && time && title)
        || (frequencyValue?.value === 'week' && dayValue && time && title)
        || (frequencyValue?.value === 'month' && scheduledDate && time && title)
    );

    const isMonthlyOverviewValid = selectedReport === reportsEnum.monthlyReport && startDate && endDate && orgValue;

    return !(isOneTimeLeakReportValid || isScheduledLeakReportValid || isMonthlyOverviewValid);
  };

  const buildOneTimeReportData = () => ({
    orgId: orgValue?.value ? parseInt(String(orgValue.value), 10) : 0,
    siteId: siteValue?.value.toString() ?? 'All',
    inspectorId: inspectorValue?.value.toString() ?? 'All',
    startTime: formatDate(startDate),
    endTime: formatDate(endDate?.setDate(endDate.getDate() + 1))
  });

  const buildScheduledReportData = () => ({
    userReportName: title,
    reportName: selectedReport,
    cronUtc: generateCronExpression(
      frequencyValue?.value.toString() ?? 'day',
      time?.value.toString() ?? '00:00',
      dayValue?.value.toString(),
      scheduledDate
    ),
    options: {
      orgId: parseInt(String(orgValue?.value ?? ''), 10),
      siteId: siteValue?.value.toString() ?? 'All',
      inspectorId: inspectorValue?.value.toString() ?? 'All',
      reportName: title
    }
  });

  const triggerSuccessNotification = () => {
    triggerGlobalNotification(
      'success',
      'Your report was successfully created and downloaded..',
      'Successfully scheduled report',
      6000
    );
  };

  const triggerErrorNotification = (error: Error) => {
    triggerGlobalNotification(
      'error',
      `There was a problem generating your report. Please try again. ${error.message}`,
      'Report failed',
      6000
    );
  };

  const generateReport = async () => {
    setLoading(true);
    if (!selectedReport) { return; }
    if (toggleOption === 'One-Time Report') {
      const data = buildOneTimeReportData();
      immediateReportingPost(data, token, selectedReport)
        .then(() => {
          setLoading(false);
          triggerSuccessNotification();
          setSelectedReport('');
        }).catch((error) => {
          setLoading(false);
          triggerErrorNotification(error);
        });
    } else if (toggleOption === 'Scheduled Report' && frequencyValue && time) {
      const data = buildScheduledReportData();
      scheduledReportingPost(data, token)
        .then(() => {
          setLoading(false);
          triggerSuccessNotification();
          setSelectedReport('');
        }).catch((error) => {
          setLoading(false);
          triggerErrorNotification(error);
        });
    }
  };

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setDayValue(undefined);
    setTime(undefined);
    setFrequencyValue(undefined);
    setTitle('');
    setOrgValue(undefined);
    setSiteValue(undefined);
    setInspectorValue(undefined);
  }, [selectedReport]);

  useEffect(() => {
    const sitesOnOrg = orgs.find(
      (org: { orgName: string, netsuiteId: string }) => `${org.orgName}` === orgValue?.label
    )?.sites;
    const formattedSiteOptions = sitesOnOrg?.map((siteOnOrg: { siteName: string, siteId: string }) => ({
      value: `${siteOnOrg.siteId}`,
      label: `${siteOnOrg.siteName}`
    }));
    setSiteOptions(formattedSiteOptions || []);
    setSiteValue(undefined);
  }, [orgValue]);

  return (
    <div className="report-container">
      <div>
        <div className="label-container--row margin-bottom-8">
          <Header className="label" headerSize="h6">Choose Report Type</Header>
          <Header className="label red" headerSize="h6"> * </Header>
        </div>
        <div className="content-container">
          <Card
            onCardClick={() => { handleLeakReportSelected('leak'); }}
            active={selectedReport === reportsEnum.leakReport}
          >
            <Checkbox
              title="Leak report"
              subTitle="This report provides high-leveled daily summaries and comprehensive overviews
                   of all inspections conducted."
              onChecked={() => {}}
              checked={selectedReport === reportsEnum.leakReport}
            />
          </Card>
          {/* <Card
            onCardClick={() => { handleLeakReportSelected('monthly'); }}
            active={monthlyReportSelected}
          >
            <Checkbox
              title="Monthly Overview Report"
              subTitle="This report provides high-leveled daily summaries and comprehensive overviews
                of all inspections conducted."
              onChecked={() => {}}
              checked={monthlyReportSelected}
            />
          </Card> */}
        </div>
      </div>
      {selectedReport && (
        <Toggle
          firstOption="One-Time Report"
          secondOption="Scheduled Report"
          activeOption={toggleOption}
          onToggle={() => setToggleOption(toggleOption === 'One-Time Report' ? 'Scheduled Report' : 'One-Time Report')}
          disabled={selectedReport === reportsEnum.monthlyReport}
        />
      )}
      {selectedReport && (
        <div className="content-container">
          <div>
            <div className="label-container--row">
              <Header className="input-label" headerSize="h6">Organization</Header>
              <Header className="label red" headerSize="h6"> * </Header>
            </div>
            <Dropdown
              id="org-dropdown"
              onSelect={(option) => setOrgValue(option)}
              placeholder="Select Organization"
              value={orgValue?.label ?? ''}
              options={orgOptions.filter((option) => option.label.toLowerCase().includes(orgSearchTerm.toLowerCase()))}
              searchValue={orgSearchTerm}
              onSearchChange={(value) => { setOrgSearchTerm(value); }}
              onClear={() => setOrgValue(undefined)}
              fixedWidth="320px"
            />
          </div>
          <div>
            <Header className="input-label" headerSize="h6">Site</Header>
            <Dropdown
              id="site-dropdown"
              onSelect={(option) => setSiteValue(option)}
              placeholder="Select Site"
              value={siteValue?.label ?? ''}
              options={
                siteOptions.filter((option) => option.label.toLowerCase().includes(siteSearchTerm.toLowerCase()))
              }
              searchValue={siteSearchTerm}
              onSearchChange={(value) => { setSiteSearchTerm(value); }}
              onClear={() => setSiteValue(undefined)}
              fixedWidth="320px"
            />
          </div>
          <div>
            <Header className="input-label" headerSize="h6">Inspector</Header>
            <Dropdown
              id="inspector-dropdown"
              onSelect={(option) => setInspectorValue(option)}
              placeholder="Select Inspector"
              value={inspectorValue?.label ?? ''}
              options={inspectorOptions.filter(
                (option) => option.label.toLowerCase().includes(inspectorSearchTerm.toLowerCase())
              )}
              searchValue={inspectorSearchTerm}
              onSearchChange={(value) => { setInspectorSearchTerm(value); }}
              onClear={() => setInspectorValue(undefined)}
              fixedWidth="320px"
            />
          </div>
        </div>
      )}
      {(selectedReport && toggleOption === 'Scheduled Report') && (
        <div className="content-container">
          <div>
            <div className="label-container--row">
              <Header className="input-label" headerSize="h6">Title</Header>
              <Header className="label red" headerSize="h6"> * </Header>
            </div>
            <Input
              placeholder="My report title"
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              fixedWidth="320px"
            />
          </div>
          <div>
            <div className="label-container--row">
              <Header className="input-label" headerSize="h6">Frequency</Header>
              <Header className="label red" headerSize="h6"> * </Header>
            </div>
            <Dropdown
              id="frequency-dropdown"
              onSelect={(option) => setFrequencyValue(option)}
              placeholder="Select Frequency"
              value={frequencyValue?.label ?? ''}
              options={frequencySearchItems}
              fixedWidth="320px"
            />
          </div>
          {frequencyValue?.value !== 'day' && (
            frequencyValue?.value === 'week' ? (
              <div>
                <div className="label-container--row">
                  <Header className="input-label" headerSize="h6">Start Date</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <Dropdown
                  id="start-date-dropdown"
                  onSelect={(option) => setDayValue(option)}
                  placeholder="Select Start Date"
                  value={dayValue?.label ?? ''}
                  options={daySearchItems}
                  fixedWidth="320px"
                />
              </div>
            ) : (
              <div>
                <div className="label-container--row">
                  <Header className="input-label" headerSize="h6">Scheduled Date</Header>
                  <Header className="label red" headerSize="h6"> * </Header>
                </div>
                <DatePicker
                  selectedDate={scheduledDate || null}
                  setSelectedDate={(date) => setScheduledDate(date || undefined)}
                  minDate={firstDayThisMonth}
                  maxDate={lastDayThisMonth}
                  allowedMonthRange={{ startDate: 1, endDate: 28 }}
                />
              </div>
            )
          )}
          <div>
            <div className="label-container--row">
              <Header className="input-label" headerSize="h6">Time</Header>
              <Header className="label red" headerSize="h6"> * </Header>
            </div>
            <Dropdown
              id="time-dropdown"
              onSelect={(option) => setTime(option)}
              placeholder="Select Time"
              value={time?.label ?? ''}
              options={timeSearchItems}
              fixedWidth="320px"
            />
          </div>
        </div>
      )}
      {((selectedReport === reportsEnum.leakReport && toggleOption === 'One-Time Report')
        || selectedReport === reportsEnum.monthlyReport) && (
        <div className="date-container">
          <div className="label-container">
            <div className="label-container--row">
              <Header className="label" headerSize="h6">Date Range</Header>
              <Header className="label red" headerSize="h6"> * </Header>
            </div>
            <Header className="label--description" headerSize="h6">The range of collected data to report.</Header>
          </div>
          {selectedReport === reportsEnum.leakReport && toggleOption === 'One-Time Report'
            ? (
              <DateRangePicker
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                setSelectedEndDate={(date) => setEndDate(date)}
                setSelectedStartDate={(date) => setStartDate(date)}
                variant="dateRange"
              />
            )
            : (
              <DateRangePicker
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                setSelectedEndDate={(date) => setEndDate(date)}
                setSelectedStartDate={(date) => setStartDate(date)}
                variant="month"
              />
            )}
        </div>
      )}
      <div className="generate-button-container">
        <Button
          className="generate-button"
          onClick={() => generateReport()}
          disabled={isDisabled() || loading}
          loading={loading}
        >
          Generate
        </Button>
      </div>
    </div>
  );
};

export default CreateNewReport;
