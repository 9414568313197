import React from 'react';
import { GlobalProvider } from './context/GlobalContext';
import { ModifyInspectionsProvider } from './context/ModifyInspectionsContext';
import { InspectionProvider } from './context/InspectionContext';

const AppProviders = ({ children }) => (
  <GlobalProvider>
    <ModifyInspectionsProvider>
      <InspectionProvider>{children}</InspectionProvider>
    </ModifyInspectionsProvider>
  </GlobalProvider>
);

export default AppProviders;
