import styled from 'styled-components';

const TableButtonContainer = styled.div`
    width: 96px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    margin-left: 8px;
`;

export default TableButtonContainer;
