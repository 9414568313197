import styled from 'styled-components';

import { global } from '../../../shared/colors';

const StyledUpload = styled.div`
    padding: 24px;
    width: 100%;
    overflow: auto;
    height: ${(props) => (props.height ? props.height : 'auto')};

    .upload--form-buttons {
        display: flex;
        justify-content: flex-end;
    }

    .upload--button {
        margin-left: 0.5rem;
        margin-top: 2rem;
        border-radius: 5px;
        border-color: ${global.PrimaryBlue} !important;
        color: ${global.Gray3} !important;
        font-family: 'Inter', sans-serif, Arial, Helvetica !important;
        width: 6rem;
    }

    .upload-input {
        height: 38px;
        min-width: 130px;
    }

    .ant-table-cell {
        color: ${global.Gray3};
        font-family: Inter;
    }

    .ant-table-thead .ant-table-cell {
        background-color: none;
        background: none;
        border-bottom: none;
    }

    .ant-space {
        display: flex;
        justify-content: space-between;
    }

    .ant-input,
    .ant-space-item {
        color: ${global.Gray3};
    }

    .anticon.anticon-check {
        svg {
            color: ${global.PrimaryBlue};
        }
    }

    .anticon.anticon-close {
        svg {
            color: lightcoral;
        }
    }
`;

export default StyledUpload;
