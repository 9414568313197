import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'antd';

import InspectionUpload from '../../pages/MultiUpload/InspectionUpload';
import { global } from '../../shared/colors';

const StyledModal = styled(Modal)`
    .ant-modal-header {
        display: flex;
        background: ${global.PrimaryBlue};
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 10px 10px 0 0;
        height: 55px;
    }
    .ant-modal-title {
        color: ${global.White};
        text-align: center;
    }
    .ant-modal-content {
        color: ${global.White};
        padding: 0;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }
    .ant-modal-close-x {
        color: ${global.White};
    }
    .ant-table {
        height: 25vh;
    }
    .ant-table-wrapper .ant-table {
        overflow-y: auto;
    }
    .ant-modal-root .ant-modal-mask {
        z-index: 99999;
    }
`;

const FileUploadModal = ({
  inspection, isVisible, onOk, onCancel
}) => (
  <main>
    <StyledModal
      title={`Attach File to ${inspection?.flogistixId}`}
      aria-label="Attach File"
      visible={isVisible}
      onOk={onOk}
      onCancel={onCancel}
      width="75%"
      footer={null}
    >
      <section>
        <InspectionUpload selectedInspection={inspection} />
      </section>
    </StyledModal>
  </main>
);

FileUploadModal.propTypes = {
  inspection: PropTypes.shape({
    flogistixId: PropTypes.string
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default FileUploadModal;
