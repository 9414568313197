import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  IoAlertCircleOutline,
  IoCloseOutline,
  IoCheckmarkCircleSharp
} from 'react-icons/io5';

import './Notification.css';
import { GlobalContext } from '../../context/GlobalContext';
import { INFINITE } from '../../shared/constants';

const Notification = () => {
  const { globalNotificationType, closeGlobalNotification } = useContext(GlobalContext);
  const {
    show, message, type, duration, customTitle
  } = globalNotificationType;

  useEffect(() => {
    let timer;
    if (show && duration !== INFINITE) {
      timer = setTimeout(() => {
        closeGlobalNotification();
      }, duration);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show, closeGlobalNotification]);

  const animationClass = show ? 'notification-enter' : 'notification-exit';
  const title = customTitle || (type.charAt(0).toUpperCase() + type.slice(1));

  const getIcon = (iconType) => {
    const iconSize = '24px';
    switch (iconType) {
      case 'error':
        return <IoAlertCircleOutline color="#FF5353" size={iconSize} />;
      case 'success':
        return <IoCheckmarkCircleSharp color="#0A84FF" size={iconSize} />;
      default:
        return null;
    }
  };

  const getCloseIconColor = (iconType) => {
    switch (iconType) {
      case 'error':
        return '#FF5353';
      case 'success':
        return '#0A84FF';
      default:
        return '#0A84FF';
    }
  };

  return (
    <aside
      className={`notification ${type} ${animationClass}`}
      style={{ display: show ? 'flex' : 'none' }}
      role="alert"
      aria-live="assertive"
    >
      <div className="alert-section">
        <div className="alert-icon">{getIcon(type)}</div>
        <div>
          <h2 className="close-header">{title}</h2>
          <p className="alert-message">{message}</p>
        </div>
      </div>
      <button
        onClick={closeGlobalNotification}
        className="close"
        aria-label="Close notification"
        type="button"
      >
        <IoCloseOutline color={getCloseIconColor(type)} size="24px" />
      </button>
    </aside>
  );
};

Notification.propTypes = {
  globalNotificationType: PropTypes.shape({
    show: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'success'])
  })
};

Notification.defaultProps = {
  globalNotificationType: {
    show: false,
    message: '',
    type: 'error'
  }
};

export default Notification;
