import React from 'react';
import PropTypes from 'prop-types';
import { IconContainer, StyledTooltip } from './styled-components';

const IconToggle = ({
  children,
  tooltip,
  noHover,
  style,
  onClick,
  disabled,
  ariaLabel,
  ...otherProps
}) => {
  const defaultStyles = {
    alignSelf: 'end'
  };

  const styles = {
    ...defaultStyles,
    ...style
  };

  return (
    <IconContainer noHover={!!noHover} disabled={disabled}>
      <StyledTooltip title={tooltip}>
        <button
          type="button"
          aria-label={ariaLabel || tooltip}
          aria-disabled={disabled}
          {...otherProps}
          style={styles}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </button>
      </StyledTooltip>
    </IconContainer>
  );
};

IconToggle.propTypes = {
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string.isRequired,
  noHover: PropTypes.bool,
  style: PropTypes.shape({
    alignSelf: PropTypes.string
  }),
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string
};

IconToggle.defaultProps = {
  noHover: false,
  style: { alignSelf: 'end' },
  disabled: false,
  ariaLabel: null
};

export default IconToggle;
