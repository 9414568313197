import getIAM from './getIAM';

const getOrgs = async (token, setOrganizations, triggerGlobalNotification) => {
  try {
    const orgs = await getIAM('/iam/orgs', token);
    setOrganizations(orgs?.orgs);
  } catch (err) {
    triggerGlobalNotification(
      'error',
      'Failed to fetch organizations'
    );
  }
};

export default getOrgs;
