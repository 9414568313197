import styled from 'styled-components';

const HeaderWrapper = styled.div`
    display: flex;
    font-size: 16px;
    align-items: baseline;
    flex-direction: column;
`;

export default HeaderWrapper;
