/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { IoCloseOutline, IoCalendarClearOutline } from 'react-icons/io5';
import PropTypes from 'prop-types';

import './datepicker.css';
import { global } from '../../shared/colors';

const Datepicker = ({ selected, onChange, id = '' }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(selected ? selected.split('T')[0] : '');
  const [currentDate, setCurrentDate] = useState(new Date());

  const handleDayClick = (dayString) => {
    setSelectedDate(dayString);
  };

  const handleSave = () => {
    if (onChange) {
      onChange(selectedDate);
    }
    setShowCalendar(false);
  };

  const handleClear = () => {
    setSelectedDate('');
    setShowCalendar(false);
    if (onChange) {
      onChange(null);
    }
  };

  const handleClose = () => {
    setShowCalendar(false);
  };

  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };

  const handlePreviousYear = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1)
    );
  };

  const handleNextYear = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear() + 1, currentDate.getMonth(), 1)
    );
  };

  const weekdays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  const generateWeekdays = () => weekdays.map((day) => (
    <div key={day} className="weekday">
      {day}
    </div>
  ));

  const generateCalendarDays = () => {
    const days = [];
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const firstDayOfMonth = new Date(year, month, 1).getDay();

    for (let i = 0; i < firstDayOfMonth; i += 1) {
      days.push(
        <div key={`empty-${i}`} className="day-button empty">
          {' '}
        </div>
      );
    }

    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const today = new Date();

    for (let i = 1; i <= daysInMonth; i += 1) {
      const dayDate = new Date(year, month, i);
      const dayString = dayDate.toISOString().split('T')[0];
      const formattedDayNumber = i.toString().padStart(2, '0');

      const isToday = today.getDate() === dayDate.getDate()
                && today.getMonth() === dayDate.getMonth()
                && today.getFullYear() === dayDate.getFullYear();

      let buttonClass = 'day-button';
      if (selectedDate === dayString) {
        buttonClass = 'day-button active';
      } else if (isToday) {
        buttonClass = 'day-button today';
      }

      days.push(
        <button
          id={`day-button-${i}`}
          type="button"
          key={i}
          className={buttonClass}
          onClick={() => handleDayClick(dayString)}
        >
          {formattedDayNumber}
        </button>
      );
    }
    return days;
  };

  const formatDate = (date) => {
    const options = {
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC'
    };
    return date.toLocaleDateString(undefined, options).replace(' ', ', ');
  };

  const formatDateForSubHeader = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (Number.isNaN(date)) {
      console.error(`Invalid dateString in formatDateForSubHeader: ${dateString}`);
      return '';
    }

    const options = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    };

    return date.toLocaleDateString(undefined, options);
  };

  const formatDateForHeader = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    if (Number.isNaN(date)) {
      console.error(`Invalid dateString in formatDateForHeader: ${dateString}`);
      return '';
    }

    const options = {
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    };

    return date.toLocaleDateString(undefined, options);
  };

  const HeaderDate = () => (
    <>
      <h1 id="date-header" className="headerDate">{formatDateForHeader(selectedDate)}</h1>
      <h2 id="date-subheader" className="headerSubDate">
        {formatDateForSubHeader(selectedDate)}
      </h2>
    </>
  );

  const TodayDate = () => {
    const todayString = new Date().toISOString().split('T')[0];

    return (
      <>
        <h1 className="headerDate">Today</h1>
        <h2 id="today-subheader" className="headerSubDate">
          {formatDateForSubHeader(todayString)}
        </h2>
      </>
    );
  };

  const headerTitle = selectedDate ? <HeaderDate /> : <TodayDate />;

  const currentMonthYear = formatDate(currentDate);

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const dateOnlyString = dateString.split('T')[0]; // Strip the time component
    const [year, month, day] = dateOnlyString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    if (Number.isNaN(date)) {
      console.error(`Invalid dateString in formatDateForInput: ${dateString}`);
      return '';
    }

    const formattedDay = date.getDate().toString().padStart(2, '0');
    const formattedMonth = (date.getMonth() + 1)
      .toString()
      .padStart(2, '0');
    const formattedYear = date.getFullYear();
    return `${formattedMonth} / ${formattedDay} / ${formattedYear}`;
  };

  return (
    <div className="datepicker-container">
      <div className="input-wrapper">
        <input
          id={id}
          type="text"
          value={formatDateForInput(selectedDate)}
          readOnly
          onClick={() => setShowCalendar(true)}
          placeholder="Select date"
        />
        <IoCalendarClearOutline
          className="calendar-icon"
          onClick={() => setShowCalendar(true)}
          style={{
            color: global.Gray3,
            fontSize: '24px',
            marginRight: '20px',
            stroke: '1px'
          }}
        />
      </div>

      {showCalendar && (
        <div className="calendar-panel">
          <div className="calendar-header">
            <span>{headerTitle}</span>
            <button
              type="button"
              className="close-button"
              onClick={handleClose}
            >
              <IoCloseOutline size={40} />
            </button>
          </div>
          <section className="month-section">
            <div className="month-year-header">
              <div>
                <button
                  id="left-year-button"
                  type="button"
                  className="arrow-button"
                  onClick={handlePreviousYear}
                >
                  &laquo;
                </button>
                <button
                  id="left-month-button"
                  type="button"
                  className="arrow-button"
                  onClick={handlePreviousMonth}
                >
                  &lsaquo;
                </button>
              </div>
              <span id="date-label" className="currentMonthYear">
                {currentMonthYear}
              </span>
              <div>
                <button
                  id="right-month-button"
                  type="button"
                  className="arrow-button"
                  onClick={handleNextMonth}
                >
                  &rsaquo;
                </button>
                <button
                  id="right-year-button"
                  type="button"
                  className="arrow-button"
                  onClick={handleNextYear}
                >
                  &raquo;
                </button>
              </div>
            </div>
            <div className="weekdays-grid">
              {generateWeekdays()}
            </div>
            <div className="days-grid">
              {generateCalendarDays()}
            </div>
          </section>
          <div className="panel-actions">
            <button
              id="cancel-button"
              className="cancel"
              type="button"
              onClick={handleClear}
            >
              Cancel
            </button>
            <button
              id="save-button"
              className="save"
              type="button"
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

Datepicker.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Datepicker;
