/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IoChevronDownOutline, IoChevronUpOutline, IoRemoveCircle } from 'react-icons/io5';
import {
  Button,
  Dropdown,
  DualInput,
  Input,
  Toggle
} from '@flogistix/flo-ui';
import { InspectionContext } from '../../../../../context/InspectionContext';
import { ComponentType } from '../../../../../classes/enums';
import { Leak } from '../../../../../classes/leak';
import { UploadFile } from '../../../../../classes/airmethane-file';
import { DropdownOptionType } from '../../../shared/typings';
import { PRIORITY_OPTIONS } from '../../../../../shared/constants';
import TypedDropzone from '../../../../../components/FileDropzone/TypedDropzone/TypedDropzone';
import { global } from '../../../../../shared/colors';
import './LeakCard.scss';

interface LeakImage {
  id?: string;
  fileType?: string;
  fileName?: string;
}

const LeakCard = ({
  leak,
  leakIndex,
  deleteLeak
}: {
  leak: Leak,
  leakIndex: number,
  deleteLeak: () => void,
}) => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [leakTagNumber, setTagNumber] = useState(leak?.tagNumber || '');
  const [leakComponentType, setComponentType] = useState(leak?.componentType || undefined);
  const [leakComponentSubtype, setComponentSubtype] = useState(leak?.componentSubtype || '');
  const [leakPriority, setPriority] = useState(leak?.priority || 'None');
  const [leakNotes, setNotes] = useState(leak?.notes || '');
  const [leakLocation, setLocation] = useState(leak?.location || '');
  const [leakTimestamp, setTimestamp] = useState(leak?.timestamp || '');
  const [timeStampMinutes, setTimestampMinutes] = useState(leak?.timestamp?.split(':')[0] || '');
  const [timeStampSeconds, setTimestampSeconds] = useState(leak?.timestamp?.split(':')[1] || '');
  const [leakRate, setRate] = useState(leak?.rate || '');
  const [leakRateUom, setRateUom] = useState(leak?.rateUom || 'ppm');
  const [leakFiles, setLeakFiles] = useState(leak?.files?.map((file) => ({ id: file.id })) || []);
  const [firstImage, setFirstImage] = useState(leak?.files?.at(0) ?? {} as LeakImage);
  const [secondImage, setSecondImage] = useState(leak?.files?.at(1) ?? {} as LeakImage);
  const [componentTypeSearch, setComponentTypeSearch] = useState('');
  const [prioritySearch, setPrioritySearch] = useState('');
  const handleTagNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagId = e.target.value as string;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      tagNumber: tagId
    } as Leak).then(() => {
      setTagNumber(tagId);
    });
  };

  const handleComponentTypeChange = (selectedOption: DropdownOptionType) => {
    const componentType = selectedOption.value as ComponentType;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      componentType
    } as Leak).then(() => {
      setComponentType(componentType);
      setCompletedSectionsOnChange();
    });
  };

  const handleComponentSubtypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const componentSubtype = e.target.value as string;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      componentSubtype
    } as Leak).then(() => {
      setComponentSubtype(componentSubtype);
      setCompletedSectionsOnChange();
    });
  };

  const handlePriorityChange = (selectedOption: DropdownOptionType) => {
    const priority = selectedOption.value as string;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      priority
    } as Leak).then(() => {
      setPriority(priority);
      setCompletedSectionsOnChange();
    });
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const notes = e.target.value as string;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      notes
    } as Leak).then(() => {
      setNotes(notes);
      setCompletedSectionsOnChange();
    });
  };

  const handleLocationBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const location = e.target.value as string;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      location
    } as Leak).then(() => {
      setLocation(location);
      setCompletedSectionsOnChange();
    });
  };

  const handleTimestampMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const minutes = e.target.value.padStart(2, '0');
    const newTimestamp = `${minutes || '00'}:${timeStampSeconds || '00'}`;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      timestamp: newTimestamp
    } as Leak).then(() => {
      setTimestamp(newTimestamp);
      setTimestampMinutes(minutes);
      setCompletedSectionsOnChange();
    });
  };

  const handleTimestampSecondsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const seconds = e.target.value.padStart(2, '0');
    const newTimestamp = `${timeStampMinutes || '00'}:${seconds || '00'}`;
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      timestamp: newTimestamp
    } as Leak).then(() => {
      setTimestamp(newTimestamp);
      setTimestampSeconds(seconds);
      setCompletedSectionsOnChange();
    });
  };

  const handleRateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rate = Number(e.target.value);
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      rate
    } as Leak).then(() => {
      setRate(rate);
      setCompletedSectionsOnChange();
    });
  };

  const handleRateUomChange = () => {
    const newRateUom = leakRateUom === 'ppm' ? 'mcf' : 'ppm';
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      rateUom: newRateUom
    } as Leak).then(() => {
      setRateUom(newRateUom);
      setCompletedSectionsOnChange();
    });
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
    firstOrSecondInput: number
  ) => {
    const pendingFiles: UploadFile[] = [];
    const files = e.type === 'drop'
      ? (e as React.DragEvent<HTMLDivElement>).dataTransfer.files
      : (e as React.ChangeEvent<HTMLInputElement>).target.files;
    const file = files?.[0];
    if (file) {
      pendingFiles.push({ file, id: uuidv4(), displayName: file.name });
      leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
        ...leak,
        pendingFiles
      } as Leak).then(() => {
        const queuedFiles = pendingFiles.map((f) => ({
          id: f.id,
          fileType: f.file.type,
          fileName: f.displayName
        }));
        setLeakFiles([...leakFiles, ...queuedFiles]);
        if (firstOrSecondInput === 1) {
          setFirstImage(queuedFiles[0]);
        } else {
          setSecondImage(queuedFiles[0]);
        }
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleRemoveFile = (fileId: string, firstOrSecondInput: number) => {
    const idToRemove = fileId;
    const newFiles = leak.files?.filter((file) => file.id !== idToRemove) ?? [];
    leak!.patchLeak(token, inspection!.flogistixId!, inspection!, {
      ...leak,
      files: newFiles,
      pendingFiles: []
    } as unknown as Leak).then(() => {
      const newLeakFiles = leakFiles.filter((file) => file.id !== idToRemove);
      setLeakFiles(newLeakFiles);
      if (firstOrSecondInput === 1) {
        setFirstImage({});
      } else {
        setSecondImage({});
      }
      setCompletedSectionsOnChange();
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteButtonRef.current
        && !deleteButtonRef.current.contains(event.target as Node)
      ) {
        setShowDelete(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="delete-button-wrapper" ref={deleteButtonRef}>
      {showDelete ? (
        <Button
          variation="red-outline"
          className="delete-button-container"
          size="medium"
          type="button"
          onClick={deleteLeak}
        >
          Delete
        </Button>
      ) : (
        <button
          className="icon-button"
          type="button"
          onClick={() => setShowDelete(true)}
          aria-label="Remove item"
        >
          <IoRemoveCircle className="remove-icon" size={24} />
        </button>
      )}
      <div className={`leak-card-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="header-wrapper">
          <div className="repair-header">
            <h5 className="repair-header">{`Leak ${leakIndex + 1}`}</h5>
            <button
              type="button"
              className="toggle-button"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <IoChevronUpOutline style={{ color: global.Gray4 }} />
              ) : (
                <IoChevronDownOutline style={{ color: global.Gray4 }} />
              )}
            </button>
          </div>
        </div>
        {isExpanded && (
          <div className="fields-container">
            <label className="label">Leak ID/Tag</label>
            <Input
              type="text"
              name="tagNumber"
              defaultValue={leakTagNumber}
              onBlur={handleTagNumberChange}
              placeholder="12345678"
              className="input__text"
              fixedWidth="100%"
            />
            <label className="label">Component Type</label>
            <Dropdown
              value={leakComponentType as string}
              id="component-type"
              onSelect={handleComponentTypeChange}
              className="input__select"
              options={Object.values(ComponentType).map(
                (type) => ({ value: type, label: type })
              ).filter(
                (type) => !componentTypeSearch
                  || type.label.toLowerCase().includes(componentTypeSearch.toLowerCase())
              )}
              placeholder="Component Type"
              searchValue={componentTypeSearch}
              onSearchChange={(value) => setComponentTypeSearch(value)}
              fixedWidth="100%"
            />
            <label className="label">Sub-component</label>
            <Input
              type="text"
              name="componentSubtype"
              defaultValue={leakComponentSubtype}
              onBlur={handleComponentSubtypeChange}
              placeholder="Type sub-component"
              className="input__text"
              fixedWidth="100%"
            />
            <label className="label">Timestamp</label>
            <p className="sub-label">The timestamp in the video this leak occurs</p>
            <DualInput
              containerClass="timestamp-input"
              width={500}
              leftInput={{
                onBlur: handleTimestampMinuteChange,
                placeholder: '0',
                suffix: 'minutes',
                type: 'number',
                defaultValue: timeStampMinutes,
                maxLength: 2
              }}
              rightInput={{
                onBlur: handleTimestampSecondsChange,
                placeholder: '0',
                suffix: 'seconds',
                type: 'number',
                defaultValue: timeStampSeconds,
                maxLength: 2
              }}
            />
            <label className="label">Location</label>
            <Input
              type="text"
              defaultValue={leakLocation}
              onBlur={handleLocationBlur}
              placeholder="Northeast corner of the tank"
              className="input__text"
              fixedWidth="100%"
            />
            <label className="label">Leak Measurement</label>
            <Toggle
              className="rate-toggle"
              firstOption="PPM.M"
              secondOption="MCF"
              activeOption={leakRateUom === 'mcf' ? 'MCF' : 'PPM.M'}
              onToggle={handleRateUomChange}
              disabled={leakRateUom === undefined}
            />
            <label className="label">
              {leakRateUom === 'mcf' ? 'MCF' : 'PPM.M'}
            </label>
            <Input
              type="number"
              onWheel={(e) => e.currentTarget.blur()}
              defaultValue={leakRate}
              onBlur={handleRateChange}
              placeholder="Enter Value"
              className="input__text"
              fixedWidth="100%"
            />
            <label className="label">Priority</label>
            <Dropdown
              value={leakPriority}
              onSelect={handlePriorityChange}
              className="input__select"
              options={PRIORITY_OPTIONS.filter(
                (option) => !prioritySearch || option.label.toLowerCase().includes(prioritySearch.toLowerCase())
              )}
              placeholder="Select priority"
              fixedWidth="100%"
              searchValue={prioritySearch}
              onSearchChange={(value) => setPrioritySearch(value)}
            />
            <label className="label">Leak summary</label>
            <div className="leak-summary">
              <div className="leak-summary__header">
                <h5>{leakComponentType}</h5>
                <p>{leakTimestamp}</p>
              </div>
              <div className="leak-summary__content">
                <p>{leakComponentSubtype}</p>
                <p>{leakRateUom}</p>
              </div>
              <p className="leak-summary__location">{leakLocation}</p>
            </div>
            <label className="label">Leak images</label>
            <p className="sub-label">Files should be .jpg, .png, or .heic</p>
            <div className="leak-images">
              <TypedDropzone
                onFileChange={(e) => handleFileChange(e, 1)}
                acceptedTypes=".png, .jpg, .jpeg, .heic"
                removeHandler={() => handleRemoveFile(firstImage?.id ?? '', 1)}
                showRemoveButton={!!firstImage?.id}
                fileId={firstImage?.id}
              />
              <TypedDropzone
                onFileChange={(e) => handleFileChange(e, 2)}
                acceptedTypes=".png, .jpg, .jpeg, .heic"
                removeHandler={() => handleRemoveFile(secondImage?.id ?? '', 2)}
                showRemoveButton={!!secondImage?.id}
                fileId={secondImage?.id}
              />
            </div>
            <label className="label">Notes</label>
            <Input
              type="text"
              defaultValue={leakNotes}
              onBlur={handleNotesChange}
              placeholder="Anything else you would like to add?"
              className="input__text"
              fixedWidth="100%"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LeakCard;
