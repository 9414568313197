import React from 'react';
import { Skeleton } from 'antd';

const SkeletonHeader = () => (
  <Skeleton.Input
    style={{ height: '14px', width: '200px' }}
    size="small"
    active
    paragraph={{ rows: 1 }}
  />
);

export default SkeletonHeader;
