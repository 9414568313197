import styled from 'styled-components';
import { global } from '../../../shared/colors';

const InspectionBreadcrumb = styled.button`
    background: none;
    border: none;
    color: ${global.Gray4};
    cursor: pointer;
    font-weight: 400;
    white-space: nowrap;
    font-size: 16px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;

    &:hover {
        text-decoration: underline;
        color: ${global.Black};
    }
`;

export default InspectionBreadcrumb;
