import styled from 'styled-components';
import { Tabs } from 'antd';

import { global } from '../../../shared/colors';

const StyledTabs = styled(Tabs)`
    margin-left: 2%;
    margin-right: 2%;

    .ant-tabs-tab-btn {
        display: flex;
    }

    .ant-tabs .ant-tabs-tab-btn {
        justify-content: space-between;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${global.PrimaryBlue} !important;
        font-weight: 600;
    }

    .ant-tabs-ink-bar {
        position: absolute;
        background: ${global.PrimaryBlue};
        pointer-events: none;
    }

    .ant-tabs-tab-btn:hover {
        color: ${global.PrimaryBlue};
    }
`;

export default StyledTabs;
