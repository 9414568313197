import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { InspectionContext } from '../../../../context/InspectionContext';

const FinalSubmission = () => {
  const navigate = useNavigate();
  const { inspection, token } = useContext(InspectionContext);
  const isReady = inspection?.determineSubmissionReadiness();

  const handleSubmission = () => {
    inspection!.submitInspection(token).then(() => {
      navigate('/');
    });
  };

  return (
    <div>
      <h1>Before you submit</h1>
      <p>Make sure you have everything ready</p>
      <br />
      <br />
      <h4>I understand...</h4>
      <p>
        I understand that once I submit this form, I will not be
        able to make any changes or adjustments to the content or
        inspection created. I also confirm that all the documents
        and images included in this form meet the criteria for
        inspection established by the relevant state and federal
        regulations. I am aware that I will be held accountable if
        the documents and images fail to meet these standards.
        Therefore, I take full responsibility for the accuracy and
        completeness of the submission I am providing.
      </p>
      <br />
      <button
        type="button"
        className="btn btn-primary"
        disabled={!isReady}
        onClick={handleSubmission}
      >
        Submit inspection
      </button>
    </div>
  );
};

export default FinalSubmission;
