/* eslint-disable no-case-declarations */
import { JsonType, Transaction } from './db';
import { createTransaction } from './operations';
import { Leak, LeakRepair } from '../pages/InspectionLayout/typings/LeakTypes';
import { Verification } from '../pages/InspectionLayout/typings/VerificationTypes';
import { Survey } from '../pages/InspectionLayout/typings/SurveyTypes';
import { sendMessageToSW } from '../shared/utils';
import { urlPrefix, API_INSPECTION } from '../shared/url';

interface AirMethaneFile {
  displayName: string;
  sensorType: string;
  file: File
}

interface PostTransactionCreationProps {
  token: string;
  inspectionId: string;
  flogistixId: string;
  fileVersion: number;
}

interface SingleMonitoredComponent {
  componentType?: 'closed vent system' |
  'compressor' |
  'connector' |
  'cover' |
  'flange' |
  'instrument' |
  'meter' |
  'open ended line' |
  'pressure relief device' |
  'thief hatch' |
  'valve' |
  'other';
  difficultToMonitor?: boolean;
  difficultToMonitorDescription?: string;
  unsafeToMonitor?: boolean;
  unsafeToMonitorDescription?: string;
}

interface SingleFileUpload extends PostTransactionCreationProps {
  file?: AirMethaneFile | null;
  uploadedFiles?: { id: string }[];
}

interface SingleFacilitySign extends PostTransactionCreationProps {
  file: { id: string };
}

interface SingleVerificationBody extends PostTransactionCreationProps {
  file: { id: string };
  body: Verification;
  localId: string;
}

interface SingleLeakRepairBody extends PostTransactionCreationProps {
  file: { id: string };
  body: LeakRepair;
  localId: string;
  tagNumber: string;
}

interface SingleVerification extends PostTransactionCreationProps {
  verificationToDelete: Verification;
  newVerification: Verification;
  body: Verification;
}

interface MultiFileUpload extends PostTransactionCreationProps {
  files: AirMethaneFile[];
  uploadedFiles?: { id: string }[];
}

interface VerificationTransaction extends SingleFileUpload {
  body: Verification;
}

interface LeakTransaction extends MultiFileUpload {
  body: Leak;
  filesToDelete?: { id: string, version: number }[];
}

interface LeakRepairTransaction extends MultiFileUpload {
  oldRepair?: LeakRepair;
  newRepair?: LeakRepair;
  file?: { id: string };
  body: LeakRepair;
  tagNumber: string;
}

interface MonitoredComponentTransaction extends PostTransactionCreationProps {
  oldComp?: SingleMonitoredComponent;
  newComp?: SingleMonitoredComponent;
  body: LeakRepair;
  tagNumber: string;
}

interface DeviationTransaction extends PostTransactionCreationProps {
  newDev?: string;
  body: LeakRepair;
  tagNumber: string;
}

interface SurveyTransaction extends MultiFileUpload {
  body: Survey;
  filesToDelete?: { id: string, version: number }[];
}

export const createAddFacilitySignTransaction = async ({
  token,
  file,
  inspectionId,
  flogistixId
}: SingleFileUpload) => {
  const transaction = {
    queueId: `${flogistixId}->facility-sign`,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      payload: {
        pendingFiles: [{ ...file }],
        inspectionId
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/location-sign-file`,
      fileUpload: true
    }
  } as Transaction;

  await createTransaction(transaction);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const deleteFacilitySignTransaction = async ({
  token,
  file,
  fileVersion,
  inspectionId,
  flogistixId
}: SingleFacilitySign) => {
  const queueId = `${flogistixId}->facility-sign`;
  const transaction = {
    queueId,
    request: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
      payload: {},
      version: `${fileVersion}`,
      fileUpload: false
    }
  } as Transaction;

  await createTransaction(transaction);

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/location-sign-file`,
      payload: { id: '' },
      inspectionId
    }
  } as Transaction;

  await createTransaction(transactionPatch);

  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const createAddVerificationTransaction = async ({
  token,
  file,
  body,
  inspectionId,
  flogistixId,
  uploadedFiles
}: VerificationTransaction) => {
  const transaction = {
    queueId: `${flogistixId}->verification`,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      payload: {
        body,
        pendingFiles: (file ? [file] : []),
        inspectionId,
        uploadedFiles: uploadedFiles ?? []
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addVerification`,
      fileUpload: true
    }
  } as Transaction;

  await createTransaction(transaction);
};

export const deleteVerificationTransaction = async ({
  token,
  file,
  body,
  fileVersion,
  inspectionId,
  flogistixId
}: SingleVerificationBody) => {
  const queueId = `${flogistixId}->verification`;

  if (file) {
    const transaction = {
      queueId,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
        payload: {},
        version: `${fileVersion}`,
        fileUpload: false
      }
    } as Transaction;

    await createTransaction(transaction);
  }

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/removeVerification`,
      payload: {
        ...body
      },
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
};

export const createAddLeakTransaction = async ({
  token,
  body,
  files,
  inspectionId,
  flogistixId,
  uploadedFiles
}: LeakTransaction) => {
  const tagNumber = body?.tagNumber;
  const transaction = {
    queueId: `${flogistixId}->leak->${tagNumber}`,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      payload: {
        body,
        pendingFiles: files,
        uploadedFiles: uploadedFiles ?? [],
        inspectionId
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addLeak`,
      fileUpload: true
    }
  } as Transaction;

  await createTransaction(transaction);
};

export const deleteLeakAndAssociatedFiles = async ({
  token,
  flogistixId,
  body,
  inspectionId,
  filesToDelete = []
}: LeakTransaction) => {
  const tagNumber = body?.tagNumber;
  const queueId = `${flogistixId}->leak->${tagNumber}`;
  const deleteLeakTransaction = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/removeLeak`,
      payload: {
        ...body
      },
      fileUpload: false,
      inspectionId
    }
  } as Transaction;

  await createTransaction(deleteLeakTransaction);

  for (let i = 0; i < filesToDelete.length; i += 1) {
    if (!filesToDelete[i].id) break;
    const deleteFileTransaction = {
      queueId: `${queueId}->delete-file`,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${filesToDelete[i].id}`,
        payload: {},
        version: filesToDelete[i]?.version
      }
    } as Transaction;
    // eslint-disable-next-line no-await-in-loop
    await createTransaction(deleteFileTransaction);
  }
};

export const createAddSurveyTransaction = async ({
  token,
  body,
  files,
  inspectionId,
  flogistixId,
  uploadedFiles
}: SurveyTransaction) => {
  const transaction = {
    queueId: `${flogistixId}->survey`,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      payload: {
        body,
        pendingFiles: files ?? [],
        uploadedFiles: uploadedFiles ?? [],
        inspectionId
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addSurvey`,
      fileUpload: true
    }
  } as Transaction;

  await createTransaction(transaction);
};

export const deleteSurveyAndAssociatedFiles = async ({
  token,
  flogistixId,
  body,
  inspectionId,
  filesToDelete = []
}: SurveyTransaction) => {
  const queueId = `${flogistixId}->survey`;
  const deleteSurveyTransaction = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/removeSurvey`,
      payload: {
        ...body
      },
      fileUpload: false,
      inspectionId
    }
  } as Transaction;

  await createTransaction(deleteSurveyTransaction);

  for (let i = 0; i < filesToDelete.length; i += 1) {
    if (!filesToDelete[i].id) break;
    const deleteFileTransaction = {
      queueId: `${queueId}->delete-file`,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${filesToDelete[i].id}`,
        payload: {},
        version: filesToDelete[i]?.version
      }
    } as Transaction;
    // eslint-disable-next-line no-await-in-loop
    await createTransaction(deleteFileTransaction);
  }
};

export const createAddLeakRepairTransaction = async ({
  token,
  body,
  file,
  inspectionId,
  flogistixId,
  uploadedFiles,
  tagNumber
}: LeakRepairTransaction) => {
  const transaction = {
    queueId: `${flogistixId}->leak-repair`,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      payload: {
        body,
        pendingFiles: (file ? [file] : []),
        inspectionId,
        uploadedFiles: uploadedFiles ?? []
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${tagNumber}/addLeakRepair`,
      fileUpload: true
    }
  } as Transaction;

  await createTransaction(transaction);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const updateVerificationTransaction = async ({
  token,
  verificationToDelete,
  newVerification,
  inspectionId,
  flogistixId
}: SingleVerification) => {
  const queueId = `${flogistixId}->verification`;

  const transaction = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/removeVerification`,
      payload: verificationToDelete as JsonType,
      inspectionId
    }
  } as Transaction;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addVerification`,
      payload: newVerification as JsonType,
      inspectionId
    }
  } as Transaction;

  await createTransaction(transaction);
  await createTransaction(transactionPatch);
};

export const addVerificationTransaction = async ({
  token,
  newVerification,
  inspectionId,
  flogistixId
}: SingleVerification) => {
  const queueId = `${flogistixId}->verification`;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addVerification`,
      payload: newVerification as JsonType,
      inspectionId
    }
  } as Transaction;

  await createTransaction(transactionPatch);
};

export const addVerificationTransactionWithoutFile = async ({
  token,
  body,
  inspectionId,
  flogistixId
}: SingleVerification) => {
  const queueId = `${flogistixId}->verification`;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { file, ...rest } = body;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addVerification`,
      payload: {
        ...rest
      },
      inspectionId
    }
  } as Transaction;

  await createTransaction(transactionPatch);
};

export const addLeakRepairTransaction = async ({
  token,
  body,
  inspectionId,
  flogistixId,
  tagNumber
}: LeakRepairTransaction) => {
  const queueId = `${flogistixId}->leakRepair`;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${tagNumber}/addLeakRepair`,
      payload: body,
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
};

export const updateLeakRepairTransaction = async ({
  token,
  oldRepair,
  newRepair,
  inspectionId,
  flogistixId,
  tagNumber
}: LeakRepairTransaction) => {
  const queueId = `${flogistixId}->leakRepair`;

  const transaction = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${oldRepair?.leakId}/removeLeakRepair`,
      payload: { ...oldRepair, ...(oldRepair?.file ? { file: { id: oldRepair?.file?.id } } : {}) } as JsonType,
      inspectionId
    }
  } as Transaction;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${tagNumber}/addLeakRepair`,
      payload: newRepair as JsonType,
      inspectionId
    }
  } as Transaction;

  await createTransaction(transaction);
  await createTransaction(transactionPatch);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const deleteLeakRepairTransaction = async ({
  token,
  file,
  body,
  fileVersion,
  inspectionId,
  flogistixId,
  tagNumber
}: LeakRepairTransaction) => {
  const queueId = `${flogistixId}->leak-repair`;
  if (file) {
    const transaction = {
      queueId,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
        payload: {},
        version: `${fileVersion}`,
        fileUpload: false
      }
    } as Transaction;

    await createTransaction(transaction);
  }

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${tagNumber}/removeLeakRepair`,
      payload: {
        ...body,
        ...(file ? { file: { id: file?.id } } : {})
      },
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const deleteLeakRepairWithoutMessageTransaction = async ({
  token,
  file,
  body,
  fileVersion,
  inspectionId,
  flogistixId,
  tagNumber
}: LeakRepairTransaction) => {
  const queueId = `${flogistixId}->leak-repair`;
  if (file) {
    const transaction = {
      queueId,
      request: {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        endpoint: `https://${urlPrefix}files.api.axil.ai/files/${file?.id}`,
        payload: {},
        version: `${fileVersion}`,
        fileUpload: false
      }
    } as Transaction;

    await createTransaction(transaction);
  }

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${tagNumber}/removeLeakRepair`,
      payload: {
        ...body
      },
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
};

export const addLeakRepairTransactionWithoutFile = async ({
  token,
  body,
  inspectionId,
  flogistixId,
  tagNumber
}: SingleLeakRepairBody) => {
  const queueId = `${flogistixId}->verification`;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { file, ...rest } = body;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/leaks/${tagNumber}/addLeakRepair`,
      payload: {
        ...rest
      },
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
};

export const addMonitoredComponentTransaction = async ({
  token,
  newComp,
  inspectionId,
  flogistixId
}: MonitoredComponentTransaction) => {
  const queueId = `${flogistixId}->monitoredComponent`;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addMonitoredComponent`,
      payload: newComp as JsonType,
      inspectionId
    }
  } as Transaction;

  await createTransaction(transactionPatch);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const updateMonitoredComponentTransaction = async ({
  token,
  oldComp,
  newComp,
  inspectionId,
  flogistixId
}: MonitoredComponentTransaction) => {
  const queueId = `${flogistixId}->monitoredComponent`;

  const transaction = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/removeMonitoredComponent`,
      payload: oldComp as JsonType,
      inspectionId
    }
  } as Transaction;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/addMonitoredComponent`,
      payload: newComp as JsonType,
      inspectionId
    }
  } as Transaction;

  await createTransaction(transactionPatch);
  await createTransaction(transaction);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const deleteMonitoredComponentTransaction = async ({
  token,
  oldComp,
  inspectionId,
  flogistixId
}: MonitoredComponentTransaction) => {
  const queueId = `${flogistixId}->monitoredComponent`;

  const transaction = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/removeMonitoredComponent`,
      payload: oldComp as JsonType,
      inspectionId
    }
  } as Transaction;

  await createTransaction(transaction);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const updateDeviationTransaction = async ({
  token,
  newDev,
  inspectionId,
  flogistixId
}: DeviationTransaction) => {
  const queueId = `${flogistixId}->deviation`;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/monitoring-plan-deviations/${newDev}`,
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};

export const addSubmitTransaction = async ({
  token,
  inspectionId,
  flogistixId
}: PostTransactionCreationProps) => {
  const queueId = `${flogistixId}->submit`;

  const transactionPatch = {
    queueId,
    request: {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      endpoint: `${API_INSPECTION}/${inspectionId}/submit`,
      inspectionId
    }
  } as unknown as Transaction;

  await createTransaction(transactionPatch);
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
};
