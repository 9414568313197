import styled from 'styled-components';
import { global } from '../../../shared/colors';

const StyledDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-input-affix-wrapper:hover {
        border-color: ${global.Gray2} !important;
    }

    .ant-input-affix-wrapper .ant-input-prefix {
        margin-inline-end: 12px;
    }
`;

export default StyledDiv;
