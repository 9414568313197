const deleteInspection = async (endpoint, token) => {
  const results = await fetch(`${endpoint}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  return results.json();
};

export default deleteInspection;
