import { useContext, useState, useEffect } from 'react';

import { GlobalContext } from '../context/GlobalContext';
import { INFINITE } from '../shared/constants';
import { getFiles } from './airmethaneApi';

function useFiles(filter = null) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const { token, triggerGlobalNotification } = useContext(GlobalContext);

  useEffect(() => {
    if (trigger && token) {
      (async () => {
        try {
          setLoading(true);
          const result = await getFiles(token, filter);
          setData(result?.files);
          setLoading(false);
          setTrigger(false);
        } catch (err) {
          setError(err);
          triggerGlobalNotification(
            'error',
            // eslint-disable-next-line max-len
            'There’s a problem loading your files. Try refreshing the page. If the problem persists, please contact support for help.',
            'Failure to load files',
            INFINITE
          );
          setLoading(false);
          setTrigger(false);
        }
      })();
    }
  }, [trigger, token]);

  const lazyFunc = async () => {
    setTrigger(true);
  };

  return [loading, data, lazyFunc, error];
}

export default useFiles;
