import React, { FC } from 'react';
import './ToggleCheck.scss';

interface ToggleCheckProps {
  isOn: boolean;
  handleToggle: () => void;
}

const ToggleCheck: FC<ToggleCheckProps> = ({ isOn, handleToggle }) => (
  <label className="toggle-label">
    <input type="checkbox" className="toggle-input" checked={isOn} onChange={handleToggle} />
    <span className="slider" />
  </label>
);

export default ToggleCheck;
