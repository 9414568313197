/* eslint-disable */
import { useState, useEffect, useContext } from 'react';
import { Button } from '@flogistix/flo-ui';
import { Spin } from 'antd';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';

import { GlobalContext } from '../../context/GlobalContext';
import getSitesForOrg from '../../services/getSitesForOrg';
import uploadFileListToS3 from '../../services/uploadFileListToS3';
import { UploadTarget } from '../../components';
import UploadedFileTable from './UploadedFileTable';
import useUploadStatusMap from '../../hooks/useUploadStatusMap';
import {
  ButtonDiv,
  ButtonGroup,
  StyledUpload,
  StyledModalDiv,
  StyledHeader,
  StyledFileTable,
  StyledPlaceholderDiv
} from './styled-components';
import { createAddFacilitySignTransaction, createAddSurveyTransaction } from '../../dexie/utils';

const formFieldData = {
  key: null,
  displayName: '',
  inspectionId: null,
  sensorType: null,
  fileType: null,
  orgId: null,
  siteId: null,
  flogistixId: null,
  isRowValid: false,
  uploadStatus: null,
  sites: [],
  identifiers: []
};

const Upload = ({
  selectedInspection = null,
  selectedSite = null,
  selectedOrg = null,
  inModal
}) => {
  const {
    token,
    inspections,
    user_organizations,
    loading,
    netsuiteToAxilSiteIdMap,
    netsuiteToAxilCustomerIdMap,
    getAndSetInspections,
    inspectionsReloadIsPending
  } = useContext(GlobalContext);

  const {
    uploadStatusMap,
    uploadingFiles,
    setUploadingFiles,
    setAllFilesToPending,
    uploadingFileFailed,
    uploadingFileSuccess,
    resetUpload
  } = useUploadStatusMap();

  const statusMap = uploadStatusMap;
  const uploadedFiles = uploadingFiles;
  const setUploadedFiles = setUploadingFiles;

  const [canSubmit, setCanSubmit] = useState(false);
  const uploadQueueIsActive = Object.entries(statusMap).length > 0;
  const [dropdownInspections, setDropdownInspections] = useState(inspections);

  const getValidatedDataItem = (dataItem) => {
    const {
      displayName,
      inspectionId,
      sensorType,
      orgId,
      siteId,
      flogistixId
    } = dataItem;

    const isRowValid = displayName?.length > 0
            && ![inspectionId, sensorType, orgId, siteId, flogistixId].includes(
              null
            );

    return {
      ...dataItem,
      isRowValid
    };
  };

  const getUploadStatusByFileKey = (data) => statusMap?.[data?.key];

  const updateUploadedFile = (data, updatedPropContainer) => setUploadedFiles(
    uploadedFiles.map((file) => (file.key === data.key
      ? getValidatedDataItem({ ...file, ...updatedPropContainer })
      : file))
  );

  const isFormValid = () => uploadedFiles?.length > 0
        && uploadedFiles.every(({ isRowValid }) => isRowValid)
        && !uploadQueueIsActive
        && !inspectionsReloadIsPending;

  const removeUploadedFile = (data) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.key !== data.key));
  };

  const addFilesToQueue = (newFiles) => {
    const newDataItems = newFiles.map((file) => ({
      ...formFieldData,
      fileName: file.name,
      displayName: file.name,
      key: uuid(),
      file,
      ...(!!selectedInspection && {
        inspectionId: selectedInspection?.id,
        orgId: netsuiteToAxilCustomerIdMap?.[
          selectedInspection?.org.id
        ],
        siteId: netsuiteToAxilSiteIdMap?.[
          selectedInspection?.site?.id
        ],
        flogistixId: selectedInspection?.flogistixId
      })
    }));

    setUploadedFiles([...uploadedFiles, ...newDataItems]);
  };

  const handleFileDrop = (droppedFiles) => {
    if (uploadQueueIsActive) {
      return;
    }

    addFilesToQueue(droppedFiles);
  };

  const handleInspectionChange = (data, inspection) => {
    const {
      flogistixId,
      id: inspectionId,
      customerLocationId,
      customerId
    } = inspection;
    const siteId = netsuiteToAxilSiteIdMap?.[customerLocationId];
    const orgId = netsuiteToAxilCustomerIdMap?.[customerId];

    updateUploadedFile(data, {
      orgId,
      siteId,
      inspectionId,
      flogistixId
    });
  };

  const handleOrgChange = (data, org) => {
    const { netsuiteId } = org;

    const orgId = netsuiteToAxilCustomerIdMap?.[netsuiteId];

    getSitesForOrg(orgId, data, updateUploadedFile, token);
  };

  const handleSiteChange = (data, site) => {
    const { netsuiteId } = site;

    const siteId = netsuiteToAxilSiteIdMap?.[netsuiteId];

    const filteredInspections = inspections.filter(
      (inspection) => inspection.site.id === netsuiteId
    );
    setDropdownInspections(filteredInspections);

    const formattedIdentifierOptions = filteredInspections?.map(
      (inspection) => ({
        label: `${format(
          new Date(inspection.inspectionDate),
          'MM/dd/yy'
        )} ${inspection.flogistixId}`,
        value: inspection
      })
    );

    updateUploadedFile(data, {
      siteId,
      identifiers: formattedIdentifierOptions
    });
  };

  const handleDeleteFile = (data) => {
    removeUploadedFile(data);
  };

  const handleFileTypeChange = (data, fileTypeId) => {
    updateUploadedFile(data, { fileType: fileTypeId });
  };

  const handleSensorTypeChange = (data, sensorTypeId) => {
    updateUploadedFile(data, { sensorType: sensorTypeId });
  };

  const handleDisplayNameChange = (data, displayName) => {
    updateUploadedFile(data, { displayName });
  };

  const handleCancel = () => {
    resetUpload();
  };

  const handleSubmit = async () => {
    createAddFacilitySignTransaction({
      token,
      file: uploadedFiles[0],
      inspectionId: "1234546",
      flogistixId: "GBAN_inspection_1711965442"
    });
  };

  const handleSurveyTrial = () => {
    createAddSurveyTransaction({
      token,
      files: uploadedFiles,
      inspectionId: "1234546",
      flogistixId: "GBAN_inspection_1711965442",
      body: {
        method: 'Aerial OGI',
        gimbalAngle: 34
      }
    })
  };

  useEffect(() => {
    setCanSubmit(isFormValid());
  }, [uploadedFiles, statusMap, inspectionsReloadIsPending, canSubmit]);

  return (
    <StyledUpload height={!inModal ? '90vh' : 'auto'}>
      {!inModal && <StyledHeader>Multi-File Upload</StyledHeader>}
      {loading ? (
        <Spin />
      ) : (
        <div>
          <UploadTarget
            onFileDrop={handleFileDrop}
            disabled={uploadQueueIsActive}
          />
          {uploadedFiles?.length > 0 ? (
            <div>
              {inModal ? (
                <StyledModalDiv>
                  <UploadedFileTable
                    uploadedFiles={uploadedFiles}
                    onSensorTypeChange={
                      handleSensorTypeChange
                    }
                    onFileTypeChange={handleFileTypeChange}
                    onDeleteFile={handleDeleteFile}
                    onInspectionChange={
                      handleInspectionChange
                    }
                    onSiteChange={handleSiteChange}
                    onOrgChange={handleOrgChange}
                    onDisplayNameChange={
                      handleDisplayNameChange
                    }
                    inspections={dropdownInspections}
                    orgs={user_organizations}
                    selectedInspection={selectedInspection}
                    selectedSite={selectedSite}
                    selectedOrg={selectedOrg}
                    statusMap={statusMap}
                    getUploadStatusByFileKey={
                      getUploadStatusByFileKey
                    }
                  />
                  <ButtonGroup>
                    <ButtonDiv>
                      <Button
                        variation="gray"
                        onClick={handleCancel}
                      >
                        Clear
                      </Button>
                    </ButtonDiv>
                    <ButtonDiv>
                      <Button
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                      >
                        Send facility sign w/ first file
                      </Button>
                      <Button
                        variation='red'
                        onClick={handleSurveyTrial}
                      >
                        send survey trial w/ all files :)
                      </Button>
                    </ButtonDiv>
                  </ButtonGroup>
                </StyledModalDiv>
              ) : (
                <div>
                  <StyledFileTable>
                    <UploadedFileTable
                      statusMap={statusMap}
                      orgs={user_organizations}
                      selectedOrg={selectedOrg}
                      selectedSite={selectedSite}
                      uploadedFiles={uploadedFiles}
                      onOrgChange={handleOrgChange}
                      onDeleteFile={handleDeleteFile}
                      onSiteChange={handleSiteChange}
                      inspections={dropdownInspections}
                      selectedInspection={
                        selectedInspection
                      }
                      onFileTypeChange={
                        handleFileTypeChange
                      }
                      onInspectionChange={
                        handleInspectionChange
                      }
                      onSensorTypeChange={
                        handleSensorTypeChange
                      }
                      onDisplayNameChange={
                        handleDisplayNameChange
                      }
                      getUploadStatusByFileKey={
                        getUploadStatusByFileKey
                      }
                    />
                  </StyledFileTable>
                  <ButtonGroup>
                    <ButtonDiv>
                      <Button
                        variation="gray"
                        onClick={handleCancel}
                      >
                        Clear
                      </Button>
                    </ButtonDiv>
                    <ButtonDiv>
                      <Button
                        disabled={!canSubmit}
                        onClick={handleSubmit}
                      >
                        Send facility sign w/ first file
                      </Button>
                      <Button
                        variation='red'
                        onClick={handleSurveyTrial}
                      >
                        send survey trial w/ all files :)
                      </Button>
                    </ButtonDiv>
                  </ButtonGroup>
                </div>
              )}
            </div>
          ) : (
            <div>
              {!inModal && <StyledPlaceholderDiv />}
              <ButtonGroup>
                <ButtonDiv>
                  <Button
                    variation="gray"
                    onClick={handleCancel}
                  >
                    Clear
                  </Button>
                </ButtonDiv>
                <ButtonDiv>
                  <Button
                    disabled={!canSubmit}
                    onClick={handleSubmit}
                  >
                    Send facility sign w/ first file
                  </Button>
                </ButtonDiv>
              </ButtonGroup>
            </div>
          )}
        </div>
      )}
    </StyledUpload>
  );
};

export default Upload;
