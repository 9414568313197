import { Button } from '@flogistix/flo-ui';
import { ButtonContainer } from '../../shared/styled-components';
import {
  StyledDeleteInspModal,
  StyledDiv,
  AlertMessage,
  StyledRiAlertLine,
  AlertText,
  InspectionText,
  ButtonDiv
} from './styled-components';

const InspectionDeleteModal = ({
  isVisible, onCancel, onOk, inspection
}) => (
  <StyledDeleteInspModal
    role="dialog"
    aria-modal="true"
    open={isVisible}
    footer={null}
    closable={false}
    centered
  >
    <StyledDiv>
      <AlertMessage role="alert">
        <StyledRiAlertLine />
        <AlertText>
          Are you sure you would like to delete the following
          Inspection?
        </AlertText>
        <InspectionText>{inspection?.flogistixId}</InspectionText>
      </AlertMessage>

      <ButtonDiv role="group" aria-label="Modal actions">
        <ButtonContainer>
          <Button
            variation="gray"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCancel();
            }}
          >
            Cancel
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onOk(inspection?.id);
            }}
          >
            Delete
          </Button>
        </ButtonContainer>
      </ButtonDiv>
    </StyledDiv>
  </StyledDeleteInspModal>
);

export default InspectionDeleteModal;
