import React from 'react';
import { components } from 'react-select';
import {
  StyledIoChevronUpOutline,
  StyledIoChevronDownOutline
} from './styled-components';

const DropdownIndicator = (props) => {
  const { selectProps } = props;
  let arrow;
  if (selectProps.menuIsOpen) {
    arrow = <StyledIoChevronUpOutline size="1em" aria-hidden="true" />;
  } else {
    arrow = <StyledIoChevronDownOutline size="1em" aria-hidden="true" />;
  }

  return (
    <div data-cy="indicator">
      <components.DropdownIndicator {...props}>
        {arrow}
      </components.DropdownIndicator>
    </div>
  );
};

export default DropdownIndicator;
