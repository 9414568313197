import React, { useContext, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContext';

import TableDiv from './styled-components/TableDiv';
import FilesSkeleton from './FilesSkeleton/FilesSkeleton';
import FilteredFilesTable from './FilteredFilesTable/FilteredFilesTable';

const SkeletonDiv = styled.div`
    width: 100%;
    margin: 60px;

    @media only screen and (max-width: 922px) {
        overflow-x: scroll;
    }
`;

const FilteredFiles = () => {
  const params = useParams();
  const history = useNavigate();
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    inspections, loading, user_sites, user_organizations
  } = useContext(GlobalContext);
  const [netsuiteSite, setNetsuiteSite] = useState(null);
  const [netsuiteOrganization, setNetsuiteOrganization] = useState(null);
  const shouldRender = !loading && !!netsuiteSite && !!netsuiteOrganization;

  const goToOrgList = () => {
    setNetsuiteSite(null);
    history(`/orgs/${netsuiteOrganization.netsuiteId}`);
  };

  const goToOrgLocation = () => {
    history(
      `/orgs/${netsuiteOrganization.netsuiteId}/inspections/${netsuiteSite.netsuiteId}`
    );
  };

  const goToStart = () => {
    setNetsuiteSite(null);
    setNetsuiteOrganization(null);

    history('/');
  };

  useEffect(() => {
    if (!loading) {
      const customerId = parseInt(params.customerId, 10);
      const customerLocationId = parseInt(params.id, 10);

      setNetsuiteOrganization(
        user_organizations.find((org) => org.netsuiteId === customerId)
      );
      setNetsuiteSite(
        user_sites.find(
          (site) => site.netsuiteId === customerLocationId
        )
      );
    }
  }, [params, inspections, loading, user_organizations, user_sites]);

  return shouldRender ? (
    <TableDiv>
      <FilteredFilesTable
        inspections={inspections}
        user_organization={netsuiteOrganization}
        user_site={netsuiteSite}
        onOrgBreadcrumbClick={goToOrgList}
        onInspectionBreadcrumbClick={goToStart}
        OnOrgLocationBreadcrumbClick={goToOrgLocation}
        flogistix_id={params.flogistixId}
        inspId={params.insp}
      />
    </TableDiv>
  ) : (
    <SkeletonDiv>
      <FilesSkeleton />
    </SkeletonDiv>
  );
};

export default FilteredFiles;
