import React from 'react';
import styled from 'styled-components';
import { IoLocateOutline } from 'react-icons/io5';

const StyledIoLocateOutline = styled(({ className }) => (
  <IoLocateOutline className={className} />
))`
    font-size: 16px;
    margin: auto 8px auto auto;
`;

export default StyledIoLocateOutline;
