import React, { useContext } from 'react';
import { Avatar, Modal } from 'antd';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@flogistix/flo-ui';
import styled from 'styled-components';

import { GlobalContext } from '../../context/GlobalContext';

import { global } from '../../shared/colors';

const StyledProfileModal = styled(Modal)`
    float: right;
    margin-right: 40px;
    top: 72px;

    .ant-modal-body {
        display: flex;
        align-items: center;
        flex-direction: column;

        h1 {
            font-size: 20px;
            font-weight: 600;
            color: ${global.Gray1};
            margin: 0;
        }

        p {
            font-size: 16px;
            margin: 0 0 20px 0;
            color: ${global.Gray3};
        }
    }

    .ant-modal-close-x {
        color: ${global.Black};
    }
`;

const ProfileModal = () => {
  const { logout, user } = useAuth0();

  const { profileModalOpen, setProfileModalOpen } = useContext(GlobalContext);

  return (
    <StyledProfileModal
      footer={[]}
      open={profileModalOpen}
      wrapClassName="profile-modal-dialog"
      onCancel={() => setProfileModalOpen(false)}
      mask={{ backgroundColor: 'transparent' }}
      role="dialog"
      aria-labelledby="profile-modal-title"
    >
      <Avatar
        size={88}
        src={user?.picture}
        alt={user?.name || 'User avatar'}
      />
      <h1 id="profile-modal-title">{user?.name || 'No Name'}</h1>
      <p>{user?.email || 'No Email'}</p>
      <Button
        onClick={() => logout({ returnTo: window.location.origin })}
        variation="red-outline"
      >
        Logout
      </Button>
    </StyledProfileModal>
  );
};

export default ProfileModal;
