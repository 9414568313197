import React from 'react';
import styled from 'styled-components';
import { HiOutlineTrash } from 'react-icons/hi';

const StyledIoTrashOutline = styled(
  React.forwardRef((props, ref) => <HiOutlineTrash ref={ref} {...props} />)
)`
    font-size: 16px;
`;

export default StyledIoTrashOutline;
