import { useState, useEffect, useRef } from 'react';

import {
  PENDING_FILE_UPLOAD_STATUS,
  SUCCESS_FILE_UPLOAD_STATUS,
  FAILED_FILE_UPLOAD_STATUS
} from '../shared/constants';

const useUploadStatusMap = () => {
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [uploadStatusMap, setUploadStatusMap] = useState({});
  const uploadStatusMapRef = useRef();

  useEffect(() => {
    uploadStatusMapRef.current = uploadStatusMap;
  }, [uploadStatusMap]);

  const isUploadStillInFocus = (pendingFile) => `${uploadStatusMapRef.current[pendingFile.key]?.status}`
        === PENDING_FILE_UPLOAD_STATUS;

  const setAllFilesToPending = () => {
    setUploadStatusMap(
      uploadingFiles.reduce(
        (newStatusMap, file) => ({
          ...newStatusMap,
          [file.key]: { status: PENDING_FILE_UPLOAD_STATUS }
        }),
        uploadStatusMapRef.current
      )
    );
  };

  const uploadingFileFailed = ({ pendingFile, failedUploadResult }) => {
    if (isUploadStillInFocus(pendingFile)) {
      setUploadStatusMap({
        ...uploadStatusMapRef.current,
        [pendingFile.key]: {
          status: FAILED_FILE_UPLOAD_STATUS,
          failedUploadResult
        }
      });
    }
  };

  const uploadingFileSuccess = ({ pendingFile }) => {
    if (isUploadStillInFocus(pendingFile)) {
      setUploadStatusMap({
        ...uploadStatusMapRef.current,
        [pendingFile.key]: { status: SUCCESS_FILE_UPLOAD_STATUS }
      });
    }
  };

  const resetUploadStatusMap = () => {
    setUploadStatusMap({});
  };

  const resetUpload = () => {
    resetUploadStatusMap();
    setUploadingFiles([]);
  };

  return {
    uploadStatusMap,
    setUploadStatusMap,
    uploadingFiles,
    setUploadingFiles,
    setAllFilesToPending,
    uploadingFileFailed,
    uploadingFileSuccess,
    resetUploadStatusMap,
    resetUpload
  };
};

export default useUploadStatusMap;
