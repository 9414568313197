import styled from 'styled-components';
import { RiAlertLine } from 'react-icons/ri';

import { global } from '../../../shared/colors';

const StyledRiAlertLine = styled(RiAlertLine)`
    color: ${global.White};
    font-size: 24px;
`;

export default StyledRiAlertLine;
