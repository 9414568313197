import React from 'react';
import styled from 'styled-components';
import { Input } from 'antd';
import { IoFilterOutline } from 'react-icons/io5';
import PropTypes from 'prop-types';

import { global } from '../../shared/colors';

const StyledInput = styled(Input)`
    height: 43px;
    width: 60%;
    min-width: 200px;
    max-width: 560px;
    margin-bottom: 32px;
    font-size: 16px;
    border: 1px solid ${global.Gray6};
    border-radius: 60px;
    padding-left: 20px;
    cursor: pointer;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));

    ${(props) => props.fileSearchMode
    && `
        position: absolute;
        top: -122px;
    `}
`;

const StyledDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .ant-input-affix-wrapper:hover {
        border-color: ${global.Gray2} !important;
    }

    .ant-input-affix-wrapper .ant-input-prefix {
        margin-inline-end: 12px;
    }
`;

const StyledIoFilterOutline = styled(({ className }) => (
  <IoFilterOutline className={className} />
))`
    font-size: 20px;
    color: ${global.Gray4};
`;

const Search = ({
  filter, setFilter, placeholderText, fileSearch
}) => (
  <StyledDiv role="search">
    <StyledInput
      aria-label="Search"
      prefix={<StyledIoFilterOutline size="1em" aria-hidden="true" />}
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      placeholder={placeholderText}
      data-cy="type"
      fileSearchMode={fileSearch}
      id="DashboardFilter"
    />
  </StyledDiv>
);

Search.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  fileSearch: PropTypes.bool
};

Search.defaultProps = {
  placeholderText: 'Filter by column title', // Default value for placeholderText
  fileSearch: false // Default value for fileSearch
};

export default Search;
