import { useContext, useEffect, useState } from 'react';
import { IoAddOutline } from 'react-icons/io5';
import { Toggle } from '@flogistix/flo-ui';
import { v4 as uuidv4 } from 'uuid';
import { InspectionContext } from '../../../../../context/InspectionContext';
import { Leak } from '../../../../../classes/leak';
import LeakCard from './LeakCard';

import './Leaks.scss';

const Leaks = () => {
  const { inspection, token, setCompletedSectionsOnChange } = useContext(InspectionContext);
  const [leaks, setLeaks] = useState(inspection?.leaks || []);
  const [hasLeaks, setHasLeaks] = useState((inspection?.leaks?.length ?? 0) > 0);

  const createLeak = () => {
    const newLeak = new Leak();
    inspection!.addLeak(token, newLeak).then(() => {
      setLeaks([...leaks, newLeak]);
      setHasLeaks(true);
      setCompletedSectionsOnChange();
    });
  };

  const deleteLeak = (leak: Leak) => {
    inspection!.removeLeak(token, leak).then(() => {
      const newLeaks = leaks.filter((l) => l.tagNumber !== leak.tagNumber);
      if (newLeaks.length === 0) setHasLeaks(false);
      setLeaks(newLeaks);
      setCompletedSectionsOnChange();
    });
  };

  const handleHasLeaksToggle = () => {
    if (hasLeaks) {
      setHasLeaks(false);
    } else {
      setHasLeaks(true);
    }
  };

  useEffect(() => {
    if (hasLeaks && !inspection!.leaks?.length) {
      createLeak();
    }
  }, [hasLeaks]);

  useEffect(() => {
    if (inspection?.leaks === undefined) {
      inspection!.initializeLeaks();
    }
  }, []);
  return (
    <div className="leaks-container">
      <div className="section-heading">
        <h1 className="page-details__header">Leak details</h1>
        <h4 className="part">Part 1 of 2</h4>
        <button
          className="add-new-item-button"
          type="button"
          onClick={createLeak}
        >
          <IoAddOutline />
          Add new leak
        </button>
      </div>
      <label className="label">Were there any leaks found on site?</label>
      <Toggle
        firstOption="Yes"
        secondOption="No"
        activeOption={hasLeaks ? 'Yes' : 'No'}
        onToggle={handleHasLeaksToggle}
        disabled={(leaks?.length ?? 0) > 0}
      />
      {
        leaks?.map((leak: Leak, i: number) => (
          <LeakCard
            key={uuidv4()}
            leakIndex={i}
            leak={leak}
            deleteLeak={() => deleteLeak(leak)}
          />
        ))
      }
      <button
        className="add-new-item-button-bottom"
        type="button"
        onClick={createLeak}
      >
        <IoAddOutline />
        Add new leak
      </button>
    </div>
  );
};

export default Leaks;
