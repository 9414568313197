import styled from 'styled-components';
import { global } from '../../../shared/colors';

const IconContainer = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    button {
        width: 32px;
        height: 32px;
        color: ${global.Gray3};
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
            color: ${global.Black};
            font-weight: 800;
        }
    }

    &:focus {
        background: ${(props) => (props.noHover || props.disabled ? 'none' : global.Gray4)};
        color: ${global.Gray3};
    }

    & button:disabled {
        cursor: not-allowed;
    }

    & button:disabled svg {
        opacity: 0.2;
    }
`;

export default IconContainer;
