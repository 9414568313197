/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import { Layout } from 'antd';

import AppRoutes from './AppRoutes';

import ProfileModal from './components/Modals/ProfileModal';
import { GlobalContext } from './context/GlobalContext';
import Notification from './components/Notifications/Notification';
import { updateAuthTokenForPendingTransactions } from './dexie/operations';

const Container = styled.div`
    margin: 0;
    overflow: hidden;
    height: 100%;
    * {
        -ms-overflow-style: none;
    }
    ::-webkit-scrollbar {
        display: none;
    }
    overflow: auto;
`;

const useHandleTokenRefresh = () => {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const authChannel = new BroadcastChannel('auth-channel');

    const handleTokenRefresh = async () => {
      try {
        const refreshedToken = await getAccessTokenSilently();
        await updateAuthTokenForPendingTransactions(refreshedToken);
      } catch (error) {
        console.error('Error refreshing access token:', error);
      }
    };

    authChannel.onmessage = (message) => {
      if (message.data && message.data.type === 'REFRESH_TOKEN') {
        handleTokenRefresh();
      }
    };

    return () => {
      authChannel.close();
    };
  }, [getAccessTokenSilently]);
};

const App = () => {
  const { user_organizations, is_admin } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const {
    user, isLoading, isAuthenticated, loginWithRedirect
  } = useAuth0();
  const [isPendoInitialized, setIsPendoInitialized] = useState(false);

  const navLinks = [
    {
      key: 'Inspections',
      icon: 'clipboard',
      label: 'Inspections',
      path: '/'
    },
    ...(is_admin
      ? [
        {
          key: 'Reports',
          id: 'NavReports',
          icon: 'barChart',
          label: 'Reports',
          path: '/reports'
        }
      ]
      : []),
    {
      key: 'MultiUpload',
      id: 'NavMultiUpload',
      icon: 'upload',
      label: 'Transactions Table',
      path: '/upload-management'
    }
  ];

  useHandleTokenRefresh();

  const [activeLink, setActiveLink] = useState({
    path: pathname,
    key: navLinks.find((link) => pathname === link.path)?.key
  });

  const isUserExternal = (email) => email.split('@')[1] !== 'flogistix.com';

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading && isAuthenticated) {
      const external_user = isUserExternal(user.email);
      window.gtag('set', 'user_properties', {
        external_user
      });
      window.gtag('event', 'login', {
        external_user
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  useEffect(() => {
    if (!isPendoInitialized && user_organizations?.length) {
      const external_user = isUserExternal(user.email);
      // eslint-disable-next-line no-nested-ternary
      const orgName = external_user
        ? user_organizations.length > 1
          ? 'Multiple Organizations'
          : user_organizations.at(0).orgName
        : 'Flogistix';
      window.pendo.initialize({
        visitor: {
          id: user.email,
          full_name: user.name
        },
        account: {
          id: orgName
        }
      });
      setIsPendoInitialized(true);
    }
  }, [user_organizations]);

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_partial_path: window.location.pathname,
      page_path:
        window.location.pathname
        + window.location.search
        + window.location.hash,
      page_search: window.location.search,
      page_hash: window.location.hash
    });
  }, []);

  useEffect(() => {
    const activeKey = navLinks.find((link) => pathname === link.path)?.key;

    setActiveLink({
      path: pathname,
      key: activeKey
    });
  }, [pathname]);

  return (
    <Container>
      <Layout>
        <AppRoutes navLinks={navLinks} activeLink={activeLink} />
        <ProfileModal />
        <Notification />
      </Layout>
    </Container>
  );
};

export default App;
