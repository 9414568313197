export const getAllKeys = (object, parentPath = '') => {
  let collectedPaths = [];

  Object.keys(object).forEach((property) => {
    const currentPath = parentPath ? `${parentPath}.${property}` : property;
    const isObject = typeof object[property] === 'object';
    const isNotNull = object[property] !== null;
    const isNotArray = !Array.isArray(object[property]);

    if (isObject && isNotNull && isNotArray) {
      const nestedPaths = getAllKeys(object[property], currentPath);
      collectedPaths = [...collectedPaths, ...nestedPaths];
    } else {
      collectedPaths.push(currentPath);
    }
  });

  return collectedPaths;
};

export const getValueByPath = (object, pathString) => {
  const pathSegments = pathString.split('.');

  return pathSegments.reduce((currentValue, segment) => {
    const hasKey = currentValue && Object.hasOwnProperty.call(currentValue, segment);

    return hasKey ? currentValue[segment] : '';
  }, object);
};
