import { useContext, useEffect, useState } from 'react';
import { Header, Icon } from '@flogistix/flo-ui';
import { getOrgNameById, convertTimestampToYMD } from '../../InspectionLayout/shared/utils';
import { getScheduledReports, cronToInterval } from '../services/AirMethaneReportingAPI';
import { GlobalContext } from '../../../context/GlobalContext';

import './ScheduledReports.scss';

const reportEnums = Object.freeze({
  'airmethane-leak-report': 'Leak Report'
});

export interface Report {
  application: string
  cronUtc: string
  email: string
  lastRunUtc: number
  options: {
    inspectorId: string
    orgId: number
    reportName: string
    siteId: string
  }
  reportName: string
  type: string
  userReportName: string
}

const ScheduledReports = () => {
  const { token, orgs } = useContext(GlobalContext);

  const [scheduledReports, setScheduledReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScheduledReports = async () => {
      try {
        const response = await getScheduledReports(token);
        setScheduledReports(response.reports);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchScheduledReports();
  }, [token]);

  const generateTableData = (reports: Report[]) => reports.map((report) => [
    report.userReportName,
    getOrgNameById(report.options.orgId, orgs),
    report.lastRunUtc ? convertTimestampToYMD(report.lastRunUtc) : 'Not Run Yet',
    cronToInterval(report.cronUtc),
    reportEnums[report.reportName as keyof typeof reportEnums]]);

  return (
    <div className="reporting-wrapper">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table className="scheduled-reports-table">
          <Header headerSize="h3" className="scheduled-reports-table--title">All Scheduled Reports</Header>
          <thead className="scheduled-reports-table--header">
            <tr>
              <th className="col-1">Report Name</th>
              <th className="col-2">Customer</th>
              <th className="col-3">Last Report Date</th>
              <th className="col-4">Report Cycle</th>
              <th className="col-5">Report Type</th>
              <th className="col-6"> ... </th>
            </tr>
          </thead>
          <tbody className="scheduled-reports-table--body">
            {generateTableData(scheduledReports).map((report, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <tr key={index} className={`${index % 2 === 0 ? 'even' : 'odd'}`}>
                <td className="col-1">{report[0]}</td>
                <td className="col-2">{report[1]}</td>
                <td className="col-3">{report[2]}</td>
                <td className="col-4">{report[3]}</td>
                <td className="col-5">{report[4]}</td>
                <td className="col-6"><Icon iconName="DotsVertical" color="black" height={20} width={20} /></td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ScheduledReports;
