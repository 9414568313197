import React, { useState, useRef } from 'react';
import { IoCopyOutline, IoCheckmark } from 'react-icons/io5';

import { global } from '../../shared/colors';
import './UploadManagement.scss';

interface RequestCellProps {
  formattedRequest: string;
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void;
  hideTooltip?: () => void;
}

interface RequestObject {
  method: string;
  headers: Record<string, string>;
  payload: Record<string, string>;
  endpoint: string;
}

const RequestCell: React.FC<RequestCellProps> = ({ formattedRequest, showTooltip, hideTooltip = () => {} }) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const defaultStyle = global.Gray4;
  const hoverStyle = global.Gray3;

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(formattedRequest)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  let requestObject: RequestObject | null = null;
  try {
    requestObject = JSON.parse(formattedRequest);
  } catch (error) {
    console.error('Failed to parse formattedRequest:', error);
  }

  const formattedDisplay = requestObject ? `
    request: {
      method: ${requestObject.method}
      headers: ${requestObject.headers ? Object.keys(requestObject.headers)
    .map((key) => `${key}: ${requestObject?.headers[key]}`)
    .join(', ')
    .slice(0, 30) : 'N/A'}...
      payload: ${requestObject.payload ? Object.keys(requestObject.payload)
    .slice(0, 2)
    .map((key) => `${key}: ...`)
    .join(', ')
    .slice(0, 30) : 'N/A'}...
      endpoint: ${requestObject.endpoint ? requestObject.endpoint.slice(0, 30) : 'N/A'}...
    }` : 'Invalid JSON Data';

  const toggleTooltip = () => {
    if (isTooltipVisible) {
      hideTooltip();
    } else if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const tooltipHeight = 200;
      const offset = 35;
      const position = {
        top: buttonRect.top - tooltipHeight - offset,
        left: buttonRect.left
      };
      showTooltip(
        <div className="requests">
          <h2 className="requests-header">Request</h2>
          <p className="requests-text">{formattedDisplay}</p>
          <button
            type="button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleCopyToClipboard}
            className="tooltip-button copy-button"
          >
            <IoCopyOutline
              size="24"
              color={isHovered ? hoverStyle : defaultStyle}
            />
            {isCopied && (
              <span className="copied-notification">
                <IoCheckmark size="20" color={global.PrimaryBlue} />
                Copied to clipboard
              </span>
            )}
          </button>
        </div>,
        position
      );
    }
    setIsTooltipVisible(!isTooltipVisible);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      toggleTooltip();
    }
  };

  return (
    <div className="request-cell-container">
      <div
        ref={buttonRef}
        tabIndex={0}
        onClick={toggleTooltip}
        onKeyDown={handleKeyDown}
        className="request-button"
        role="button"
        aria-pressed={false}
      >
        View Request
      </div>
    </div>
  );
};

RequestCell.defaultProps = {
  hideTooltip: () => {}
};

export default RequestCell;
