import { useState, useEffect } from 'react';

const useLocalStorage = (key, defaultValue) => {
  const get = () => {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) return defaultValue;

    try {
      return JSON.parse(storedValue);
    } catch (error) {
      return storedValue;
    }
  };

  const [state, setState] = useState(get());

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

export default useLocalStorage;
