import React from 'react';
import { Skeleton } from 'antd';
import { IoChevronForward } from 'react-icons/io5';
import styled from 'styled-components';

import { tableRowFixture } from '../../shared/utils';
import {
  StyledBreadcrumbs,
  Breadcrumb,
  StyledSpan
} from '../../shared/styled-components';
import {
  HeaderWrapper,
  StyledHeader,
  SkeletonBreadcrumb,
  SkeletonHeader
} from './styled-components';
import Table from './Table';

const InspectionBreadcrumb = styled.button`
    background: none;
    border: none;
    color: ${global.Gray4};
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    font-size: 13px;
    width: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;
    margin-bottom: 20px;
    margin-top: 32px;

    &:hover {
        text-decoration: underline;
        color: ${global.Black};
    }
`;

const createColumn = (title, width) => ({
  title,
  width,
  ellipsis: title !== '',
  render:
        title === ''
          ? undefined
          : () => (
            <Skeleton.Input size="small" active paragraph={{ rows: 1 }} />
          )
});

const customerColumns = [
  createColumn('Site', '30%'),
  createColumn('Total Inspections', '20%'),
  createColumn('Recent Inspection', '25%')
];

const homeColumns = [
  createColumn('Customer Name', '25%'),
  createColumn('Site', '30%'),
  createColumn('Total Inspections', '20%'),
  createColumn('Recent Inspection', '25%')
];

const inspectionColumns = [
  createColumn('Identifier', '40%'),
  createColumn('Created By', '20%'),
  createColumn('File Count', '20%'),
  createColumn('Inspection Date', '20%'),
  createColumn('', 150)
];

const SkeletonTable = ({ isInspections, enableTabs = false, isCustomer }) => {
  const getHomeColumns = () => (isCustomer ? customerColumns : homeColumns);

  return (
    <>
      {isInspections && (
        <HeaderWrapper>
          <StyledHeader>
            <SkeletonHeader
              size="small"
              active
              paragraph={{ rows: 1 }}
            />
          </StyledHeader>
          <StyledBreadcrumbs>
            <InspectionBreadcrumb>Inspections</InspectionBreadcrumb>
            <IoChevronForward />
            <Breadcrumb>
              <SkeletonBreadcrumb
                size="small"
                active
                paragraph={{ rows: 1 }}
              />
            </Breadcrumb>
            <>
              <IoChevronForward />
              <StyledSpan>
                <SkeletonBreadcrumb
                  size="small"
                  active
                  paragraph={{ rows: 1 }}
                />
              </StyledSpan>
            </>
          </StyledBreadcrumbs>
        </HeaderWrapper>
      )}

      <Table
        data={tableRowFixture}
        columns={isInspections ? inspectionColumns : getHomeColumns()}
        enableTabs={enableTabs}
      />
    </>
  );
};

export default SkeletonTable;
