/* eslint no-shadow: "off" */

export enum ComplianceType {
  OOOO = 'OOOO',
  VOLUNTARY = 'VOLUNTARY'
}

export enum InspectionType {
  PIPELINE = 'PIPELINE',
  FACILITY = 'FACILITY'
}
export enum OperationalStatus {
  RUNNING = 'Running',
  DISABLED = 'Disabled',
  OTHER = 'Other'
}

export enum InspectionFrequency {
  SINGLE = 'Single',
  MONTHLY = 'Monthly',
  QUARTERLY = 'Quarterly',
  BI_ANNUAL = 'Bi-annual',
  ANNUAL = 'Annual'
}

export enum ComponentType {
  CLOSED_VENT_SYSTEM = 'closed vent system',
  COMPRESSOR = 'compressor',
  CONNECTOR = 'connector',
  COVER = 'cover',
  FLANGE = 'flange',
  INSTRUMENT = 'instrument',
  METER = 'meter',
  OPEN_ENDED_LINE = 'open ended line',
  PRESSURE_RELIEF_DEVICE = 'pressure relief device',
  THIEF_HATCH = 'thief hatch',
  VALVE = 'valve',
  OTHER = 'other'
}

export enum LeakPriority {
  EMERGENCY_WORK = '1 - Emergency Work',
  URGENT_WORK = '2 - Urgent Work',
  TWO_WEEKS = '3 - Two Weeks',
  TWO_MONTHS = '4 - Two Months',
  LOW_PRIORITY = '5 - Low Priority'
}

export enum SkyCondition {
  CLEAR = 'Clear',
  MOSTLY_CLEAR = 'Mostly Clear',
  PARTLY_CLOUDY = 'Partly Cloudy',
  MOSTLY_CLOUDY = 'Mostly Cloudy',
  CLOUDY_OVERCAST = 'Cloudy (Overcast)'
}

export enum WindDirection {
  NORTH = 'North',
  NORTH_EAST = 'North East',
  EAST = 'East',
  SOUTH_EAST = 'South East',
  SOUTH = 'South',
  SOUTH_WEST = 'South West',
  WEST = 'West',
  NORTH_WEST = 'North West'
}

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document'
}

export enum SensorType {
  FLIR_OGI = 'FLIR OGI',
  LASER = 'Laser',
  SNIFFER = 'Sniffer',
  INSPECTION_REPORT = 'Inspection Report',
  STANDARD_PIC = 'Standard Pic',
  MISC = 'Misc.',
  OGI = 'OGI',
  LIDAR = 'LIDAR',
  PHOTOGRAMMETRY = 'Photogrammetry',
  LEAK = 'Leak',
  PATCH_CHANGE = 'Patch Change',
  HYBRID_SENSOR = 'Hybrid Sensor'
}

export enum SurveyMethod {
  AERIAL_OGI = 'Aerial OGI',
  HANDHELD_OGI = 'Handheld OGI',
  LASER = 'Laser',
  PATCH = 'Patch'
}

export enum InspectionStatus {
  DRAFT = 'DRAFT',
  LEAK_PROCESSING = 'LEAK_PROCESSING',
  MEDIA_PROCESSING = 'MEDIA_PROCESSING',
  READY_TO_SUBMIT = 'READY_TO_SUBMIT',
  COMPLETE = 'COMPLETE',
  REOPENED = 'REOPENED',
  DELETED = 'DELETED'
}
