import React, { useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import './index.css';
import AppProviders from './AppProviders';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { sendMessageToSW } from './shared/utils';

const container = document.getElementById('root');
const root = createRoot(container);
const history = createBrowserHistory();

history.listen((location) => {
  window.gtag('event', 'page_view', {
    page_path: location.pathname + location.search + location.hash,
    page_search: location.search,
    page_hash: location.hash
  });
});

serviceWorkerRegistration.register();

const AppContainer = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Auth0Provider
      useRefreshTokens
      domain="axil.auth0.com"
      scope="openid profile email"
      cacheLocation="localstorage"
      audience="https://api.axil.ai"
      redirectUri={window.location.origin}
      clientId="99kaMRFkSRB2QvTGfwghhx6AGRoOyN4Q"
    >
      <BrowserRouter>
        <AppProviders>
          <App />
        </AppProviders>
      </BrowserRouter>
    </Auth0Provider>
  );
};

root.render(<AppContainer />);
