export const sensorTypes = {
  FLIR: { id: 1, label: 'FLIR OGI' },
  LASER: { id: 2, label: 'Laser' },
  SNIFFER: { id: 3, label: 'Sniffer' },
  INSPECTION_REPORT: { id: 4, label: 'Inspection Report' },
  STANDARD_PIC: { id: 5, label: 'Standard Pic' },
  MISC: { id: 6, label: 'Misc.' },
  VENTUS_OGI: { id: 7, label: 'Ventus OGI' },
  LIDAR: { id: 8, label: 'LIDAR' },
  PHTOGRAMMETRY: { id: 9, label: 'Photogrammetry' }
};

export const VIDEO_FILE_TYPE = 'video';
export const DOCUMENT_FILE_TYPE = 'document';
export const IMAGE_FILE_TYPE = 'image';
export const COMPLETE_STATUS_ID = 5;

export const AIR_METHANE_ADMIN_GROUP_NAME = 'Air Methane Admin';

export const AIR_METHANE_ACCESS_GROUPS = [
  AIR_METHANE_ADMIN_GROUP_NAME,
  'Air Methane User',
  'Drone Pilot'
];

export const PENDING_FILE_UPLOAD_STATUS = 'PENDING_FILE_UPLOAD_STATUS';
export const FAILED_FILE_UPLOAD_STATUS = 'FAILED_FILE_UPLOAD_STATUS';
export const SUCCESS_FILE_UPLOAD_STATUS = 'SUCCESS_FILE_UPLOAD_STATUS';

export const inspectionStatusMap = {
  draft: 'Draft',
  reopened: 'Reopened',
  ready_to_submit: 'Ready for submission'
};

export const emptyBaseMonitoredComponent = {
  componentType: null,
  difficultToMonitor: false,
  difficultToMonitorDescription: '',
  unsafeToMonitor: false,
  unsafeToMonitorDescription: ''
};

export const inspectionSections = {
  SITE_DETAILS: 'site-details',
  INSPECTION_DETAILS: 'inspection-details',
  INFO: 'info',
  VERIFICATION: 'verification',
  WEATHER_CONDITIONS: 'weather-conditions',
  SURVEY_DETAILS: 'survey-details',
  LEAK_DETAILS: 'leak-details',
  LEAK_REPAIRS: 'leak-repairs',
  INSPECTION_SUMMARY: 'inspection-summary',
  FINAL_SUBMISSION: 'final-submission'
};

export const requiredFieldsBySection = {
  [inspectionSections.SITE_DETAILS]: [
    'inspectionDate',
    'customer',
    'customerLocation',
    'state',
    'county',
    'contactPhone',
    'contactName',
    'latitude',
    'longitude'
  ],
  [inspectionSections.INFO]: [
    'complianceType',
    'operationalStatus',
    'inspectionDate',
    'startTime',
    'endTime'
  ],
  [inspectionSections.VERIFICATION]: [
    'file',
    'surveyInstrument',
    'altitude',
    'flowRate'
  ],
  [inspectionSections.WEATHER_CONDITIONS]: [
    'maxWindSpeed',
    'ambientTemperature',
    'windDirection',
    'skyConditions',
    'barometricPressure',
    'humidityPercent'
  ],
  [inspectionSections.SURVEY_DETAILS]: [
    'inspectors',
    'method',
    'surveyInstrument',
    'inspectionDurationHours',
    'inspectionDurationMinutes',
    'files'
  ],
  [inspectionSections.LEAK_DETAILS]: [
    'componentType',
    'componentSubtype',
    'location',
    'rateUom',
    'tagNumber',
    'files'
  ],
  [inspectionSections.LEAK_REPAIRS]: ['date', 'file', 'leakId']
};

export const homeSections = {
  IN_PROGRESS: 'in-progress',
  COMPLETE: 'complete'
};

export const TAB_NAMES = {
  QUEUED: 'queued',
  PROCESSING: 'processing',
  RETRYING: 'retrying',
  FAILED: 'failed',
  COMPLETED: 'completed'
};

export const INFINITE = 'infinite';

const InspectionOptions = {
  frequency: [
    { value: 1, label: 'Single' },
    { value: 2, label: 'Monthly' },
    { value: 3, label: 'Quarterly' },
    { value: 4, label: 'Bi-annual' },
    { value: 5, label: 'Annual' }
  ],

  skyCondition: [
    { value: 1, label: 'Clear' },
    { value: 2, label: 'Mostly Clear' },
    { value: 3, label: 'Partly Cloudy' },
    { value: 4, label: 'Mostly Cloudy' },
    { value: 5, label: 'Cloudy (Overcast)' }
  ],

  windDirection: [
    { value: 1, label: 'North' },
    { value: 2, label: 'North East' },
    { value: 3, label: 'East' },
    { value: 4, label: 'South East' },
    { value: 5, label: 'South' },
    { value: 6, label: 'South West' },
    { value: 7, label: 'West' },
    { value: 8, label: 'North West' }
  ],

  method: [
    { value: 1, label: 'Aerial OGI' },
    { value: 2, label: 'Handheld OGI' },
    { value: 3, label: 'Laser' },
    { value: 100, label: 'Patch' }
  ],

  componentType: [
    { value: 1, label: 'closed vent system' },
    { value: 2, label: 'compressor' },
    { value: 3, label: 'connector' },
    { value: 4, label: 'cover' },
    { value: 5, label: 'flange' },
    { value: 6, label: 'instrument' },
    { value: 7, label: 'meter' },
    { value: 8, label: 'open ended line' },
    { value: 9, label: 'pressure relief device' },
    { value: 10, label: 'thief hatch' },
    { value: 11, label: 'valve' },
    { value: 12, label: 'other' }
  ],

  priorityOptions: [
    { value: 'None', label: 'None' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' }
  ]
};

export const FREQUENCY_OPTIONS = InspectionOptions.frequency;
export const SKY_CONDITION_OPTIONS = InspectionOptions.skyCondition;
export const WIND_DIRECTION_OPTIONS = InspectionOptions.windDirection;
export const METHOD_OPTIONS = InspectionOptions.method;
export const COMP_TYPE_OPTIONS = InspectionOptions.componentType;
export const PRIORITY_OPTIONS = InspectionOptions.priorityOptions;

export const INSPECTION_TYPE_OPTIONS = [
  { value: 'FACILITY', label: 'Facility' },
  { value: 'PIPELINE', label: 'Pipeline' }
];
