/* eslint-disable no-nested-ternary */
import React, {
  useRef, useState, useEffect, useContext
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InspectionContext } from '../../../context/InspectionContext';
import { fetchFileUrl } from '../../../services/airmethaneApi';
import imageOutline from '../../../assets/image-outline.svg';
import MultiDropdown from '../../MultiDropdown/MultiDropdown';
import DeleteConfirmationModal from '../../Modals/DeleteConfirmationModal';
import Survey from '../../../classes/survey';
import './MultiDropzone.scss';
import AirMethaneFile from '../../../classes/airmethane-file';

interface MultiDropzoneProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => void;
  initialSelectedOptions?: string[];
  acceptedTypes: string;
  removeHandler?: (fileId?: string) => void;
  showRemoveButton?: boolean;
  fileIds: string[];
  surveyId?: string;
  selectOptions?: {
    value: string | number;
    label: string;
    fileUrl?: string;
    content?: React.ReactNode;
    fileType?: string;
  }[];
  onSelectChange?: (value: string | number) => void;
  setSurveyFiles: React.Dispatch<React.SetStateAction<AirMethaneFile[]>>;
  onConfirm?: (fileIds: string[]) => void;
}

interface FileData {
  id: string;
  parentId: string;
  version: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deleted: boolean;
  lastAct: string;
  fileType: string;
  s3Key: string;
  fileUrl: string;
  name: string;
  displayName: string;
  extension: string;
  url: string;
}

const MultiDropzone: React.FC<MultiDropzoneProps> = ({
  onFileChange,
  acceptedTypes,
  removeHandler,
  showRemoveButton,
  fileIds,
  surveyId = '',
  selectOptions = [],
  onSelectChange,
  onConfirm,
  initialSelectedOptions = [],
  setSurveyFiles
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [previews, setPreviews] = useState<string[]>([]);
  const [filesData, setFilesData] = useState<FileData[]>([]);
  const [initialFileTypes, setInitialFileTypes] = useState<string[]>([]);
  const [removedFiles, setRemovedFiles] = useState<string[]>([]);
  const [loadingStates, setLoadingStates] = useState<boolean[]>([]);
  const [loadedFiles, setLoadedFiles] = useState<boolean[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(initialSelectedOptions);
  const [showModal, setShowModal] = useState(false);
  const [fileToRemove, setFileToRemove] = useState<string | null>(null);

  const {
    token, inspection, getLocalInspectionByIdAndUpdate, hasFileId
  } = useContext(InspectionContext);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>, fileId: string) => {
    event.preventDefault();
    event.stopPropagation();
    setFileToRemove(fileId);
    setShowModal(true);
  };

  const confirmRemove = () => {
    if (!fileToRemove) return;

    const indexToRemove = filesData.findIndex((file) => file.id === fileToRemove);

    setPreviews((prev) => prev.filter((_, i) => i !== indexToRemove));
    setInitialFileTypes((prev) => prev.filter((_, i) => i !== indexToRemove));
    setLoadingStates((prev) => prev.filter((_, i) => i !== indexToRemove));
    setLoadedFiles((prev) => prev.filter((_, i) => i !== indexToRemove));
    if (removeHandler) {
      removeHandler(fileToRemove);
    }
    setFilesData((prev) => prev.filter((_, i) => i !== indexToRemove));
    setRemovedFiles((prev) => [...prev, fileToRemove]);

    setShowModal(false);
  };

  const detachFileFromSection = async () => {
    if (!fileToRemove || !surveyId || !token) return;

    const survey = inspection?.surveys?.find((surv) => surv.surveyId === surveyId);
    if (!survey) return;

    const updatedFiles = survey.files?.filter((file) => file.id !== fileToRemove);

    const updatedSurvey = new Survey({
      ...survey,
      files: updatedFiles
    });

    await updatedSurvey.patchSurvey(token, inspection!.flogistixId!, inspection!, updatedSurvey);

    setSurveyFiles(updatedFiles ?? []);

    setSelectedOptions((prevSelectedOptions) => prevSelectedOptions.filter((option) => option !== fileToRemove));

    setShowModal(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    if (!event) return;

    event.preventDefault();
    event.stopPropagation();

    let newFiles: File[] = [];
    if (event.type === 'change') {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        newFiles = Array.from(target.files);
      }
    } else if (event.type === 'drop') {
      const e = event as React.DragEvent<HTMLDivElement>;
      if (e.dataTransfer.files) {
        newFiles = Array.from(e.dataTransfer.files);
      }
    }

    const newPreviews = newFiles.map(() => '');
    const newFileTypes = newFiles.map((file) => file.type);

    setPreviews((prev) => [...prev, ...newPreviews]);
    setInitialFileTypes((prev) => [...prev, ...newFileTypes]);
    setLoadingStates((prev) => [...prev, ...newFiles.map(() => true)]);
    setLoadedFiles((prev) => [...prev, ...newFiles.map(() => false)]);
    setFilesData((prev) => [
      ...prev,
      ...newFiles.map((file) => ({
        id: uuidv4(),
        parentId: '',
        version: 0,
        createdAt: new Date().toISOString(),
        createdBy: '',
        updatedAt: '',
        updatedBy: '',
        deleted: false,
        lastAct: '',
        fileType: file.type,
        s3Key: '',
        fileUrl: '',
        name: file.name,
        displayName: file.name,
        extension: file.name.split('.').pop() ?? '',
        url: ''
      }))
    ]);

    if (onFileChange) {
      onFileChange(event);
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    newFiles.forEach((file, index) => {
      const objectUrl = URL.createObjectURL(file);

      setPreviews((prev) => {
        const updatedPreviews = [...prev];
        updatedPreviews[previews.length + index] = objectUrl;
        return updatedPreviews;
      });

      setFilesData((prev) => {
        const updatedFilesData = [...prev];
        updatedFilesData[previews.length + index].url = objectUrl;
        return updatedFilesData;
      });
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFileToRemove(null);
  };

  const handleSelectChange = (value: string | number) => {
    setSelectedOptions((prev) => {
      const isSelected = prev.includes(value as string);
      if (isSelected) {
        return prev.filter((id) => id !== value);
      }
      return [...prev, value as string];
    });

    if (onSelectChange) {
      onSelectChange(value);
    }
  };

  const handleDropdownSelect = (option: { value: string | number; label: string }) => {
    handleSelectChange(option.value);
  };

  const handleDropdownDeselect = (option: { value: string | number; label: string }) => {
    handleSelectChange(option.value);
  };

  const handleConfirm = (prevSelected: string[]) => {
    if (onConfirm) {
      const uniqueSelectedOptions = Array.from(new Set(selectedOptions));
      setSelectedOptions(uniqueSelectedOptions);
      const combinedOptions = [...uniqueSelectedOptions, ...prevSelected];
      onConfirm(combinedOptions);
    }
  };

  const fetchAndSetFilePreviews = async () => {
    const newLoadingStates = fileIds.map(
      (fileId, index) => !!fileId && !removedFiles.includes(fileId) && !loadedFiles[index]
    );
    setLoadingStates(newLoadingStates);

    const fileDataArray: FileData[] = await Promise.all(
      fileIds
        .filter((fileId) => !!fileId && !removedFiles.includes(fileId))
        .map(async (fileId) => {
          const data = await fetchFileUrl(fileId, token);
          return data;
        })
    );

    const orderedFileDataArray = fileIds
      .filter((fileId) => !!fileId && !removedFiles.includes(fileId))
      .map((fileId) => fileDataArray.find((fileData) => fileData.id === fileId))
      .filter((fileData): fileData is FileData => fileData !== undefined);

    setFilesData(orderedFileDataArray);

    const fileTypeArray = orderedFileDataArray.map((data) => data.fileType);
    const filePreviewArray = orderedFileDataArray.map((data) => data.url);

    setInitialFileTypes(fileTypeArray);
    setPreviews(filePreviewArray);

    const markFileAsLoaded = () => true;

    // Mapping each file in orderedFileDataArray to a loaded status
    const updatedLoadedFiles = orderedFileDataArray.map(markFileAsLoaded);

    setLoadedFiles(updatedLoadedFiles);
    setLoadingStates(Array(fileIds.length).fill(false));
  };

  useEffect(() => {
    previews.forEach((preview) => URL.revokeObjectURL(preview));
  }, [previews]);

  useEffect(() => {
    const channel = new BroadcastChannel('transaction-channel');

    if (fileIds.length) {
      fetchAndSetFilePreviews();
    }

    channel.onmessage = (event) => {
      if (event.data.type === 'TRANSACTIONS_UPDATED' || event.data.type === 'TRANSACTION_COMPLETED') {
        setLoadingStates((prev) => prev.map(() => false));
        if (inspection?.id) {
          getLocalInspectionByIdAndUpdate(inspection.id);
        }
      }
    };

    return () => channel.close();
  }, [fileIds, token, removedFiles, inspection?.id]);

  useEffect(() => {
    if (initialSelectedOptions.length) {
      const matchingOptions = selectOptions.filter((option) => initialSelectedOptions.includes(option.value as string));
      const validSelectedOptions = matchingOptions.map((option) => option.value as string);
      setSelectedOptions(validSelectedOptions);
    }
  }, [initialSelectedOptions, selectOptions]);

  const previewElements = previews.map((preview, index) => {
    const fileId = filesData[index]?.id as string;
    const fileType = initialFileTypes[index] ?? filesData[index]?.fileType ?? '';

    const isVideoFile = ['video/mp4', 'video/avi', 'video/mov', 'video/wmv', 'video'].includes(fileType);

    return (
      <div key={fileId || `file-${index}-${uuidv4()}`} className="preview-item">
        {loadingStates[index] || !preview ? (
          <div className="loader">
            <div className="loader-circle" />
          </div>
        ) : isVideoFile ? (
          <video src={preview} className="multi-file-preview" controls>
            <source src={preview} type={fileType} />
            <track src="#" kind="captions" srcLang="en" label="English" default />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={preview} alt="File preview" className="multi-file-preview" />
        )}
        {showRemoveButton && (
          <button
            className="file-removal-button"
            type="button"
            onClick={(event) => handleRemove(event, fileId)}
          >
            Remove File
          </button>
        )}
      </div>
    );
  });

  return (
    <div className="dropzone-container">
      <MultiDropdown
        options={selectOptions.map((option) => ({
          ...option,
          content: (
            <div className="dropdown-option">
              {option.fileType?.startsWith('video') ? (
                <video src={option.fileUrl} className="dropdown-thumbnail" controls>
                  <source src={option.fileUrl} type={option.fileType} />
                  <track src="#" kind="captions" srcLang="en" label="English" default />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={option.fileUrl} alt={option.label} className="dropdown-thumbnail" />
              )}
              <span>{option.label}</span>
            </div>
          )
        }))}
        placeholder="Select Files"
        value={selectedOptions}
        onSelect={handleDropdownSelect}
        onDeselect={handleDropdownDeselect}
        onConfirm={handleConfirm}
        fixedWidth="100%"
        className="multi-dropdown"
        initialSelectedOptions={fileIds}
      />
      <div
        className="file-dropzone"
        onDrop={handleFileChange}
        onDragOver={handleDragOver}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <input type="file" accept={acceptedTypes} onChange={handleFileChange} ref={fileInputRef} multiple />
        {previews.length > 0 ? (
          <div className="previews-container">{previewElements}</div>
        ) : (
          <div className="placeholder-container">
            <img src={imageOutline} alt="Upload Placeholder" className="upload-placeholder" />
            <p className="placeholder-text">Upload Media</p>
          </div>
        )}
      </div>
      <DeleteConfirmationModal
        isOpen={showModal}
        onClose={handleCloseModal}
        onConfirm={confirmRemove}
        onRemoveFromSection={detachFileFromSection}
        disabled={fileToRemove ? hasFileId(fileToRemove) : false}
      />
    </div>
  );
};

export default MultiDropzone;

MultiDropzone.defaultProps = {
  removeHandler: undefined,
  showRemoveButton: false,
  surveyId: '',
  selectOptions: [],
  onSelectChange: undefined,
  onConfirm: undefined,
  initialSelectedOptions: []
};
