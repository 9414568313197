import { useState } from 'react';
import { IoCopyOutline } from 'react-icons/io5';

import RequestCell from './RequestCell';
import ResponseCell from './ResponseCell';
import { global } from '../../shared/colors';
import {
  Column,
  TransactionTableData,
  IconWithHoverProps
} from './uploadManagementTypes';
import './UploadManagement.scss';

const defaultStyle = global.Gray4;
const hoverStyle = global.Gray3;

const IconWithHover: React.FC<IconWithHoverProps> = ({
  record,
  copyToClipboard
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <IoCopyOutline
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => copyToClipboard(JSON.stringify(record))}
      className="tooltip-button"
      color={isHovered ? hoverStyle : defaultStyle}
      size="20px"
    />
  );
};

const renderDate = (dateString: string) => {
  const date = new Date(dateString);
  return <span>{date.toLocaleString()}</span>;
};

export const commonColumns = (
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void
): Column[] => [
  {
    title: 'Transaction ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Queue ID',
    dataIndex: 'queueId',
    key: 'queueId'
  },
  {
    title: 'Request',
    dataIndex: 'formattedRequest',
    key: 'formattedRequest',
    render: (formattedRequest: string) => (
      <RequestCell formattedRequest={formattedRequest} showTooltip={showTooltip} />
    )
  }
];

export const queueColumns = (
  copyToClipboard: (text: string) => void,
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void
): Column[] => [
  ...commonColumns(showTooltip),
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string, record: TransactionTableData) => (
      <div className="center">
        {renderDate(text)}
        <IconWithHover record={record} copyToClipboard={copyToClipboard} />
      </div>
    )
  }
];

export const processingColumns = (
  copyToClipboard: (text: string) => void,
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void
): Column[] => [
  ...commonColumns(showTooltip),
  {
    title: 'Attempts',
    dataIndex: 'attempts',
    key: 'attempts'
  },
  {
    title: 'Processing Started',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string, record: TransactionTableData) => (
      <div className="center">
        {renderDate(text)}
        <IconWithHover record={record} copyToClipboard={copyToClipboard} />
      </div>
    )
  }
];

export const retryingColumns = (
  copyToClipboard: (text: string) => void,
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void
): Column[] => [
  ...commonColumns(showTooltip),
  {
    title: 'Response',
    dataIndex: 'formattedResponse',
    key: 'formattedResponse',
    render: (formattedResponse: string) => (
      <ResponseCell formattedResponse={formattedResponse} showTooltip={showTooltip} />
    )
  },
  {
    title: 'Attempts',
    dataIndex: 'attempts',
    key: 'attempts'
  },
  {
    title: 'Processing Started',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string, record: TransactionTableData) => (
      <div className="center">
        {renderDate(text)}
        <IconWithHover record={record} copyToClipboard={copyToClipboard} />
      </div>
    )
  }
];

export const completedColumns = (
  copyToClipboard: (text: string) => void,
  showTooltip: (content: React.ReactNode, position: { top: number, left: number }) => void
): Column[] => [
  ...commonColumns(showTooltip),
  {
    title: 'Response',
    dataIndex: 'formattedResponse',
    key: 'formattedResponse',
    render: (formattedResponse: string) => (
      <ResponseCell formattedResponse={formattedResponse} showTooltip={showTooltip} />
    )
  },
  {
    title: 'Attempts',
    dataIndex: 'attempts',
    key: 'attempts'
  },
  {
    title: 'Completed At',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text: string, record: TransactionTableData) => (
      <div className="center">
        {renderDate(text)}
        <IconWithHover record={record} copyToClipboard={copyToClipboard} />
      </div>
    )
  }
];
