import React from 'react';
import '../InspectionTable.scss';

const IconWrapper = (props) => {
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <div
      {...props}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
      className="icon-wrapper"
    />
  );
};

export default IconWrapper;
