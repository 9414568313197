import React from 'react';
import styled from 'styled-components';
import { IoChevronDownOutline } from 'react-icons/io5';

const StyledIoChevronDownOutline = styled(({ className }) => (
  <IoChevronDownOutline className={className} />
))`
    margin-right: 20px;
    margin-top: 7px;
    color: #bcbcbc;
    height: 20px;
    width: 20px;
`;

export default StyledIoChevronDownOutline;
