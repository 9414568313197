import { Menu } from 'antd';
import styled from 'styled-components';

import { global } from '../../../shared/colors';

const StyledAntMenu = styled(Menu)`
    width: 62px;
    background: ${global.Gray6};
    margin-inline: 0;

    .ant-menu-item {
        margin: 0;
        width: 62px;
        height: 68px;
        border-radius: 0px 10px 10px 0px;
        border-right: none;
        padding-inline: 16px;
        transition: height 0.3s, background-color 0.3s, border-radius 0.3s ease-in;
        a {
            height: 100%;

            svg {
                font-size: 30px;
            }
        }
    }

    .ant-menu-item-selected {
        margin-inline: 0;
        border-right: none;
        height: 78px;
        border-radius: 0px 10px 10px 0px;
        background-color: ${global.Black};
        transition: height 0.3s, background-color 0.3s, border-radius 0.3s ease-in;
        svg {
            color: ${global.White};
        }
    }

    .ant-menu-item
        &:hover {
            background-color: ${global.Gray4} !important;
        } 
        &-seleted:hover {
            background-color: ${global.Black};
        }
        
    }
`;

export default StyledAntMenu;
