import React from 'react';
import './ReportingHome.scss';
import {
  Tab,
  Tabs,
  Header
} from '@flogistix/flo-ui';
import CreateNewReport from './CreateNewReport/CreateNewReport';
import ScheduledReports from './ScheduledReports/ScheduledReports';

const ReportingHome: React.FC = () => (
  <>
    <Header className="page-header" headerSize="h1">Reporting</Header>
    <Tabs defaultActiveKey="new_report" color="black" headerSize="h4" fontStyle="semi-bold" className="reporting-tabs">
      <Tab eventKey="new_report" title="Create New Report">
        <CreateNewReport />
      </Tab>
      <Tab eventKey="scheduled_reports" title="Scheduled Reports">
        <div className="report-container">
          <ScheduledReports />
        </div>
      </Tab>
    </Tabs>
  </>
);

export default ReportingHome;
