import React from 'react';
import styled from 'styled-components';
import { IoDownloadOutline } from 'react-icons/io5';

const StyledIoDownloadOutline = styled(
  React.forwardRef((props, ref) => (
    <IoDownloadOutline ref={ref} {...props} />
  ))
)`
    font-size: 16px;
`;

export default StyledIoDownloadOutline;
