import { DATABOOKS_URL } from '../shared/url';

const sendDatabookRequest = (
  inspection,
  token,
  user_organization,
  user_site
) => {
  const endpoint = DATABOOKS_URL;
  return fetch(`${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      databookId: `${inspection}`,
      action: 'email',
      subject: user_site.siteName,
      params: {
        orgId: user_organization.orgId,
        siteId: user_site.siteId,
        inspectionId: inspection
      }
    })
  });
};

export default sendDatabookRequest;
