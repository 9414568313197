import styled from 'styled-components';

import { global } from '../../../../shared/colors';

const StyledHeader = styled.div`
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 24px;
    color: ${global.Gray1};
    margin-top: 8px;
    margin-bottom: 15px;
    font-weight: 500;
    white-space: nowrap;
`;

export default StyledHeader;
