import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { StyledInput } from './styled-components';

const UploadDisplayName = ({ data, onDisplayNameChange, isDisabled }) => {
  const defaultDisplayName = data.displayName;
  const disabled = isDisabled(data);
  const [value, setValue] = useState(defaultDisplayName);

  const debouncedInputChange = _.debounce((displayName) => {
    onDisplayNameChange(data, displayName);
  }, 300);

  const handleInputChange = (event) => {
    setValue(event.currentTarget.value);
    debouncedInputChange(event.currentTarget.value);
  };

  useEffect(() => {
    const initDisplayName = data.displayName
      ? data.displayName
      : defaultDisplayName;
    setValue(initDisplayName);
  }, []);

  return (
    <StyledInput
      disabled={disabled}
      value={value}
      onChange={handleInputChange}
      className="upload-input"
      placeholder="Enter Name"
    />
  );
};

export default UploadDisplayName;
