import { IAM_URL } from '../shared/url';

const getIAM = async (endpoint, token) => {
  const results = await fetch(`${IAM_URL}${endpoint}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });

  return results.json();
};
export default getIAM;
