import { Layout } from 'antd';
import styled from 'styled-components';

import { global } from '../../../shared/colors';

const { Sider } = Layout;

const StyledSider = styled(Sider)`
    background-color: ${global.Gray6} !important;
`;

export default StyledSider;
