import React from 'react';

import UploadDisplayName from './UploadDisplayName';
import UploadFileStatusAndDelete from './UploadFileStatusAndDelete';
import { StyledSelect } from '../../components';

const MultiUploadTableColumns = ({
  orgOptions,
  onInspectionChange,
  onSiteChange,
  onOrgChange,
  onDisplayNameChange,
  sensorOptions,
  onSensorTypeChange,
  onDeleteFile,
  flogistixId = null,
  siteName = null,
  orgName = null,
  isDisabled,
  getUploadStatus
}) => {
  const sortByCreationDate = (arr) => {
    arr.sort(
      (a, b) => new Date(b.value.createdAt) - new Date(a.value.createdAt)
    );
    return arr;
  };

  return [
    {
      title: 'File Name',
      render: (data) => (
        <UploadDisplayName
          data={data}
          onDisplayNameChange={onDisplayNameChange}
          isDisabled={isDisabled}
        />
      ),
      width: '20%'
    },
    {
      title: 'Customer',
      render: (data) => (!orgName ? (
        <div data-cy="orgSelect">
          <StyledSelect
            options={orgOptions}
            value={data.orgId}
            onChange={(option) => {
              onOrgChange(data, option?.value);
            }}
            disabled={isDisabled(data)}
          />
        </div>
      ) : (
        <div>{orgName}</div>
      )),
      width: '20%'
    },
    {
      title: 'Site',
      render: (data) => (!siteName ? (
        <div data-cy="siteSelect">
          <StyledSelect
            options={data?.sites}
            value={data.siteId}
            onChange={(option) => {
              onSiteChange(data, option?.value);
            }}
            disabled={
              isDisabled(data) || (!orgName && !data.orgId)
            }
          />
        </div>
      ) : (
        <div>{siteName}</div>
      )),
      width: '20%'
    },
    {
      title: 'Identifier',
      render: (data) => (!flogistixId ? (
        <div data-cy="identifierSelect">
          <StyledSelect
            options={sortByCreationDate(data?.identifiers)}
            value={data.inspectionId}
            onChange={(option) => {
              onInspectionChange(data, option?.value);
            }}
            disabled={
              isDisabled(data)
                                || (!orgName && !data.orgId)
                                || (!siteName && !data.siteId)
            }
          />
        </div>
      ) : (
        <div>{flogistixId}</div>
      )),
      width: '20%'
    },
    {
      title: 'Sensor Type',
      render: (data) => (
        <div className="detail-select">
          <StyledSelect
            options={sensorOptions}
            value={data.sensorType}
            onChange={(option) => {
              onSensorTypeChange(data, option?.optionType?.id);
            }}
            disabled={isDisabled(data)}
            isSensor
          />
        </div>
      ),
      width: '20%'
    },
    {
      render: (data) => (
        <UploadFileStatusAndDelete
          onDeleteFile={onDeleteFile}
          data={data}
          getUploadStatus={getUploadStatus}
        />
      )
    }
  ];
};

export default MultiUploadTableColumns;
