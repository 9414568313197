import React, {
  useContext, useEffect, useState, useRef
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, DualInput, Button } from '@flogistix/flo-ui';
import {
  IoRemoveCircle,
  IoChevronDownOutline,
  IoChevronUpOutline,
  IoAddOutline
} from 'react-icons/io5';

import Survey from '../../../../../classes/survey';
import { getAllInspectors, getAllInstruments } from '../../../../../dexie/operations';
import { Inspector } from '../../../../../classes/inspection';
import { InspectionContext } from '../../../../../context/InspectionContext';
import { SurveyMethod } from '../../../../../classes/enums';
import SurveyInstrument from '../../../../../classes/survey-instrument';
import AirMethaneFile, { UploadFile } from '../../../../../classes/airmethane-file';
import MultiDropzone from '../../../../../components/FileDropzone/MultiDropzone/MultiDropzone';
import { global } from '../../../../../shared/colors';
import './SurveyCard.scss';
import { fetchFilesForInspection, fetchFileUrl } from '../../../../../services/airmethaneApi';

interface SurveyCardProps {
  survey: Survey;
  deleteSurvey: () => void;
  canDelete: boolean;
}

const SurveyCard = ({ survey, deleteSurvey, canDelete }: SurveyCardProps) => {
  const {
    inspection, token, setCompletedSectionsOnChange, fileOptions, setFileOptions
  } = useContext(InspectionContext);
  const deleteButtonRef = useRef<HTMLDivElement>(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const surveyNumber = (inspection?.surveys?.findIndex((surv) => surv?.surveyId === survey?.surveyId) ?? -1) + 1;
  const [surveyInspectors, setSurveyInspectors] = useState<Inspector[]>(survey.inspectors || []);
  const [surveyMethod, setSurveyMethod] = useState<SurveyMethod | ''>(survey.method || '');
  const [surveyInstrument, setSurveyInstrument] = useState<SurveyInstrument | null>(survey.surveyInstrument || null);
  const [surveyDurationHours, setSurveyDurationHours] = useState<number | string | undefined>(
    survey.inspectionDurationHours || undefined
  );
  const [surveyDurationMinutes, setSurveyDurationMinutes] = useState<number | string | undefined>(
    survey.inspectionDurationMinutes || undefined
  );
  const [surveyFiles, setSurveyFiles] = useState<AirMethaneFile[]>(survey.files || []);
  const [searchMethods, setSearchMethods] = useState('');
  const [searchInspectors, setSearchInspectors] = useState('');
  const [searchInstruments, setSearchInstruments] = useState('');
  const [inspectorOptions, setInspectorOptions] = useState<{ label: string, value: string }[]>([]);
  const [inspectors, setInspectors] = useState(survey?.inspectors);
  const [additionalInspectors, setAdditionalInspectors] = useState<number>(((inspectors?.length ?? 2) - 2) || 0);
  const [instrumentOptions, setInstrumentOptions] = useState<{ label: string, value: string }[]>([]);
  const getSelectedOptionsFromSurveyFiles = () => {
    if (!survey.files || !fileOptions) return [];

    return survey.files
      .map((file) => fileOptions.find((option) => option.value === file.id)?.value)
      .filter(Boolean);
  };
  const selectedOptionsFromSurveyFiles = getSelectedOptionsFromSurveyFiles() as string[];
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedOptionsFromSurveyFiles);

  interface DropdownOption {
    value: string | number;
    label: string;
  }

  const handleInspectorChange = (selectedOption: DropdownOption, index: number) => {
    const newInspectorId = selectedOption.value.toString();
    const alreadyExists = surveyInspectors.findIndex((inspector) => inspector.id === newInspectorId);
    if (alreadyExists !== -1 && alreadyExists !== index) return;
    const selectedInspector = inspectors?.find((option) => option?.id === newInspectorId);
    const newInspectors = [...surveyInspectors];
    newInspectors[index] = selectedInspector as Inspector;
    const sanitizedInspectors = newInspectors.filter((inspector) => !!inspector);
    survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
      ...survey,
      inspectors: sanitizedInspectors
    } as Survey).then(() => {
      setSurveyInspectors(sanitizedInspectors);
      setCompletedSectionsOnChange();
    });
  };

  const handleSurveyMethodChange = (selectedOption: { value: string, label: string }) => {
    const newMethod = selectedOption.value as SurveyMethod;
    if (newMethod !== surveyMethod) {
      survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
        ...survey,
        method: newMethod
      } as Survey).then(() => {
        setSurveyMethod(newMethod);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleSurveyInstrumentChange = (selectedOption: { value: string, label: string }) => {
    const selectedInstrument = instrumentOptions.find((option) => option.value === selectedOption.value);
    if (selectedInstrument) {
      const newInstrument = new SurveyInstrument(selectedInstrument.value);
      survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
        ...survey,
        surveyInstrument: newInstrument
      } as Survey).then(() => setSurveyInstrument(new SurveyInstrument(selectedInstrument.value))).catch(() => {
        setSurveyInstrument(null);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleDurationHourChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newHours = parseInt(event.target.value, 10);
    if (newHours !== surveyDurationHours) {
      survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
        ...survey,
        inspectionDurationHours: newHours
      } as Survey).then(() => {
        setSurveyDurationHours(newHours);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleDurationMinuteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMinutes = parseInt(event.target.value, 10);
    if (newMinutes !== surveyDurationMinutes) {
      survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
        ...survey,
        inspectionDurationMinutes: newMinutes
      } as Survey).then(() => {
        setSurveyDurationMinutes(newMinutes);
        setCompletedSectionsOnChange();
      }).catch(() => {
        setSurveyDurationMinutes(0);
        setCompletedSectionsOnChange();
      });
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    const newFiles: UploadFile[] = [];
    if (event.type === 'change') {
      const target = event.target as HTMLInputElement;
      if (target.files) {
        [...target.files].forEach((file) => {
          newFiles.push({ file, id: uuidv4(), displayName: file.name });
        });
      }
    } else if (event.type === 'drop') {
      const e = event as React.DragEvent<HTMLDivElement>;
      e.preventDefault();
      if (e.dataTransfer.files) {
        [...e.dataTransfer.files].forEach((file) => {
          newFiles.push({ file, id: uuidv4(), displayName: file.name });
        });
      }
    }

    survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
      ...survey,
      pendingFiles: newFiles
    } as unknown as Survey).then(() => {
      const queuedFiles = newFiles.map((file) => ({ id: file.id }));
      setSurveyFiles((prev) => [...prev, ...queuedFiles] as AirMethaneFile[]);
      setCompletedSectionsOnChange();
    });
  };

  const handleFileSelectConfirm = (selectedFileIds: string[]) => {
    const uniqueSelectedFileIds = [...new Set(selectedFileIds)];

    const selectedFiles = uniqueSelectedFileIds.map((fileId) => {
      const selectedFileOption = fileOptions.find((option) => option.value === fileId);
      return selectedFileOption ? new AirMethaneFile(selectedFileOption.value as string) : null;
    }).filter((file): file is AirMethaneFile => file !== null);

    if (selectedFiles.length > 0) {
      survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
        ...survey,
        files: selectedFiles,
        pendingFiles: []
      } as unknown as Survey).then(() => {
        setSurveyFiles(selectedFiles);
        setCompletedSectionsOnChange();
      });
    } else {
      console.warn('No valid file options found for selected IDs');
    }
  };

  const handleRemoveFile = (fileId?: string) => {
    if (!fileId) return;

    survey.removeFile(token, 0, new AirMethaneFile(fileId));
    survey.patchSurvey(token, inspection!.flogistixId!, inspection!, {
      ...survey,
      files: surveyFiles.filter((file) => file.id !== fileId),
      pendingFiles: []
    } as unknown as Survey).then(() => {
      setSurveyFiles((prev) => prev.filter((file) => file.id !== fileId));
      setCompletedSectionsOnChange();
    });
  };

  const handleRemoveClick = () => {
    setShowDeleteButton(true);
  };

  const handleDeleteClick = () => {
    deleteSurvey();
  };

  const handleFileSelectChange = (fileId: string | number) => {
    const selectedFile = fileOptions.find((option) => option.value === fileId);

    if (selectedFile) {
      setSelectedOptions((prevSelectedOptions) => {
        const isSelected = prevSelectedOptions.includes(fileId as string);

        let updatedOptions;
        if (isSelected) {
          updatedOptions = prevSelectedOptions.filter((id) => id !== fileId);
        } else {
          updatedOptions = [...new Set([...prevSelectedOptions, fileId as string])];
        }

        return updatedOptions;
      });
    } else {
      console.warn('No file option found for id:', fileId);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        deleteButtonRef.current
        && !deleteButtonRef.current.contains(event.target as Node)
      ) {
        setShowDeleteButton(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const getInspectorOptions = async () => {
      const inspectorData = await getAllInspectors();
      const formattedOptions = inspectorData.map((inspector) => ({
        label: inspector.name,
        value: inspector.inspectorId
      }));
      setInspectorOptions(formattedOptions);
      setInspectors(inspectorData as []);
    };

    const getInstrumentOptions = async () => {
      const instruments = await getAllInstruments();
      const formattedOptions = instruments.map((instrument) => ({
        label: `${instrument.name} - ${instrument.serialNumber}`,
        value: instrument.instrumentId
      }));
      setInstrumentOptions(formattedOptions);
    };
    getInspectorOptions();
    if (!instrumentOptions.length) {
      getInstrumentOptions();
    }
  }, []);

  const fetchAndSetFileOptions = async (inspectionId: string, tkn: string) => {
    try {
      const files = await fetchFilesForInspection(inspectionId, tkn);

      const fileDataArray = await Promise.all(
        files.map(async (file: { id: unknown }) => {
          const fileData = await fetchFileUrl(file.id, tkn);
          return {
            ...file,
            fileUrl: fileData.url,
            fileType: fileData.fileType
          };
        })
      );

      const options = fileDataArray.map((file) => ({
        label: file.name,
        value: file.id,
        fileUrl: file.fileUrl,
        fileType: file.fileType
      }));

      setFileOptions(options);
    } catch (error) {
      console.error('Error fetching inspection files:', error);
    }
  };

  useEffect(() => {
    if (inspection?.id && token) {
      fetchAndSetFileOptions(inspection.id, token);
    }
  }, [inspection?.id, token, fileOptions.length, surveyFiles]);

  return (
    <div className="delete-button-wrapper" ref={deleteButtonRef}>
      {
        canDelete && (
          showDeleteButton ? (
            <Button
              variation="red-outline"
              className="delete-button-container"
              size="medium"
              type="button"
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
          ) : (
            <button
              className="icon-button-wrapper"
              type="button"
              onClick={handleRemoveClick}
              aria-label="Remove item"
            >
              <IoRemoveCircle className="survey-remove-icon" size={24} />
            </button>
          )
        )
      }
      <div className={`survey-card-container ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="header-wrapper">
          <div className="survey-header">
            <h5 className="survey-header">{`Survey ${surveyNumber}`}</h5>
            <button
              type="button"
              className="survey-toggle-button"
              onClick={() => setIsExpanded(!isExpanded)}
              tabIndex={0}
            >
              {isExpanded ? (
                <IoChevronUpOutline style={{ color: global.Gray4 }} />
              ) : (
                <IoChevronDownOutline style={{ color: global.Gray4 }} />
              )}
            </button>
          </div>
        </div>

        {isExpanded && (
          <div className="fields-container">
            <div className="inspector-details">
              <label className="field-label">Inspector</label>
              <Dropdown
                id="inspector-1"
                value={inspectorOptions?.find((option) => option.value === surveyInspectors.at(0)?.id)?.label || ''}
                onSelect={(option) => handleInspectorChange(option, 0)}
                options={inspectorOptions}
                onSearchChange={(value) => setSearchInspectors(value)}
                className="input__dropdown"
                searchValue={searchInspectors}
                placeholder="Select Inspector"
                fixedWidth="100%"
              />
              <div className="credentials">
                {
                  surveyInspectors.at(0)?.credentials.map((credential) => (
                    <p
                      key={credential.licenseNumber}
                      className="credential"
                    >
                      Credentials:
                      {' '}
                      {credential.credentials}
                      {' '}
                      • License:
                      {' '}
                      {credential.licenseNumber}
                    </p>
                  ))
                }
              </div>
            </div>

            <div className="inspector-details">
              <label className="field-label">Inspector</label>
              <Dropdown
                id="inspector-2"
                value={inspectorOptions?.find((option) => option.value === surveyInspectors.at(1)?.id)?.label || ''}
                onSelect={(option) => handleInspectorChange(option, 1)}
                onClear={() => handleInspectorChange({ label: '', value: '' }, 1)}
                options={inspectorOptions}
                onSearchChange={(value) => setSearchInspectors(value)}
                className="input__dropdown"
                searchValue={searchInspectors}
                placeholder="Select Inspector"
                fixedWidth="100%"
              />
              <div className="credentials">
                {
                  surveyInspectors.at(1)?.credentials.map((credential) => (
                    <p
                      key={credential.licenseNumber}
                      className="credential"
                    >
                      Credentials:
                      {' '}
                      {credential.credentials}
                      {' '}
                      • License:
                      {' '}
                      {credential.licenseNumber}
                    </p>
                  ))
                }
              </div>
            </div>
            {
              additionalInspectors > 0
              && Array.from(Array(additionalInspectors).keys()).map((index) => (
                <div className="inspector-details">
                  <label className="field-label">Inspector</label>
                  <Dropdown
                    id={`inspector-${additionalInspectors + 2}` as string}
                    value={inspectorOptions?.find(
                      (option) => option.value === surveyInspectors.at(index + 2)?.id
                    )?.label || ''}
                    onSelect={(option) => handleInspectorChange(option, index + 2)}
                    onClear={() => handleInspectorChange({ label: '', value: '' }, index + 2)}
                    options={inspectorOptions}
                    onSearchChange={(value) => setSearchInspectors(value)}
                    className="input__dropdown"
                    searchValue={searchInspectors}
                    placeholder="Select Inspector"
                    fixedWidth="100%"
                  />
                  <div className="credentials">
                    {
                      surveyInspectors.at(index + 2)?.credentials.map((credential) => (
                        <p
                          key={credential.licenseNumber}
                          className="credential"
                        >
                          Credentials:
                          {' '}
                          {credential.credentials}
                          {' '}
                          • License:
                          {' '}
                          {credential.licenseNumber}
                        </p>
                      ))
                    }
                  </div>
                </div>
              ))
            }
            <button
              className="add-new-item-button"
              type="button"
              onClick={() => setAdditionalInspectors(additionalInspectors + 1)}
            >
              <IoAddOutline />
              Add inspector
            </button>

            <label className="field-label">Inspection method</label>
            <Dropdown
              id="inspection-method"
              value={Object.values(SurveyMethod).find((method) => method === surveyMethod) || ''}
              onSelect={(option) => handleSurveyMethodChange(option as { value: string, label: string })}
              options={Object.values(SurveyMethod).map((method) => ({
                label: method,
                value: method
              }))}
              onSearchChange={(value) => setSearchMethods(value)}
              className="input__dropdown"
              searchValue={searchMethods}
              placeholder="Select Inspection Method"
              fixedWidth="100%"
            />

            <label className="field-label">Survey instrument</label>
            <Dropdown
              id="survey-instrument"
              value={instrumentOptions.find((option) => option.value === surveyInstrument?.id)?.label || ''}
              onSelect={(option) => handleSurveyInstrumentChange(option as { value: string, label: string })}
              options={instrumentOptions}
              onSearchChange={(value) => setSearchInstruments(value)}
              className="input__dropdown"
              searchValue={searchInstruments}
              placeholder="Select Instrument"
              fixedWidth="100%"
            />

            <label className="field-label">Duration</label>
            <DualInput
              leftInput={{
                value: surveyDurationHours,
                onBlur: handleDurationHourChange,
                onChange: (e) => setSurveyDurationHours(e.target.value),
                placeholder: 'Hours',
                type: 'number'
              }}
              rightInput={{
                value: surveyDurationMinutes,
                onBlur: handleDurationMinuteChange,
                onChange: (e) => setSurveyDurationMinutes(e.target.value),
                placeholder: 'Minutes',
                type: 'number'
              }}
            />

            <label className="field-label">Survey files</label>
            <p className="sub-label">Files should be .jpg, .png, .heic, .mp4, .mov, or .avi</p>
            <MultiDropzone
              surveyId={survey.surveyId}
              onFileChange={handleFileChange}
              setSurveyFiles={setSurveyFiles}
              initialSelectedOptions={selectedOptions as string[]}
              acceptedTypes=".png, .jpg, .jpeg, .heic, .mp4, .mov, .avi"
              removeHandler={handleRemoveFile}
              showRemoveButton
              fileIds={surveyFiles?.map((file) => file?.id)?.filter((id = '') => !!id) as string[] ?? []}
              selectOptions={fileOptions.length > 0 ? fileOptions : undefined}
              onSelectChange={handleFileSelectChange}
              onConfirm={handleFileSelectConfirm}
            />

          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyCard;
