import React from 'react';

import './ProcessTable.scss';
import { ProcessTableProps, TransactionTableData } from '../../pages/UploadManagment/uploadManagementTypes';

const ProcessTable: React.FC<ProcessTableProps> = ({ data, columns }) => {
  if (data.length === 0) {
    return (
      <div className="no-data">
        <h1>No data to display</h1>
        <p>You haven&apos;t uploaded any data while offline yet.</p>
      </div>
    );
  }

  const renderPropertyValue = (value: string | number | boolean | bigint): React.ReactNode => {
    if (value === null) return 'null';

    if (typeof value === 'object') {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    }

    if (
      typeof value === 'string'
      || typeof value === 'number'
      || typeof value === 'boolean'
      || typeof value === 'bigint'
    ) {
      return value.toString();
    }

    return 'Unsupported type';
  };

  return (
    <table className="process-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.key} style={{ width: column.width ?? 'auto' }}>
              {column.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.map((item) => (
            <tr key={item.id}>
              {columns.map((column) => (
                <td key={column.key}>
                  {column.render
                    ? column
                      .render(item[column.dataIndex as keyof TransactionTableData] as string, item)
                    : renderPropertyValue(
                      item[column.dataIndex as keyof TransactionTableData] as string
                    )}
                </td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length} style={{ textAlign: 'center' }}>
              No data available.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ProcessTable;
