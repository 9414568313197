/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import {
  IoAdd,
  IoAddOutline,
  IoMap,
  IoMapOutline
} from 'react-icons/io5';
import PropTypes from 'prop-types';
import {
  barChart,
  barChartOutline,
  upload,
  uploadOutline,
  clipboard,
  clipboardOutline
} from './Icons';

import { GlobalContext } from '../../context/GlobalContext';
import { StyledAntMenu, StyledSider } from './styled-components';
import { homeSections } from '../../shared/constants';

const Importer = upload;
const ImporterOutline = uploadOutline;

const icons = {
  clipboard,
  clipboardOutline,
  IoAdd,
  IoAddOutline,
  upload,
  uploadOutline,
  Importer,
  ImporterOutline,
  IoMap,
  IoMapOutline,
  barChart,
  barChartOutline
};

const generateNavIcon = (iconName, isActive, linkedIcon) => {
  const Icon = icons[`${iconName}${isActive ? '' : 'Outline'}`];
  const isImporter = linkedIcon.key === 'Importer';
  return <Icon className={isImporter ? 'rotate' : ''} aria-hidden="true" />;
};

const buildItemLinks = (
  navItems,
  currentActiveKey,
  toggleSideNav,
  setActiveHomeSection,
  isStep2FormActive
) => navItems.map((linkItem) => ({
  ...linkItem,
  icon: (
    <NavLink
      exact="true"
      to={linkItem.path}
      key={linkItem.key}
      onClick={() => {
        if (linkItem.icon === 'IoAdd' || linkItem.icon === 'IoAddOutline') {
          toggleSideNav();
        }
        if (linkItem.key === 'Inspections' && isStep2FormActive) setActiveHomeSection(homeSections.IN_PROGRESS);
      }}
    >
      {generateNavIcon(
        linkItem.icon,
        currentActiveKey === linkItem.key,
        linkItem,
        linkItem.key
      )}
    </NavLink>
  )
}));

const SideNav = ({ activeLink, navLinks }) => {
  const {
    is_admin,
    has_access,
    sideNavVisible,
    toggleSideNav,
    setActiveHomeSection,
    isStep2FormActive
  } = useContext(GlobalContext);

  const defaultLinks = [
    {
      key: 'Inspections',
      icon: 'clipboard',
      label: 'Inspections',
      path: '/'
    }
  ];

  const displayedLinks = is_admin ? navLinks : defaultLinks;

  return !has_access || !sideNavVisible ? null : (
    <StyledSider collapsedWidth={62} collapsed>
      <nav aria-label="sidebar">
        <StyledAntMenu
          mode="inline"
          items={buildItemLinks(
            displayedLinks,
            activeLink.key,
            toggleSideNav,
            setActiveHomeSection,
            isStep2FormActive
          )}
          selectedKeys={[
            displayedLinks.find((link) => activeLink.path === link.path)?.key
              ?? 'Inspections'
          ]}
        />
      </nav>
    </StyledSider>
  );
};

SideNav.propTypes = {
  activeLink: PropTypes.shape({
    key: PropTypes.string,
    path: PropTypes.string
  }),
  navLinks: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired
    })
  )
};

SideNav.defaultProps = {
  activeLink: {},
  navLinks: []
};

export default SideNav;
