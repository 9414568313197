import styled from 'styled-components';
import { Modal } from 'antd';

const StyledDeleteInspModal = styled(Modal)`
    .ant-modal-body {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ant-modal-content {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-radius: 0;
        background-color: ${global.White};
        border-radius: 10px;
        display: flex;
        justify-content: center;
    }
`;

export default StyledDeleteInspModal;
