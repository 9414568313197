import React from 'react';
import styled from 'styled-components';
import { IoPushOutline } from 'react-icons/io5';

import { global } from '../../../shared/colors';

const StyledUploadOutlined = styled(({ className }) => (
  <IoPushOutline className={className} />
))`
    color: ${global.Gray3};
    cursor: pointer;
    font-size: 32px;
    &:hover {
        font-size: 48px;
    }
`;

export default StyledUploadOutlined;
