import styled from 'styled-components';

const TableContainer = styled.div`
    margin-right: 2%;
    border-radius: 20px;
    background: #f8f8f8;
    overflow: hidden;

    div.ant-table-wrapper > div > div > div {
        height: 54vh;
    }
`;

export default TableContainer;
