import styled from 'styled-components';
import { Tabs } from 'antd';

import { global } from '../../../../shared/colors';

const StyledTabs = styled(Tabs)`
    margin-right: 31px;
    margin-left: 61px;
    background: #f8f8f8;
    border-radius: 20px;
    padding-top: 80px;
    height: 63vh;

    .ant-tabs-tab-btn {
        display: flex;
    }

    .ant-tabs-tab {
        padding: 8px 0 !important;
    }

    .ant-tabs .ant-tabs-tab-btn {
        justify-content: space-between;
    }

    .ant-tabs-tab .ant-tabs-tab-btn {
        color: ${global.Gray4};
        font-weight: normal;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${global.Black} !important;
        font-weight: 600 !important;
    }

    .ant-tabs-ink-bar {
        position: absolute
        left: 24px !important;
        right: 0;
        background: transparent;
        pointer-events: none;
    }

    .ant-tabs-tab-btn:hover {
        color: ${global.PrimaryBlue};
    }

    &.ant-tabs > .ant-tabs-nav {
        margin-left: 40px !important;
    }

    .ant-tabs-ink-bar:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 24px !important;
        right: 0;
        height: 1px;
        background-color: ${global.PrimaryBlue};
    }
`;

export default StyledTabs;
