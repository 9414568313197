import styled from 'styled-components';

const UploadFileTable = styled.div`
    height: 500px;
    overflow: auto;
    .ant-modal-body {
        .uploaded-file-table {
            height: 25vh;
            overflow: visible;
        }
    }

    .ant-table-wrapper {
        height: 100%;
        
        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;

                .ant-table {
                    height: 100%;

                    .ant-table-container {
                        height: 100%;
                        overflow: auto;
                    }
                }
            }
        }
    } 
`;

export default UploadFileTable;
