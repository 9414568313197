import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled((props) => {
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <div
      {...props}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    />
  );
})`
    display: flex;
    justify-content: space-between;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon-group {
        display: flex;

        a {
            margin: 0;
        }
    }
`;

export default IconWrapper;
