import getIAM from './getIAM';

const getSites = async (token, setSites, triggerGlobalNotification) => {
  try {
    const sites = await getIAM('/iam/sites', token);
    setSites(sites?.sites);
  } catch (err) {
    triggerGlobalNotification(
      'error',
      'Failed to fetch sites'
    );
  }
};

export default getSites;
