import styled from 'styled-components';
import { global } from '../../../shared/colors';

const StyledParagraph = styled.p`
    color: ${global.textGrey};
    margin-bottom: 0;
    margin-top: 30px;
`;

export default StyledParagraph;
