import styled from 'styled-components';

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 272px;
    background: ${global.White};
    border-radius: 2px;
`;

export default StyledDiv;
