import React from 'react';
import { IoChevronForward } from 'react-icons/io5';
import styled from 'styled-components';

import { tableRowFixture } from '../../../shared/utils';
import { Table } from '../../../components';
import {
  Wrapper,
  HeaderWrapper,
  SkeletonBreadcrumbs,
  SkeletonColumns,
  SkeletonHeader
} from './styled-components';
import {
  Breadcrumb,
  StyledBreadcrumbs,
  StyledSpan
} from '../../../shared/styled-components';

const InspectionBreadcrumb = styled.button`
    background: none;
    border: none;
    color: ${global.Gray4};
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    font-size: 13px;
    width: 76px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    padding: 0;

    &:hover {
        text-decoration: underline;
        color: ${global.Black};
    }
`;

const StyledHeader = styled.div`
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 24px;
    color: ${global.Gray1};
    margin-top: 8px;
    margin-bottom: 38px;
    font-weight: 500;
    white-space: nowrap;
`;

const oneColumn = [
  {
    title: 'Display Name',
    width: '100%',
    render: () => <SkeletonColumns />,
    sortable: true
  }
];

const getColumns = (tableLocation) => [
  {
    title: 'Display Name',
    width: '70%',
    sortable: true,
    render: () => <SkeletonColumns />
  },
  {
    title: tableLocation === 'leaks' ? 'Tag Id' : 'Sensor Type',
    width: '30%',
    sortable: true,
    render: () => <SkeletonColumns />
  }
];

const SkeletonTable = ({ justTable, tableLocation = 'leaks' }) => {
  const typeTab = localStorage.getItem('typeTab') || '1';

  return justTable ? (
    <Table
      data={tableRowFixture || ''}
      columns={
        typeTab === '3' || typeTab === '2'
          ? getColumns(tableLocation)
          : oneColumn
      }
      searchable={false}
    />
  ) : (
    <Wrapper style={{ height: '100vh' }}>
      <HeaderWrapper>
        <StyledHeader>
          <SkeletonHeader />
        </StyledHeader>
        <StyledBreadcrumbs>
          <InspectionBreadcrumb>Inspections</InspectionBreadcrumb>
          <IoChevronForward />
          <Breadcrumb>
            <SkeletonBreadcrumbs />
          </Breadcrumb>
          <IoChevronForward />
          <Breadcrumb>
            <SkeletonBreadcrumbs />
          </Breadcrumb>
          <IoChevronForward />
          <StyledSpan>
            <Breadcrumb>
              <SkeletonBreadcrumbs />
            </Breadcrumb>
          </StyledSpan>
        </StyledBreadcrumbs>
      </HeaderWrapper>
    </Wrapper>
  );
};

export default SkeletonTable;
