import React from 'react';
import { Skeleton } from 'antd';

const SkeletonBreadcrumbs = () => (
  <Skeleton.Input
    size="small"
    active
    paragraph={{ rows: 1 }}
    style={{ height: '10px' }}
  />
);

export default SkeletonBreadcrumbs;
