import PropTypes from 'prop-types';
import { Button } from '@flogistix/flo-ui';
import {
  ButtonContainer,
  TextWithIcon,
  StyledParagraph,
  StyledInspection,
  StyledDiv,
  StyledDataBooksModal
} from './styled-components';
import { ButtonContainer as ClickableContainer } from '../../shared/styled-components';

const DatabooksModal = ({
  onOk, isVisible, inspection, onCancel
}) => (
  <StyledDataBooksModal
    open={isVisible}
    footer={null}
    closable={false}
    centered
  >
    <StyledDiv>
      <TextWithIcon>
        <StyledParagraph>
          Would you like to receive an email with a download link for
          the files in the following inspection?
        </StyledParagraph>
        <StyledInspection>{inspection?.flogistixId}</StyledInspection>
      </TextWithIcon>
      <ButtonContainer>
        <ClickableContainer>
          <Button
            variation="gray"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </ClickableContainer>
        <ClickableContainer>
          <Button
            onClick={() => {
              onOk(inspection?.id);
            }}
          >
            Send
          </Button>
        </ClickableContainer>
      </ButtonContainer>
    </StyledDiv>
  </StyledDataBooksModal>
);

DatabooksModal.propTypes = {
  onOk: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  inspection: PropTypes.shape({
    id: PropTypes.string,
    flogistixId: PropTypes.string
  }),
  onCancel: PropTypes.func.isRequired
};

DatabooksModal.defaultProps = {
  inspection: {}
};

export default DatabooksModal;
