import styled from 'styled-components';

const StyledColumnDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-group {
    display: flex;

    a {
      margin: 0;
    }
  }
`;

export default StyledColumnDiv;
