import { IAM_URL } from '../shared/url';

const getSitesForOrg = async (orgId, data, callback, token, triggerGlobalNotification) => {
  try {
    const results = await fetch(`${IAM_URL}/iam/orgs/${orgId}/sites`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });

    if (results.ok) {
      const res = await results.json();

      const formattedSites = res?.sites?.map((site) => ({
        label: site?.siteName,
        value: site
      }));

      callback(data, {
        orgId,
        sites: formattedSites
      });
      return res;
    }
    return null;
  } catch (err) {
    triggerGlobalNotification(
      'error',
      'Failed to fetch sites for customer'
    );
    return null;
  }
};

export default getSitesForOrg;
