import React from 'react';
import styled from 'styled-components';
import { HiOutlineTrash } from 'react-icons/hi';

const StyledIoTrashOutline = styled(({ className }) => (
  <HiOutlineTrash className={className} />
))`
    font-size: 16px;
`;

export default StyledIoTrashOutline;
