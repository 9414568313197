/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import styled from 'styled-components';

import Home from './pages/Home/Home';
import FilteredFiles from './pages/Files/FilteredFiles';
import UploadManagement from './pages/UploadManagment/UploadManagement';
import { GlobalContext } from './context/GlobalContext';
import AccessDenied from './pages/NoAccess/AccessDenied';
import Header from './components/Header/Header';
import SideNav from './components/Sidenav/SideNav';
import { global } from './shared/colors';
import Inspections from './pages/Inspections/Inspection';
import PartTwo from './pages/InspectionLayout/form-pages/site-details/PartTwo';
import Verifications from './pages/InspectionLayout/form-pages/inspection-details/verifications/Verifications';
import Surveys from './pages/InspectionLayout/form-pages/inspection-details/surveys/Surveys';
import PartOne from './pages/InspectionLayout/form-pages/site-details/PartOne';
import Info from './pages/InspectionLayout/form-pages/inspection-details/Information';
import Weather from './pages/InspectionLayout/form-pages/inspection-details/Weather';
import InspectionLayout from './pages/InspectionLayout/InspectionLayout';
import Leaks from './pages/InspectionLayout/form-pages/leak-details/leaks/Leaks';
import LeakRepairs from './pages/InspectionLayout/form-pages/leak-details/leak-repairs/LeakRepairs';
import MonitoredComponents from './pages/InspectionLayout/form-pages/inspection-summary/MonitoredComponents';
import FinalSubmission from './pages/InspectionLayout/form-pages/final-submission/FinalSubmission';
import InspectionSummary from './pages/InspectionLayout/form-pages/inspection-summary/InspectionSummary';
import ReportingHome from './pages/Reporting/ReportingHome';

const StyledDiv = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  background: ${global.White};
  flex-direction: column;
`;

const StyledSection = styled.section`
  display: flex;
  flex-direction: row;
  height: calc(100% - 72px);
  overflow: auto;
`;

const Main = styled.section`
  flex-grow: 1;
  overflow: auto;
  width: -webkit-fill-available;
  max-height: calc(100% - 72px);
`;

const AppRoutes = ({ navLinks, activeLink }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { is_admin, has_access } = useContext(GlobalContext);

  return !has_access ? (
    <AccessDenied />
  ) : (
    <StyledDiv>
      <Header />
      <StyledSection>
        <SideNav activeLink={activeLink} navLinks={navLinks} />
        <Main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/orgs/:customerId" element={<Home />} />
            <Route
              path="/orgs/:customerId/inspections/:id"
              element={<Inspections />}
            />
            <Route
              path="/orgs/:customerId/inspections/:id/files/:flogistixId/:insp"
              element={<FilteredFiles />}
            />
            {is_admin && (
              <>
                <Route path="/upload-management" element={<UploadManagement />} />
                <Route path="/inspections/:id?/*" element={<InspectionLayout />}>
                  <Route path="site-details/1" element={<PartOne />} />
                  <Route path="site-details/2" element={<PartTwo />} />
                  <Route path="inspection-details/1" element={<Info />} />
                  <Route path="inspection-details/2" element={<Verifications />} />
                  <Route path="inspection-details/3" element={<Weather />} />
                  <Route path="inspection-details/4" element={<Surveys />} />
                  <Route path="leak-details/1" element={<Leaks />} />
                  <Route path="leak-details/2" element={<LeakRepairs />} />
                  <Route path="inspection-summary/1" element={<InspectionSummary />} />
                  <Route path="inspection-summary/2" element={<MonitoredComponents />} />
                  <Route path="final-submission" element={<FinalSubmission />} />
                  <Route path="" element={<Navigate to="site-details/1" replace />} />
                </Route>
                <Route path="/reports" element={<ReportingHome />} />
              </>
            )}
          </Routes>
        </Main>
      </StyledSection>
    </StyledDiv>
  );
};

export default AppRoutes;
