import styled from 'styled-components';
import { Input } from 'antd';
import { global } from '../../../shared/colors';

const StyledInput = styled(Input)`
    border-radius: 10px;
    text-overflow: ellipsis;

    &.ant-input,
    .ant-space-item {
        color: ${global.Gray2};
    }

    &:hover {
        border-color: ${global.Gray2};
        cursor: pointer;
    }
`;

export default StyledInput;
