import styled from 'styled-components';

const TableDiv = styled.div`
    width: 100%;
    @media only screen and (max-width: 922px) {
        overflow-x: scroll;
    }
`;

export default TableDiv;
