/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useRef,
  useState,
  useEffect, useContext
} from 'react';

import { InspectionContext } from '../../../context/InspectionContext';
import { fetchFileUrl } from '../../../services/airmethaneApi';
import imageOutline from '../../../assets/image-outline.svg';

import './TypedDropzone.scss';

interface TypedDropzoneProps {
  onFileChange: (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => void;
  acceptedTypes: string;
  removeHandler?: (fileId?: string) => void;
  showRemoveButton?: boolean;
  fileId?: string;
}

interface FileData {
  id: string;
  parentId: string;
  version: number;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deleted: boolean;
  lastAct: string;
  fileType: string;
  s3Key: string;
  fileUrl: string;
  name: string;
  displayName: string;
  extension: string;
  url: string;
}

const TypedDropzone: React.FC<TypedDropzoneProps> = ({
  onFileChange,
  acceptedTypes,
  removeHandler,
  showRemoveButton,
  fileId
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [fileData, setFileData] = useState<FileData | null>(null);
  const [initialFileType, setInitialFileType] = useState<string | null>(null);
  const { token } = useContext(InspectionContext);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleRemove = () => {
    setPreview(null);
    setInitialFileType(null);
    if (removeHandler) {
      removeHandler(fileId);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>) => {
    if (event.type === 'change') {
      const target = event.target as HTMLInputElement;
      if (target.files && target.files[0]) {
        const file = target.files[0];
        setPreview(URL.createObjectURL(file));
        setInitialFileType(file.type);
      }
    } else if (event.type === 'drop') {
      const e = event as React.DragEvent<HTMLDivElement>;
      e.preventDefault();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const file = e.dataTransfer.files[0];
        setPreview(URL.createObjectURL(file));
        setInitialFileType(file.type);
      }
    }
    onFileChange(event);
  };

  useEffect(() => () => {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
  }, [preview]);

  useEffect(() => {
    const getFileData = async () => {
      if (fileId) {
        try {
          const data = await fetchFileUrl(fileId, token);
          setFileData(data);
          setInitialFileType(data.fileType);
        } catch (error) {
          console.error(error);
        }
      }
    };
    if (fileId) {
      getFileData();
    }
  }, [fileId, token]);

  useEffect(() => {
    if (fileData && fileData.url) {
      setPreview(fileData.url);
      setInitialFileType(fileData.fileType);
    }
  }, [fileData]);

  const isVideo = (fileType: string) => {
    const videoTypes = ['video/mp4', 'video/avi', 'video/mov', 'video/wmv', 'video'];
    return videoTypes.includes(fileType);
  };

  const thumbs = () => {
    if (preview) {
      const isVideoFile = isVideo(initialFileType ?? '') || (fileData && isVideo(fileData.fileType));

      if (isVideoFile) {
        return (
          <video
            src={preview}
            className="file-preview"
            controls
          >
            <source src={preview} type={initialFileType ?? fileData?.fileType ?? ''} />
            <track
              src="#"
              kind="captions"
              srcLang="en"
              label="English"
              default
            />
            Your browser does not support the video tag.
          </video>
        );
      }

      return <img src={preview} alt="File preview" className="file-preview" />;
    }

    return (
      <div className="placeholder-container">
        <img src={imageOutline} alt="Upload Placeholder" className="upload-placeholder" />
        <p className="placeholder-text">Upload Media</p>
      </div>
    );
  };

  return (
    <div className="dropzone-container">
      <div
        className="file-dropzone"
        onDrop={handleFileChange}
        onDragOver={handleDragOver}
        onClick={handleClick}
      >
        <input
          type="file"
          accept={acceptedTypes}
          onChange={handleFileChange}
          ref={fileInputRef}
        />
        {thumbs()}
      </div>
      {showRemoveButton && (
        <button className="file-removal-button" type="button" onClick={handleRemove}>
          Remove File
        </button>
      )}
    </div>
  );
};

TypedDropzone.defaultProps = {
  removeHandler: undefined,
  showRemoveButton: false,
  fileId: ''
};

export default TypedDropzone;
